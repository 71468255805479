import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from '@shared/app-component-base';


@Component({
  selector: 'app-pdf-popup',
  templateUrl: './pdf-popup.component.html',
  styleUrls: ['./pdf-popup.component.css']
})
export class PdfPopupComponent extends AppComponentBase implements OnInit {

  pdfSrc = "";
  isAcceptance = false;

  constructor(
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) public myData: DialogData,
    public dialogRef: MatDialogRef<PdfPopupComponent>,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.pdfSrc = this.myData.pdfUrl;
    if (this.myData.isAcceptance) {
      this.isAcceptance = true;
    }
  }

  addFunction() {
    this.dialogRef.close(true);

  }


  closeFunction() {
    this.dialogRef.close(false);
  }

}
export interface DialogData {
  pdfUrl: string;
  isAcceptance: boolean;
  title: string;
}