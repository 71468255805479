import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppComponentBase } from '@shared/app-component-base';

@Component({
  selector: 'app-custom-video-player',
  templateUrl: './custom-video-player.component.html',
  styleUrls: ['./custom-video-player.component.css']
})
export class CustomVideoPlayerComponent extends AppComponentBase implements OnInit, AfterViewInit {
  @ViewChild('youTubePlayer') youTubePlayer: ElementRef<HTMLDivElement>;
  
  @Input() videoUrl = '';
  @Input() height = 360;
  @Input() width = 640;

  videoId = '';
  isDialog = false;
  isLoading = false;

  constructor(
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) public myData: DialogData,
    public dialogRef: MatDialogRef<CustomVideoPlayerComponent>,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.initializeProperties();
    this.extractVideoId();
  }

  ngAfterViewInit(): void {
    this.setupResizeListener();
  }

  private initializeProperties() {
    this.isLoading = false;
    if (this.myData.videoUrl?.length > 0) {
      this.videoUrl = this.myData.videoUrl;
      this.isDialog = true;
    }
    this.height = this.myData.height > 0 ? this.myData.height : this.height;
    this.width = this.myData.width > 0 ? this.myData.width : this.width;
  }

  private extractVideoId() {
    const match = this.videoUrl.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/);
    this.videoId = (match && match[7].length === 11) ? match[7] : '';
  }

  close() { 
    this.dialogRef.close();
  }

  private setupResizeListener() {
    this.onResize();
    window.addEventListener('resize', this.onResize.bind(this));
  }

  onResize(): void {
    this.isLoading = true;
    this.width = Math.min(this.youTubePlayer.nativeElement.clientWidth, 1280);
    this.height = this.width / 16 * 9;
    this.changeDetectorRef.detectChanges();
    this.isLoading = false;
    console.log("width", this.width);
    console.log("height", this.height);
  }
}

export interface DialogData {
  videoUrl: string;
  height: number;
  width: number;
}