import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { InventoryMaterialOnShelfServiceProxy, InventoryProductOnShelfServiceProxy, InventoryShelf, InventoryWarehouse, MaterialServiceProxy, NonCurrentAssetItem, NonCurrentAssetItemServiceProxy, NonCurrentAssetServiceProxy, ProductServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'app-inventory-items-dropdown',
  templateUrl: './inventory-items-dropdown.component.html',
  styleUrls: ['./inventory-items-dropdown.component.css']
})
export class InventoryItemsDropdownComponent extends AppComponentBase implements OnInit {
  @Input() mode: number //0: product; 1: material; 2: product & material;
  @Input() IsEmptyIncluded: boolean = false;
  @Input() initialItem: any // product or material object, not dto
  @Input() initialAssetItem: NonCurrentAssetItem

  @Output() messageEvent: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() resetSelection: EventEmitter<{}> = new EventEmitter<{}>();

  allItemShelfList: any[] = []
  filteredItemShelfList: any[] = []
  filteredWarehouseList: any[] = []

  itemDropdown = []
  warehouseDropdown = []
  shelfDropdown = []

  selectedItem: any
  selectedWarehouse: InventoryWarehouse
  selectedShelf: InventoryShelf
  selectedItemShelf: any
  selectedAssetItem: NonCurrentAssetItem

  selectedItemText = ""

  constructor(
    injector: Injector,
    private _productShelfService: InventoryProductOnShelfServiceProxy,
    private _materialShelfService: InventoryMaterialOnShelfServiceProxy,
    private _productService: ProductServiceProxy,
    private _materialService: MaterialServiceProxy,
    private _assetService: NonCurrentAssetServiceProxy,
    private _assetItemService: NonCurrentAssetItemServiceProxy,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.allItemShelfList = []
    this.itemDropdown = []
    if (!this.initialItem) {
      if (this.mode == 0) {
        this._productService.getProductOnlyByTenant().subscribe(result => {
          if (result.length <= 0) abp.message.error(this.l("ItemsUnavailable"))
          this.allItemShelfList = result
          this.allItemShelfList.forEach(x => { this.itemDropdown.push(x.name)})
        })
      }
      if (this.mode == 1) {
        this._materialService.getMaterialByTenant().subscribe(result => {
          if (result.length <= 0) abp.message.error(this.l("ItemsUnavailable"))
          this.allItemShelfList = result
          this.allItemShelfList.forEach(x => { this.itemDropdown.push(x.name)})
        })
      }
      if (this.mode == 2) {
        this._assetService.getNonCurrentAssetByTenant().subscribe(result => {
          if (result.length <= 0) abp.message.error(this.l("ItemsUnavailable"))
          this.allItemShelfList = result
          this.allItemShelfList.forEach(x => { this.itemDropdown.push(x.name)})
        })
      }
    }
    else {
      this.onSelectItem()
      this.selectedItem = this.initialItem
    }
  }

  onSelectItem($event?) {
    this.resetSelection.emit()
    this.filteredItemShelfList = []
    this.warehouseDropdown = []
    this.shelfDropdown = []
    if ($event) {
      this.selectedItem = this.allItemShelfList.find(x => x.name === $event.selectedItem)
    }

    setTimeout(() => {
      if (this.mode == 0) {
        this._productShelfService.getProductShelfByProductId(this.selectedItem.id, this.IsEmptyIncluded).subscribe(result => {
          if (result.length <= 0) abp.message.error(`No Available ${this.selectedItem.name} in Inventory`)
          this.filteredItemShelfList = result
          this.warehouseDropdown = Array.from(new Set(this.filteredItemShelfList.map(x => {
            const quantity = this.filteredItemShelfList.reduce((sum, current) => {
              return current.inventoryShelf.inventoryWarehouse.id === x.inventoryShelf.inventoryWarehouse.id ? sum + current.quantity : sum;
            }, 0);
            return `${x.inventoryShelf.inventoryWarehouse.name} - Quantity: ${quantity} ${this.selectedItem?.unitOfMeasurement}`;
          })));
          
        })
      }
      if (this.mode == 1) {
        this._materialShelfService.getMaterialShelfByMaterialId(this.selectedItem.id, this.IsEmptyIncluded).subscribe(result => {
          if (result.length <= 0) abp.message.error(`No Available ${this.selectedItem.name} in Inventory`)
          this.filteredItemShelfList = result
          this.warehouseDropdown = Array.from(new Set(this.filteredItemShelfList.map(x => {
            const quantity = this.filteredItemShelfList.reduce((sum, current) => {
              return current.inventoryShelf.inventoryWarehouse.id === x.inventoryShelf.inventoryWarehouse.id ? sum + current.quantity : sum;
            }, 0);
            return `${x.inventoryShelf.inventoryWarehouse.name} - Quantity: ${quantity} ${this.selectedItem?.unitOfMeasurement}`;
          })));
          
        })
      }
      if (this.mode == 2) {
        if (this.initialAssetItem) {
          this.filteredItemShelfList.push(this.initialAssetItem)
          this.filteredItemShelfList.forEach(x => {
            this.warehouseDropdown.push(`${x.serialNumber} - ${x.barCode} - ${x.internalTag}`)
          })
          this.onSelectWarehouse()
        } else {
          this._assetItemService.getByNonCurrentAssetId(this.selectedItem.id).subscribe(result => {
            if (result.length <= 0) abp.message.error(`No Available ${this.selectedItem.name}`)
            this.filteredItemShelfList = result
            this.filteredItemShelfList.forEach(x => {
              this.warehouseDropdown.push(`${x.serialNumber} - ${x.barCode} - ${x.internalTag}`)
            })
          })
        }
      }
    }, 250);
  }

  onSelectWarehouse($event?) {
    this.resetSelection.emit()
    this.shelfDropdown = []
    this.filteredWarehouseList = []
    if (this.mode == 2) {
      this.selectedItemText = this.initialAssetItem
        ? `${this.initialAssetItem.serialNumber} - ${this.initialAssetItem.barCode} - ${this.initialAssetItem.internalTag}`
        : $event.selectedItem;
    
      this.selectedAssetItem = this.filteredItemShelfList.find(x =>
        `${x.serialNumber} - ${x.barCode} - ${x.internalTag}` === this.selectedItemText
      );
    
      this.messageEvent.emit(this.selectedAssetItem);
    }    
    else {
      const obj = this.filteredItemShelfList.find(x => {
        const quantity = this.filteredItemShelfList.reduce((sum, current) => {
          return current.inventoryShelf.inventoryWarehouse.id === x.inventoryShelf.inventoryWarehouse.id ? sum + current.quantity : sum;
        }, 0);
        return `${x.inventoryShelf.inventoryWarehouse.name} - Quantity: ${quantity} ${this.selectedItem?.unitOfMeasurement}` === $event.selectedItem;
      });
      this.selectedWarehouse = obj?.inventoryShelf?.inventoryWarehouse;
  
      setTimeout(() => {
        this.filteredWarehouseList = this.filteredItemShelfList.filter((x) =>
        x?.inventoryShelf?.inventoryWarehouse?.id == this.selectedWarehouse.id && 
        (x?.productGuid == this.selectedItem.id || 
        x?.materialGuid == this.selectedItem.id));
  
        if (this.filteredWarehouseList.length > 1) this.shelfDropdown = [...new Set(this.filteredWarehouseList.map(x => x?.inventoryShelf?.name + " - Quantity: " + x.quantity + " " + this.selectedItem?.unitOfMeasurement))]
        if (this.filteredWarehouseList.length == 1) {
          this.selectedItemShelf = this.filteredWarehouseList[0]
          if (this.mode == 0) this.selectedItemShelf.product = this.selectedItem
          if (this.mode == 1) this.selectedItemShelf.material = this.selectedItem
          this.messageEvent.emit(this.selectedItemShelf);
        }
      }, 250); 
    }
  }

  onSelectShelf($event) {
    const obj = this.filteredWarehouseList.find(x => x.inventoryShelf.name + " - Quantity: " + x.quantity + " " + this.selectedItem?.unitOfMeasurement === $event.selectedItem);
    this.selectedShelf = obj?.inventoryShelf;
    this.selectedItemShelf = this.filteredItemShelfList.find((x) => x.id == obj.id);
    if (this.mode == 0) this.selectedItemShelf.product = this.selectedItem
    if (this.mode == 1) this.selectedItemShelf.material = this.selectedItem
    this.messageEvent.emit(this.selectedItemShelf);
  }

}
