import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { PermissionCheckerService } from "abp-ng2-module";
import { AppSessionService } from "../session/app-session.service";
import { AppAuthService } from "./app-auth.service";
import { AppConsts } from "@shared/AppConsts";

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild {
  constructor(
    private _authService: AppAuthService,
    private _permissionChecker: PermissionCheckerService,
    private _router: Router,
    private _sessionService: AppSessionService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // Check if the user is logged in
    if (!this._sessionService.user) {
      this._authService.clearSiteData();
      abp.auth.clearToken();
      abp.utils.deleteCookie(AppConsts.authorization.encryptedAuthTokenName);
      this._router.navigate(["/account/login"]);
      return false;
    }

    // Access level check
    if (route.data && route.data["requiredLevel"]) {
      const requiredLevel = +route.data["requiredLevel"];
      const userAccessLevel = this._sessionService.accessLevel; // Assuming accessLevel is in AppSessionService

      if (userAccessLevel < requiredLevel) {
        this._router.navigate(["/app/permission-denied"]);
        return false;
      }
    }

    // HR check
    if (route.data && route.data["requireHR"]) {
      const hrBool: boolean = route.data["requireHR"];
      const userHrBool = this._sessionService.tenant.addHr;

      if (hrBool != userHrBool) {
        this._router.navigate(["/app/permission-denied"]);
        return false;
      }
    }

    // Permission check (if any permission is defined in the route)
    if (!route.data || !route.data["permission"]) {
      return true;
    }

    if (this._permissionChecker.isGranted(route.data["permission"])) {
      return true;
    }

    this._router.navigate([this.selectBestRoute()]);
    return false;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }

  selectBestRoute(): string {
    if (!this._sessionService.user) {
      this._authService.clearSiteData();
      abp.auth.clearToken();
      abp.utils.deleteCookie(AppConsts.authorization.encryptedAuthTokenName);
      return "/account/login";
    }

    return "/sell/home";
  }
}
