import { Component, Injector, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AppComponentBase } from '@shared/app-component-base';
import { PickPlanComponent } from 'account/pick-plan/pick-plan.component';

@Component({
  selector: 'app-check-access',
  templateUrl: './check-access.component.html',
  styleUrls: ['./check-access.component.scss']
})
export class CheckAccessComponent extends AppComponentBase implements OnInit {

  hasAccess: boolean = false;

  @Input() accessLevel: number = 100;
  @Input() addHR: boolean = false;

  myAccessLevel = this.appSession?.accessLevel ?? 100;
  myHrBool = this.appSession?.tenant?.addHr ?? false;

  constructor(
    injector: Injector,
    public _dialog: MatDialog,
    public dialogRef: MatDialogRef<PickPlanComponent>){
    super(injector);
  }

  ngOnInit(): void {
    this.checkUserAccess();
  }

  checkUserAccess(): void {
    if (this.appSession?.tenantId != null) {
      if (this.addHR && this.myHrBool) {
        this.hasAccess = true;
      } 
      else if (!this.addHR && this.myAccessLevel >= this.accessLevel) {
        this.hasAccess = true;
      }
      else {
        this.hasAccess = false;
      }
    } else {
      this.hasAccess = true;
    }
  }

  pickPlanPopup() {
    const dialogRef = this._dialog.open(PickPlanComponent, {
      disableClose: false,
      width: "70%",
      data: {
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      //after close
    });
  }

}