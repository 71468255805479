import { Injectable } from "@angular/core";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "@shared/assets/font/custom-fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  Roboto: {
    normal: 'NunitoSans-Regular.ttf',
    bold: 'NunitoSans-Bold.ttf',
    italics: 'NunitoSans-Italic.ttf',
    bolditalics: 'NunitoSans-BoldItalic.ttf'
  },
  callstories: {
    normal: "Callstories.ttf",
    bold: "Callstories.ttf",
    italics: "Callstories.ttf",
    bolditalics: "Callstories.ttf",
  },
};
@Injectable({
  providedIn: 'root'
})
export class CustomTableService {

  constructor() {
  }

  docDefinition = {};
  tableData: any[];
  tableColumns: any[];
  fontSize = 6.5;
  showTitle = true;
  mergeRowArray: number[] = [];
  mergeColumnArray: number[] = []; d

  async generatePdf(
    tableData: any[],
    tableColumns: any[],
    showTitle: boolean,
    mergeRowArray: number[] = [],
    mergeColumnArray: number[] = []
  ) {
    this.showTitle = showTitle;
    this.fontSize = 8;
    this.tableData = tableData;
    this.tableColumns = tableColumns;
    this.mergeRowArray = mergeRowArray;
    this.mergeColumnArray = mergeColumnArray;

    this.docDefinition = {
      content: [this.table()],
      footer: function (currentPage, pageCount, pageSize) {
        if (currentPage == pageCount) {
          return {};
        }
      },
      defaultStyle: {
        color: "#3F3F46",
      },
      pageOrientation: "portrait",
    };
    pdfMake.createPdf(this.docDefinition).open();
  }

  async generatePdfLandscape(
    tableData: any[],
    tableColumns: any[],
    showTitle: boolean,
    mergeRowArray: number[] = [],
    mergeColumnArray: number[] = []
  ) {
    this.mergeRowArray = mergeRowArray;
    this.mergeColumnArray = mergeColumnArray;

    this.fontSize = 6.5;
    this.showTitle = showTitle;
    this.tableData = tableData;
    this.tableColumns = tableColumns;

    this.docDefinition = {
      content: [this.table()],
      footer: function (currentPage, pageCount, pageSize) {
        if (currentPage == pageCount) {
          return {};
        }
      },
      defaultStyle: {
        color: "#3F3F46",
      },
      pageOrientation: "landscape",
    };
    pdfMake.createPdf(this.docDefinition).open();
  }


  async getDocDefinition(
    tableData: any[],
    tableColumns: any[],
    showTitle: boolean,
    mergeRowArray: number[] = [],
    mergeColumnArray: number[] = []
  ) {
    this.mergeRowArray = mergeRowArray;
    this.mergeColumnArray = mergeColumnArray;

    this.fontSize = 6.5;
    this.showTitle = showTitle;
    this.tableData = tableData;
    this.tableColumns = tableColumns;

    this.docDefinition = {
      content: [this.table()],
      footer: function (currentPage, pageCount, pageSize) {
        if (currentPage == pageCount) {
          return {};
        }
      },
      defaultStyle: {
        color: "#3F3F46",
      },
      pageOrientation: "landscape",
    };

    return this.docDefinition;
  }

  table() {
    let keyList = [];
    let titleList = [];
    let widthList = [];
    let dataList = [];
    this.tableColumns.forEach((eachTitle) => {
      keyList.push(eachTitle.dataKey);
      let rounded = 0;
      if (eachTitle.width) {
        const screenWidth = Number(eachTitle.width.replace("px", ""));
        rounded = parseInt(Math.round(screenWidth / 3000 * 595).toFixed(0));
      }
      widthList.push(eachTitle.width ? rounded : "*");
      if (this.showTitle) {
        titleList.push(this.textTitle(eachTitle.name, this.fontSize));
      }
    });

    this.tableData.forEach((eachData, rowIndex) => {
      let eachRow = [];
      keyList.forEach((eachKey, columnIndex) => {
        // Apply colSpan only if the row is included in mergeRowArray and the column is the starting index of a merge
        let colSpan = 1;
        if (this.mergeRowArray.includes(rowIndex) && this.mergeColumnArray.includes(columnIndex)) {
          if (columnIndex === this.mergeColumnArray[0]) { // Check if it's the starting column of merge
            colSpan = this.mergeColumnArray[1] - columnIndex; // Calculate the correct colSpan
          } else {
            colSpan = 0; // Skip adding cell for columns that are merged into the first
          }
        }
        if (colSpan > 0 || !this.mergeRowArray.includes(rowIndex)) { // Add cell if not merging or if row not in mergeRowArray
          eachRow.push(this.textCenter(eachData[eachKey], this.fontSize, colSpan));
        }
      });
      dataList.push(eachRow);
    });

    let bodyContent = this.showTitle ? [titleList, ...dataList] : [...dataList];

    return [
      {
        table: {
          headerRows: this.showTitle ? 1 : 0,
          widths: widthList,
          body: bodyContent,
        },
        layout: {
          defaultBorder: true,
          // fillColor: function (rowIndex, node, columnIndex) {
          //   return rowIndex === 0 ? "#2810bf" : null;
          // },
        },
        margin: [0, 10],
      },
    ];
  }


  textTitle(myString, size) {
    return {
      text: myString,
      fontSize: size,
      bold: true,
      // color: "#ffffff",
      alignment: "center",
      margin: [0, 5, 0, 0],
    };
  }

  textCenter(myString, size, colSpan) {
    return {
      text: myString,
      fontSize: size,
      bold: false, // Adjusted to not bold the data text
      alignment: "center",
      margin: [0, 5, 0, 0],
      colSpan: colSpan
    };
  }
}
