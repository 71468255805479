// import {
//     AbpHttpInterceptor,
//     RefreshTokenService,
// } from "@abp/abpHttpInterceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AbpHttpInterceptor } from "abp-ng2-module";
import * as ApiServiceProxies from "./service-proxies2";
import { AppInterceptor } from "./custom-interceptor"

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy2,
        ApiServiceProxies.CachingServiceProxy2,
        ApiServiceProxies.ChatServiceProxy2,
        ApiServiceProxies.CommonLookupServiceProxy2,
        ApiServiceProxies.EditionServiceProxy2,
        ApiServiceProxies.FriendshipServiceProxy2,
        ApiServiceProxies.HostSettingsServiceProxy2,
        ApiServiceProxies.InstallServiceProxy2,
        ApiServiceProxies.LanguageServiceProxy2,
        ApiServiceProxies.NotificationServiceProxy2,
        ApiServiceProxies.OrganizationUnitServiceProxy2,
        ApiServiceProxies.PermissionServiceProxy2,
        ApiServiceProxies.ProfileServiceProxy2,
        ApiServiceProxies.RoleServiceProxy2,
        ApiServiceProxies.SessionServiceProxy2,
        ApiServiceProxies.TenantServiceProxy2,
        ApiServiceProxies.TenantDashboardServiceProxy2,
        ApiServiceProxies.TenantSettingsServiceProxy2,
        ApiServiceProxies.TimingServiceProxy2,
        ApiServiceProxies.UserServiceProxy2,
        ApiServiceProxies.UserLinkServiceProxy2,
        ApiServiceProxies.UserLoginServiceProxy2,
        ApiServiceProxies.WebLogServiceProxy2,
        ApiServiceProxies.AccountServiceProxy2,
        ApiServiceProxies.TokenAuthServiceProxy2,
        ApiServiceProxies.TenantRegistrationServiceProxy2,
        ApiServiceProxies.HostDashboardServiceProxy2,
        ApiServiceProxies.PaymentServiceProxy2,
        ApiServiceProxies.DemoUiComponentsServiceProxy2,
        ApiServiceProxies.InvoiceServiceProxy2,
        ApiServiceProxies.SubscriptionServiceProxy2,
        ApiServiceProxies.InstallServiceProxy2,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy2,
        ApiServiceProxies.PayPalPaymentServiceProxy2,
        ApiServiceProxies.StripePaymentServiceProxy2,
        ApiServiceProxies.DashboardCustomizationServiceProxy2,
        // { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: AbpHttpInterceptor,
        //     multi: true,
        // },        
        { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
        ApiServiceProxies.BuyerProgramServiceProxy2,
        ApiServiceProxies.SupplierServiceProxy2,
        ApiServiceProxies.DocumentServiceProxy2,
        ApiServiceProxies.BuyerServiceProxy2,
        ApiServiceProxies.FunderServiceProxy2,
        ApiServiceProxies.CashflowServiceProxy2,
        ApiServiceProxies.ReportServiceProxy2,
        ApiServiceProxies.AssesmentServiceProxy2,
        ApiServiceProxies.DdcapServiceProxy2,
        ApiServiceProxies.SettingServiceProxy2,
        ApiServiceProxies.AgreementServiceProxy2,
        ApiServiceProxies.CtosServiceProxy2,
        ApiServiceProxies.DigitalConsentServiceProxy2,
        ApiServiceProxies.BuyerSupplierRepositoryServiceProxy2,
        ApiServiceProxies.GuarantorServiceProxy2,
        ApiServiceProxies.FinancialSpreadsheetServiceProxy2,
        ApiServiceProxies.EdlxServiceProxy2,
    ],
})
export class ServiceProxyModule2 { }
