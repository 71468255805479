import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';
import * as ApiServiceProxies from './service-proxies';
import { ApiTrackingInterceptor } from './api-tracking.interceptor';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';


@NgModule({
    imports:
        [
            NgxGoogleAnalyticsModule.forRoot('G-HVJ7PWCDB0'),
            NgxGoogleAnalyticsRouterModule.forRoot(),
        ],
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.ProductServiceProxy,
        ApiServiceProxies.CustomerServiceProxy,
        ApiServiceProxies.BillServiceProxy,
        ApiServiceProxies.BillItemServiceProxy,
        ApiServiceProxies.CompanyProfileServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.OrdersServiceProxy,
        ApiServiceProxies.VendorServiceProxy,
        ApiServiceProxies.PurchaseRequisitionServiceProxy,
        ApiServiceProxies.PurchaseOrdersServiceProxy,
        ApiServiceProxies.GoodsReceivedNotesServiceProxy,
        ApiServiceProxies.EasyParcelServiceProxy,
        ApiServiceProxies.AccountsChartServiceProxy,
        ApiServiceProxies.PurchaseRequisitionPublicServiceProxy,
        ApiServiceProxies.QuotationServiceProxy,
        ApiServiceProxies.QuotationPublicServiceProxy,
        ApiServiceProxies.DepartmentServiceProxy,
        ApiServiceProxies.HrmServiceProxy,
        ApiServiceProxies.PurchaseOrdersPublicServiceProxy,
        ApiServiceProxies.MaterialServiceProxy,
        ApiServiceProxies.PurchaseOrdersItemsServiceProxy,
        ApiServiceProxies.VendorInvoiceServiceProxy,
        ApiServiceProxies.VendorInvoiceItemServiceProxy,
        ApiServiceProxies.GoodsReceivedNotesPublicServiceProxy,
        ApiServiceProxies.NonCurrentAssetServiceProxy,
        ApiServiceProxies.NonCurrentAssetItemServiceProxy,
        ApiServiceProxies.InventoryCategoryServiceProxy,
        ApiServiceProxies.CashbookServiceProxy,
        ApiServiceProxies.InventoryLocationServiceProxy,
        ApiServiceProxies.WriteOffNoteServiceProxy,
        ApiServiceProxies.WriteOffNoteCategoryServiceProxy,
        ApiServiceProxies.WriteOffNoteCategoryRelationServiceProxy,
        ApiServiceProxies.WriteOffNoteItemServiceProxy,
        ApiServiceProxies.DealershipServiceProxy,
        ApiServiceProxies.NonCurrentAssetItemImpairmentServiceProxy,
        ApiServiceProxies.InventoryWarehouseServiceProxy,
        ApiServiceProxies.InventoryShelfServiceProxy,
        ApiServiceProxies.InventoryProductOnShelfServiceProxy,
        ApiServiceProxies.InventoryMaterialOnShelfServiceProxy,
        ApiServiceProxies.NonCurrentAssetClassServiceProxy,
        ApiServiceProxies.InventoryManualEntryServiceProxy,
        ApiServiceProxies.UserCurrentAccountServiceProxy,
        ApiServiceProxies.JournalServiceProxy,
        ApiServiceProxies.WriteOffNoteLabourServiceProxy,
        ApiServiceProxies.ProjectServiceProxy,
        ApiServiceProxies.NonCurrentAssetMaintenanceServiceProxy,
        ApiServiceProxies.AboutServiceProxy,
        ApiServiceProxies.DebitNoteServiceProxy,
        ApiServiceProxies.DebitNoteItemServiceProxy,
        ApiServiceProxies.PettyCashsServiceProxy,
        ApiServiceProxies.GeneralLedgerDataServiceProxy,
        ApiServiceProxies.EsgServiceProxy,
        ApiServiceProxies.CurrencyServiceProxy,
        ApiServiceProxies.FinancialStatementServiceProxy,
        ApiServiceProxies.LHDNServiceProxy,
        ApiServiceProxies.YayasanHijauServiceProxy,
        ApiServiceProxies.BankAccountServiceProxy,
        ApiServiceProxies.PosServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true, },
        { provide: HTTP_INTERCEPTORS, useClass: ApiTrackingInterceptor, multi: true },


    ]
})
export class ServiceProxyModule { }
