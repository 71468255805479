import { ChangeDetectorRef, Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AppComponentBase } from '@shared/app-component-base';
import { AccountsChartDto, AccountsChartServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'app-account-charge',
  templateUrl: './account-charge.component.html',
  styleUrls: ['./account-charge.component.css']
})
export class AccountChargeComponent extends AppComponentBase implements OnInit {

  sectorList = [
    {
      name: "Revenue",
      code: "RE"
    },
    {
      name: "Non-Current Assets",
      code: "NC"
    },
    {
      name: "Direct Cost",
      code: "DC"
    },
    {
      name: "Current Assets",
      code: "CA"
    },
    {
      name: "Selling Expenses",
      code: "SE"
    },
    {
      name: "Current Liabilities",
      code: "CL"
    },
    {
      name: "General Admin & Expenses",
      code: "GE"
    },
    {
      name: "Non-Current Liabilities",
      code: "NL"
    },
    {
      name: "Equity",
      code: "EQ"
    },
  ];

  accountChargeDropdown = [];
  accountChargeList: AccountsChartDto[] = [];
  @Input() selectedAccount: AccountsChartDto;
  @Input() selectedSector: any = {};
  @Input() disableCategory: boolean = false;

  accountChargeString = "Account Charge";

  @Input() initialAccountCharge: any;

  @Input() initialSector: any;
  @Input() initialCode: any;
  @Input() title: any;
  @Input() isFixedAssetOnly: boolean = false;

  @Input() isSingle: boolean = false;
  @Input() hideSector = false;

  @Output() messageEvent: EventEmitter<{}> = new EventEmitter<{}>();

  constructor(
    injector: Injector,
    public _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public myData: DialogData,
    private _accountCharge: AccountsChartServiceProxy,
    public dialogRef: MatDialogRef<AccountChargeComponent>,
    private cdr: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initCode();

    if (this.title) {
      this.accountChargeString = this.title + " " + this.l("Account Charge");
    }
  }

  initCode() {

    if (this.initialCode) {

      this.accountChargeDropdown = [];
      this.selectedAccount = null;
      this.selectedSector = null;


      this._accountCharge.getOneAccountChartByCode(this.initialCode).subscribe(
        result => {

          if (result.categoryCode != null) {
            this.initialSector = {
              name: result.name,
              code: result.categoryCode
            };
            this.initialAccountCharge = result.description;
            if (this.isSingle == true) {
              this.hideSector = true;
            }
            this.selectedSector = {
              name: result.name,
              code: result.categoryCode
            };
            this.getData(result.categoryCode);
          }

        }
      )
    }
  }

  ngOnChanges(): void {
    this.hideSector = false;

    if (this.initialCode) {
      this.initCode();
    } else if (this.initialSector) {
      this.selectedSector = this.initialSector;
      this.getData(this.selectedSector.code);
    }

  }

  onSelect(value: any) {
    this.selectedSector = value;
    this.getData(this.selectedSector.code);
    if (this.isSingle == true) {
      this.accountChargeString = this.selectedSector.name + " :";
      this.hideSector = true;
    }
  }

  compareSectors(sector1: any, sector2: any) {
    return sector1 && sector2 ? sector1.code === sector2.code : sector1 === sector2;
  }

  getData(code) {
    this.accountChargeDropdown = [];

    abp.ui.setBusy();
    this._accountCharge.getAccountsChartByCode(code).subscribe((result) => {

      this.accountChargeList = result;
      abp.ui.clearBusy();

      result.filter((eachItem) => this.isFixedAssetOnly ? !eachItem.depreciationFor && !eachItem.amortizationFor : true)
        .forEach((eachItem) => this.accountChargeDropdown.push(eachItem.description));

      console.log("initialAccountCharge", this.initialAccountCharge)
      console.log("selectedSector", this.selectedSector)
      console.log("initialSector", this.initialSector)
      if (this.initialAccountCharge && this.selectedSector.name == this.initialSector.name && this.selectedSector.code == this.initialSector.code) {
        var obj = this.accountChargeList.find((x) => x.description == this.initialAccountCharge);
        this.selectedAccount = obj;
        this.messageEvent.emit(this.selectedAccount);
      }

      this.cdr.detectChanges();
    });
  }

  onSelectAccountCharge($event) {
    var obj = this.accountChargeList.find((x) => x.description == $event.selectedItem);
    this.selectedAccount = obj;
    this.messageEvent.emit(this.selectedAccount);

    if (this.myData.justPick == true) {
      this.dialogRef.close(this.selectedAccount)
    }

  }

}


export interface DialogData {
  justPick: boolean;
}