<ng-container *ngIf="myTenant !== null && tenancyName">
  <table>

    <thead>
      <tr>
        <th colspan="6" style="background-color: white;">XFLOW Pricing Structure</th>
      </tr>
      <tr>
        <th>FEATURES</th>
        <th *ngFor="let plan of pricingPlans">{{ plan.name }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>e-Invoicing (LHDN Compliant)</td>
        <td *ngFor="let plan of pricingPlans" [attr.data-label]="plan.name">
          <span *ngIf="plan.features.featureA; else notAvailable"><img class="icon-style" src="../../assets/icon/checkbox.png" /></span>
          <ng-template #notAvailable>❌</ng-template>
        </td>
      </tr>
      <tr>
        <td>e-Procurement</td>
        <td *ngFor="let plan of pricingPlans" [attr.data-label]="plan.name">
          <span *ngIf="plan.features.featureB; else notAvailable"><img class="icon-style" src="../../assets/icon/checkbox.png" /></span>
          <ng-template #notAvailable>❌</ng-template>
        </td>
      </tr>
      <tr>
        <td>e-Store <img class="icon-style" src="../../assets/icon/gateway.svg" /></td>
        <td *ngFor="let plan of pricingPlans" [attr.data-label]="plan.name">
          <span *ngIf="plan.features.featureC; else notAvailable"><img class="icon-style" src="../../assets/icon/checkbox.png" /></span>
          <ng-template #notAvailable>❌</ng-template>
        </td>
      </tr>
      <tr>
        <td>Inventory Management</td>
        <td *ngFor="let plan of pricingPlans" [attr.data-label]="plan.name">
          <span *ngIf="plan.features.featureD; else notAvailable"><img class="icon-style" src="../../assets/icon/checkbox.png" /></span>
          <ng-template #notAvailable>❌</ng-template>
        </td>
      </tr>
      <tr>
        <td>Management Reporting</td>
        <td *ngFor="let plan of pricingPlans" [attr.data-label]="plan.name">
          <span *ngIf="plan.features.featureE; else notAvailable"><img class="icon-style" src="../../assets/icon/checkbox.png" /></span>
          <ng-template #notAvailable>❌</ng-template>
        </td>
      </tr>
      <tr>
        <td>Automated Budgeting</td>
        <td *ngFor="let plan of pricingPlans" [attr.data-label]="plan.name">
          <span *ngIf="plan.features.featureF; else notAvailable"><img class="icon-style" src="../../assets/icon/checkbox.png" /></span>
          <ng-template #notAvailable>❌</ng-template>
        </td>
      </tr>
      <tr>
        <td>Asset Management</td>
        <td *ngFor="let plan of pricingPlans" [attr.data-label]="plan.name">
          <span *ngIf="plan.features.featureG; else notAvailable"><img class="icon-style" src="../../assets/icon/checkbox.png" /></span>
          <ng-template #notAvailable>❌</ng-template>
        </td>
      </tr>
      <tr>
        <td>Project Management</td>
        <td *ngFor="let plan of pricingPlans" [attr.data-label]="plan.name">
          <span *ngIf="plan.features.featureH; else notAvailable"><img class="icon-style" src="../../assets/icon/checkbox.png" /></span>
          <ng-template #notAvailable>❌</ng-template>
        </td>
      </tr>
      <tr>
        <td>ESG Reporting</td>
        <td *ngFor="let plan of pricingPlans" [attr.data-label]="plan.name">
          <span *ngIf="plan.features.featureI; else notAvailable"><img class="icon-style" src="../../assets/icon/checkbox.png" /></span>
          <ng-template #notAvailable>❌</ng-template>
        </td>
      </tr>
      <tr>
        <td>Addon: HR Module</td>
        <td colspan="5">HR Module that enable employee management, payroll processing, leave management and attendance management!
        <br>
        <span class="sale-price">RM200/mo</span>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>PRICE</td>
        <td *ngFor="let plan of pricingPlans" [attr.data-label]="plan.name">
          <span class="sale-price">RM{{ plan.earlyBird }}/mo</span>
          <br>
          <span *ngIf="plan.originalPrice" class="original-price">RM{{ plan.originalPrice }}</span>
        </td>
      </tr>
      <tr>
        <td class="no-border"></td>
        <td *ngFor="let plan of pricingPlans" [attr.data-label]="plan.name">
          <button 
            *ngIf="edition !== plan.planType"
            class="w-100"
            mat-raised-button
            (click)="pickPlan(plan.planType)"
            color="primary">Select {{plan.name}}
          </button>
          <button 
            *ngIf="edition == plan.planType"
            class="w-100"
            mat-raised-button
            (click)="pickPlan(plan.planType)"
            style="color: white; background-color: rgb(48, 202, 115);">Your Current Plan
          </button>
        </td>
      </tr>
    </tfoot>
  </table>
</ng-container>

<ng-container *ngIf="myTenant == null && tenancyName">
  <div class="row justify-content-center">
    <div class="col-md-4 text-center p-3">
      <div class="card plan-box">
        <div class="h-100">
          <h4 class="box-title text-primary">XFLOW Standard</h4>
          <span class="original-price">RM 200/mth</span><span class="price-title">RM 60/mth</span>
          <ng-container></ng-container>
          <div></div>
        </div>
        <div class="p-2 w-100">
          <button
            class="w-100"
            mat-raised-button
            color="primary"
            (click)="pickBasic()"
          >
            Select
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-4 text-center p-3">
      <div class="card plan-box">
        <div class="h-100">
          <h4 class="box-title text-primary">XFLOW Essential</h4>
          <h2 class="price-title"><s>MYR 800/mth</s><br>MYR 400/mth</h2>
          <ng-container></ng-container>
        </div>
        <div class="p-2 w-100">
          <button
            class="w-100"
            mat-raised-button
            color="primary"
            (click)="pickPlan('essential')"
          >
            Select
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-4 text-center p-3">
      <div class="card plan-box">
        <div class="h-100">
          <h4 class="box-title text-primary">XFLOW Growth</h4>
          <h2 class="price-title"><s>MYR 900/mth</s><br>MYR 500/mth</h2>
          <ng-container></ng-container>
        </div>
        <div class="p-2 w-100">
          <button
            class="w-100"
            mat-raised-button
            color="primary"
            (click)="pickPlan('growth')"
          >
            Select
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-4 text-center p-3">
      <div class="card plan-box">
        <div class="h-100">
          <h4 class="box-title text-primary">XFLOW Performance</h4>
          <h2 class="price-title"><s>MYR 950/mth</s><br>MYR 600/mth</h2>
          <ng-container></ng-container>
        </div>
        <div class="p-2 w-100">
          <button
            class="w-100"
            mat-raised-button
            color="primary"
            (click)="pickPlan('performance')"
          >
            Select
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-4 text-center p-3">
      <div class="card plan-box">
        <div class="h-100">
          <h4 class="box-title text-primary">XFLOW Advance</h4>
          <h2 class="price-title"><s>MYR 1250/mth</s><br>MYR 800/mth</h2>
          <ng-container></ng-container>
        </div>
        <div class="p-2 w-100">
          <button
            class="w-100"
            mat-raised-button
            color="primary"
            (click)="pickPlan('advance')"
          >
            Select
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-4 text-center p-3">
      <div class="card plan-box">
        <div class="h-100">
          <h4 class="box-title text-primary">Additional Package: HR/Payroll</h4>
          <h2 class="price-title">MYR 200/mth</h2>
          <ng-container></ng-container>
        </div>
      </div>
    </div>
    <div class="col-md-4 text-center p-3">
      <div class="card plan-box">
        <div class="h-100">
          <h4 class="box-title text-primary">XFLOW Advance</h4>
          <h2 class="price-title"><s>MYR 1250/mth</s><br>MYR 800/mth</h2>
          <ng-container></ng-container>
        </div>
        <div class="p-2 w-100">
          <button
            class="w-100"
            mat-raised-button
            color="primary"
            (click)="contact()"
          >
            Select
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-4 text-center p-3">
      <div class="card plan-box">
        <div class="h-100">
          <h4 class="box-title text-primary">Additional Package: HR/Payroll</h4>
          <h2 class="price-title">MYR 200/mth</h2>
          <ng-container></ng-container>
        </div>
        <div class="p-2 w-100">
          <button
            class="w-100"
            mat-raised-button
            color="primary"
            (click)="contact()"
          >
            Select
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- <ng-container *ngIf="myTenant != null">
  <div class="row justify-content-center">
    <div class="col-md-4 text-center p-3">
      <div class="card plan-box2">
        <div class="h-100">
          <div class="col-11 text-left pl-2">
            Current Plan : {{ myTenant.edition.name }}
          </div>
          <div class="col-11 text-left pl-2">
            Expiry Date : {{ convertDate(myTenant.subscriptionEndDateUtc) }}
          </div>
        </div>
        <div class="p-2 w-100">
          <button
            class="w-100"
            mat-raised-button
            color="primary"
            (click)="upgrade()"
          >
            Upgrade Plan
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container> -->