import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable()
export class ApiTrackingInterceptor implements HttpInterceptor {

    constructor(private gaService: GoogleAnalyticsService) { }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.gaService.event('API Request', 'api-call', req.url);
        return next.handle(req);
    }
}
