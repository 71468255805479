import { AbpMultiTenancyService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import {
    ApplicationInfoDto,
    CompanyProfile,
    CompanyProfileServiceProxy,
    CurrencyServiceProxy,
    GetCurrentLoginInformationsOutput,
    SessionServiceProxy,
    TenantLoginInfoDto,
    TenantServiceProxy,
    TfxCurrency,
    UserLoginInfoDto
} from '@shared/service-proxies/service-proxies';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable()
export class AppSessionService {

    private _user: UserLoginInfoDto;
    private _tenant: TenantLoginInfoDto;
    private _application: ApplicationInfoDto;
    private _companyProfile: CompanyProfile;
    private _tfxCurrency: TfxCurrency;
    private _accessLevel: number;

    constructor(
        private _sessionService: SessionServiceProxy,
        private _companyProfileService: CompanyProfileServiceProxy,
        private _abpMultiTenancyService: AbpMultiTenancyService,
        private _currencyService: CurrencyServiceProxy,
        private _tenantService: TenantServiceProxy,
        private _gaService: GoogleAnalyticsService,) {
    }

    get application(): ApplicationInfoDto {
        return this._application;
    }

    get user(): UserLoginInfoDto {
        return this._user;
    }

    get userId(): number {
        return this.user ? this.user.id : null;
    }

    get tenant(): TenantLoginInfoDto {
        return this._tenant;
    }

    get tenantId(): number {
        return this.tenant ? this.tenant.id : null;
    }

    get companyProfile(): CompanyProfile {
        return this._companyProfile;
    }

    get companyProfileId(): string {
        return this.companyProfile ? this.companyProfile.id : null;
    }

    get CurrencyRate(): TfxCurrency {
        return this._tfxCurrency;
    }

    get accessLevel(): number {
        return this._accessLevel;
    }

    getShownLoginName(): string {
        const userName = this._user.userName;
        if (!this._abpMultiTenancyService.isEnabled) {
            return userName;
        }

        return (this._tenant ? this._tenant.tenancyName : '.') + '\\' + userName;
    }

    init(): Promise<boolean> {
        return this._sessionService.getCurrentLoginInformations()
            .toPromise()
            .then((result: GetCurrentLoginInformationsOutput) => {
                this._application = result.application;
                this._user = result.user;
                this._tenant = result.tenant;

                if(result && result.user) {
                    this._gaService.gtag('config', 'G-HVJ7PWCDB0', {
                        'user_id': result.user.id
                    });

                    this._gaService.set({ 'user_id': result.user.id });

                    return this._companyProfileService.getMyCompanyProfile()
                    .toPromise()
                    .then((companyProfile: CompanyProfile) => {
                        this._companyProfile = companyProfile;

                        return this._currencyService.getAllExchangeRate()
                        .toPromise()
                        .then((tfcCurrency) => {
                            this._tfxCurrency = tfcCurrency;
                            
                            return this._tenantService.getAccessLevelByTenantId(this.tenantId)
                            .toPromise()
                            .then((accessLevel) => {
                                this._accessLevel = accessLevel;
                                return true;
                            }).catch((err) => {
                                console.error("Error fetching access level:", err);
                                return false;
                            });
                        }).catch((err) => {
                            console.error("Error fetching currency exchange rates:", err);
                            return false;
                        });

                    })
                    .catch((err) => {
                        console.error("Error fetching company profile:", err);
                        return false;
                    });
                } else return false;
            })
            .catch((err) => {
                console.error("Error fetching login information:", err);
                return false;
            });
    }


    changeTenantIfNeeded(tenantId?: number): boolean {
        if (this.isCurrentTenant(tenantId)) {
            return false;
        }

        abp.multiTenancy.setTenantIdCookie(tenantId);
        location.reload();
        return true;
    }

    private isCurrentTenant(tenantId?: number) {
        if (!tenantId && this.tenant) {
            return false;
        } else if (tenantId && (!this.tenant || this.tenant.id !== tenantId)) {
            return false;
        }

        return true;
    }
}
