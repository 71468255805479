import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  { path: "", redirectTo: "/sell/home", pathMatch: "full" },
  {
    path: "account",
    loadChildren: () =>
      import("account/account.module").then((m) => m.AccountModule), // Lazy load account module
    data: { preload: true },
  },
  {
    path: "app",
    loadChildren: () => import("app/app.module").then((m) => m.AppModule), // Lazy load app module
    data: { preload: true },
  },
  {
    path: "sell",
    loadChildren: () => import("sell/sell.module").then((m) => m.SellModule), // Lazy load sell module
    data: { preload: true },
  },
  {
    path: "buy",
    loadChildren: () => import("buy/buy.module").then((m) => m.BuyModule), // Lazy load buy module
    data: { preload: true },
  },
  {
    path: "pay",
    loadChildren: () => import("pay/pay.module").then((m) => m.PayModule), // Lazy load pay module
    data: { preload: true },
  },
  {
    path: "accounting",
    loadChildren: () => import("accounting/accounting.module").then((m) => m.AccountingModule), // Lazy load accounting module
    data: { preload: true },
  },
  {
    path: "inventory",
    loadChildren: () => import("inventory/inventory.module").then((m) => m.InventoryModule), // Lazy load inventory module
    data: { preload: true },
  },
  {
    path: "asset-register",
    loadChildren: () => import("asset-register/asset-register.module").then((m) => m.AssetRegisterModule), // Lazy load asset register module
    data: { preload: true },
  },
  {
    path: "project",
    loadChildren: () => import("project/project.module").then((m) => m.ProjectModule), // Lazy load project module
    data: { preload: true },
  },
  {
    path: "tax",
    loadChildren: () => import("tax/tax.module").then((m) => m.TaxModule), // Lazy load tax module
    data: { preload: true },
  },
  {
    path: "migration",
    loadChildren: () => import("migration/migration.module").then((m) => m.MigrationModule), // Lazy load migration module
    data: { preload: true },
  },
  {
    path: "hr",
    loadChildren: () => import("hr/hr.module").then((m) => m.HrModule), // Lazy load hr module
    data: { preload: true },
  },
  {
    path: 'esg',
    loadChildren: () => import('./esg/esg.module').then(m => m.EsgModule), // Lazy load esg module
    data: { preload: true },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
  providers: [],
})
export class RootRoutingModule { }
