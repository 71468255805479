import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from '@shared/app-component-base';


@Component({
  selector: 'app-pick-contact',
  templateUrl: './pick-contact.component.html',
  styleUrls: ['./pick-contact.component.css']
})
export class PickContactComponent extends AppComponentBase implements OnInit {

  constructor(
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) public myData: DialogData,
    public dialogRef: MatDialogRef<PickContactComponent>,
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  addFunction() {
  }
  closeFunction() {
    this.dialogRef.close();
  }

}

export interface DialogData {
  data1: string;
  data2: string;
}