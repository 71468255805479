<div class="row">
  <div class="col ml-4 text-left">
    <label>TFX ISLAMIC SDN BHD</label>
  </div>
  <div class="col mr-2 text-right">
    <button
      mat-button
      class="btn btn-secondary kt-font-bold kt-font-transform-u mr-2"
      type="button"
      (click)="closeFunction()"
    >
      {{ "Close" | localize }}
    </button>
  </div>
</div>
<mat-dialog-content>
  <div class="row w-100 justify-content-center">
    <div class="col-12">
      D-1-3, Strand Garden Office No 12, Jalan PJU5/1 Kota Damansara 47810
      Petaling Jaya
    </div>
    <div class="col-12">Phone: +603 - 6151 2225</div>
    <div class="col-12">Email: info&#64;tfx.global</div>
  </div>
</mat-dialog-content>
