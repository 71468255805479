<div class="row justify-content-center">
  <div class="col-md-4 text-center p-3">
    <div class="card plan-box">
      <div class="h-100">
        <h4 class="box-title text-primary">{{ planString }} 1 Month</h4>
        <h2 class="price-title">MYR {{ total1 }}</h2>
      </div>
      <div class="p-2 w-100">
        <button
          class="w-100"
          mat-raised-button
          color="primary"
          (click)="purchase(1)"
        >
          Purchase 1 Month Credit
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-4 text-center p-3">
    <div class="card plan-box">
      <div class="h-100">
        <h4 class="box-title text-primary">{{ planString }} 6 Months</h4>
        <h2 class="price-title">MYR {{ total2 }}</h2>
        <div class="price-title crossed-out">MYR {{ total2 + save1 }}</div>
        <h5>Discount {{ discount1 }} %</h5>
        <h5>You Save MYR {{ save1 }}</h5>
      </div>
      <div class="p-2 w-100">
        <button
          class="w-100"
          mat-raised-button
          color="primary"
          (click)="purchase(2)"
        >
          Purchase 6 Month Credit
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-4 text-center p-3">
    <div class="card plan-box">
      <div class="h-100">
        <h4 class="box-title text-primary">{{ planString }} 1 Year</h4>
        <h2 class="price-title">MYR {{ total3 }}</h2>
        <div class="price-title crossed-out">MYR {{ total3 + save2 }}</div>
        <h5>Discount {{ discount2 }} %</h5>
        <h5>You Save MYR {{ save2 }}</h5>
      </div>
      <div class="p-2 w-100">
        <button
          class="w-100"
          mat-raised-button
          color="primary"
          (click)="purchase(3)"
        >
          Purchase 12 Month Credit
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-4 text-center p-3">
    <div class="card plan-box">
      <div class="h-100">
        <h4 class="box-title text-primary">Addon: HR/Payroll Module</h4>
        <h2 class="price-title">MYR 200</h2>
        <div class="row box-content">
          <div class="col-1">
            <img class="icon-style" src="../../assets/icon/checkbox.png" />
          </div>
          <div class="col-11 text-left pl-2">Employee Management</div>
        </div>
        <div class="row box-content">
          <div class="col-1">
            <img class="icon-style" src="../../assets/icon/checkbox.png" />
          </div>
          <div class="col-11 text-left pl-2">Payroll Processing</div>
        </div>
        <div class="row box-content">
      </div>
      <div class="p-2 w-100">
       <mat-slide-toggle [(ngModel)]="isChecked" (change)="onToggleChange()">
       </mat-slide-toggle>
      </div>
    </div>
  </div>
</div>
