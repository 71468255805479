<div class="location-selector">
  <mat-form-field class="full-width">
    <mat-label>Country</mat-label>
    <input type="text" matInput [formControl]="selectedCountry" [matAutocomplete]="countryAuto">
    <mat-autocomplete #countryAuto="matAutocomplete" (optionSelected)="onCountrySelect($event.option.value)">
      <mat-option *ngFor="let country of filteredCountries | async" [value]="country.name">
        {{ country.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>State</mat-label>
    <input type="text" matInput [formControl]="selectedState" [matAutocomplete]="stateAuto">
    <mat-autocomplete #stateAuto="matAutocomplete" (optionSelected)="onStateSelect($event.option.value)">
      <mat-option *ngFor="let state of filteredStates | async" [value]="state.name">
        {{ state.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

<mat-form-field class="full-width">
  <mat-label>City</mat-label>
  <input
    type="text"
    matInput
    [formControl]="selectedCity"
    [matAutocomplete]="cityAuto"
  />
  <mat-autocomplete
    #cityAuto="matAutocomplete"
    (optionSelected)="onCitySelect($event.option.value)"
  >
    <mat-option *ngFor="let city of filteredCities | async" [value]="city.name">
      {{ city.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
</div>