<button #button mat-icon-button type="button" [attr.aria-haspopup]="picker ? 'dialog' : null"
  [attr.tabindex]="disabled ? -1 : tabIndex" [disabled]="disabled" (click)="open($event)"
  [disableRipple]="disableRipple">

  <mat-icon *ngIf="!_customIcon" [style.color]="picker?._selected?.rgba">
    palette
  </mat-icon>

  <ng-content select="[ngxMatColorpickerToggleIcon]"></ng-content>

</button>