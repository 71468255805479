<ng-container *ngIf="items.length > 0">
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>{{ title }}</mat-label>
    <mat-select
      [compareWith]="compareFn"
      (selectionChange)="selectionChange($event)"
      [(ngModel)]="selectedItem"
    >
      <mat-option>
        <ngx-mat-select-search
          [formControl]="itemFilterCtrl"
          placeholderLabel="{{ placeholderLabel }}"
          noEntriesFoundLabel="{{ notFoundLabel }}"
        >
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let item of filteredItem | async" [value]="item">
        {{ item }}
      </mat-option>
    </mat-select>
    <mat-error> * {{ "Required" | localize }} </mat-error>
  </mat-form-field>
</ng-container>
