import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from '@shared/app-component-base';
import { CompanyProfile, CompanyProfileServiceProxy, PaymentServiceProxy, Rate, TfxBillItem } from '@shared/service-proxies/service-proxies';
import { TfxInvoiceService } from "@sell/bills/templates/tfx-invoice.service";
import moment from 'moment';

@Component({
  selector: 'app-tfx-invoice',
  templateUrl: './tfx-invoice.component.html',
  styleUrls: ['./tfx-invoice.component.css']
})
export class TfxInvoiceComponent extends AppComponentBase implements OnInit {

  pdfSrc = "";
  myCompany: CompanyProfile;

  constructor(
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) public myData: DialogData,
    public dialogRef: MatDialogRef<TfxInvoiceComponent>,
    public tfxInvoiceService: TfxInvoiceService,
    private _paymentService: PaymentServiceProxy,
    private _companyProfile: CompanyProfileServiceProxy

  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.myData.isSubscription == null) {
      this.myData.isSubscription = false;
    }

    this._companyProfile.getMyCompanyProfile().subscribe(
      (result) => {
        this.myCompany = result;
        this.tfxInvoiceService.generatePdf(result, this.myData.billItems, this.myData.invoiceNumber).then((data: Blob) => {
          this.pdfSrc = URL.createObjectURL(data);
          console.log(this.pdfSrc);
        });
      }
    );

  }

  async addFunction() {

    abp.ui.setBusy();


    if (this.myData.isSubscription) {

      this._paymentService.subscriptionPayment(this.myData.tenancyName, this.myData.paymentAmount, this.myData.subscriptionEnd, this.myData.edition).subscribe(
        result => {
          var paymentUrl = result.url;
          window.location.href = paymentUrl;

        }
      )

    } else {
      //purchase product 
      this._paymentService.purchaseTfxProduct(this.myCompany.id, this.myData.invoiceNumber, this.myData.billItems).subscribe(
        result => {
          abp.ui.clearBusy();

          var paymentUrl = result.url;
          window.location.href = paymentUrl;
        }
      )
    }


  }

  closeFunction() {
    this.dialogRef.close();
  }
}
export interface DialogData {
  billItems: TfxBillItem[];
  invoiceNumber: string;
  isSubscription: boolean;
  tenancyName: string;
  edition: string;
  subscriptionEnd: moment.Moment;
  paymentAmount: number;
}