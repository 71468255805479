import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { LayoutStoreService } from "@shared/layout/layout-store.service";
import { SessionServiceProxy } from "@shared/service-proxies/service-proxies";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-root",
  template: `<router-outlet></router-outlet>`,
})
export class RootComponent {
  constructor(
    private router: Router,
    private gaService: GoogleAnalyticsService,
    private _layoutSevice: LayoutStoreService,
    private _sessionService: SessionServiceProxy
  ) {
    this.trackRouting();
  }

  private trackRouting(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const path = event.urlAfterRedirects.split("?")[0];
        var pathSplit = path.split("/");
        const pageTitle = `TFX ${path.split("/")[1]} - ${path.split("/")[2]} `;

        this._layoutSevice.setPageTitle(pageTitle);
        this._sessionService
          .getCurrentLoginInformations()
          .subscribe((result) => {
            if (result.user) {
              this.gaService.pageView(path, pageTitle, undefined, {
                user_id: result.user.id,
              });
            } else {
              this.gaService.pageView(path, pageTitle);
            }
          });
      });
  }
}
