import { Injectable } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import {
  BillDto,
  BillItemDto,
  CompanyProfileDto,
} from "@shared/service-proxies/service-proxies";
import * as moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";

@Injectable({
  providedIn: "root",
})
export abstract class BillsPdfService {
  defaultImage = "./assets/img/logo.png";
  newArray = [];

  async generateDeliveryOrderPDF(
    companyProfile: CompanyProfileDto,
    bill: BillDto,
    billItems: BillItemDto[],
    action?
  ) {
    var Logo = this.defaultImage;
    if (companyProfile.companyLogo) {
      Logo = companyProfile.companyLogo;
    }
    if (billItems) {
      var totalSubtotal = 0;
      var totalDiscount = 0;
      var totalExcl = 0;
      var totalTax = 0;
      var totalInc = 0;

      billItems.forEach((eachItem) => {
        totalSubtotal += eachItem.quantity * eachItem.unitPrice;
        totalDiscount += eachItem.totalDiscount;
        totalTax += eachItem.totaltax;
      });

      totalExcl = totalSubtotal - totalDiscount;
      totalInc = totalExcl + totalTax;

      var myCustomColor = "#2810bf ";

      if (companyProfile.brandingColor) {
        myCustomColor = "#" + companyProfile.brandingColor;
      }

      let docDefinition = {
        content: [
          {
            columns: [
              {
                columns: [
                  {
                    width: "auto",
                    image: await this.getBase64ImageFromURL(Logo),
                    fit: [80, 50],
                    // fit: [100, 50],
                  },
                  {
                    width: "auto",
                    layout: "noBorders",
                    alignment: "left",
                    table: {
                      body: [
                        [
                          {
                            text: companyProfile.companyName,
                            bold: true,
                            margin: [10, 10, 0, 0],
                          },
                        ],
                        [
                          {
                            text: companyProfile.companyRegNo,
                            margin: [10, 0, 0, 0],
                          },
                        ],
                      ],
                    },
                  },
                ],
                alignment: "left",
              },
              {
                width: "*",
                layout: "noBorders",
                text: this.l("DeliveryOrder"),
                fontSize: 30,
                bold: true,
                alignment: "right",
                margin: [0, 15, 0, 0],
                color: myCustomColor,
              },
            ],
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 30,
                x2: 520,
                y2: 30,
                lineWidth: 1,
                color: myCustomColor,
              },
            ],
          },
          {
            columns: [
              {
                width: "*",
                layout: "noBorders",
                table: {
                  body: [
                    [
                      {
                        text: this.l("DeliverTo").toUpperCase(),
                        fontSize: 10,
                        bold: true,
                        margin: [0, 10],
                      },
                    ],
                    [
                      {
                        text: bill.customer?.name,
                        fontSize: 10,
                        bold: true,
                      },
                    ],
                    [{ text: bill.customer?.billingAddress1, fontSize: 10 }],
                    [{ text: bill.customer?.billingAddress2, fontSize: 10 }],
                    [{ text: bill.customer?.city, fontSize: 10 }],
                    [{ text: bill.customer?.state, fontSize: 10 }],
                    [{ text: bill.customer?.country, fontSize: 10 }],
                    [{ text: "", fontSize: 10 }],
                    [{ text: bill.customer?.contactNo, fontSize: 10 }],
                    [
                      {
                        text: bill.customer?.email,
                        fontSize: 10,
                        link: "mailto:" + bill.customer?.email,
                        color: myCustomColor,
                        decoration: "underline",
                      },
                    ],
                  ],
                },
              },
              {
                width: "auto",
                table: {
                  widths: ["auto", "auto", "auto"],
                  body: [
                    [
                      {
                        text: this.l("OrderDetails").toUpperCase(),
                        fontSize: 10,
                        bold: true,
                        colSpan: 3,
                        margin: [0, 10],
                      },
                      {},
                      {},
                    ],
                    [
                      { text: this.l("BillNo"), fontSize: 10, alignment: "left" },
                      { text: " : ", fontSize: 10 },
                      { text: bill.billNo, fontSize: 10 },
                    ],
                    [
                      { text: this.l("Date"), alignment: "left", fontSize: 10 },
                      { text: " : ", fontSize: 10 },
                      {
                        text: bill.issueDate.format("DD MMM YYYY"),
                        fontSize: 10,
                      },
                    ],
                    [
                      { text: this.l("DueDate"), alignment: "left", fontSize: 10 },
                      { text: " : ", fontSize: 10 },
                      {
                        text: bill.dueDate.format("DD MMM YYYY"),
                        fontSize: 10,
                      },
                    ],
                    [
                      {
                        text: this.l("PurchaseOrderNo"),
                        alignment: "left",
                        fontSize: 10,
                      },
                      { text: " : ", fontSize: 10 },
                      {
                        text: bill.poReferenceNumber,
                        fontSize: 10,
                      },
                    ],
                  ],
                },
                layout: {
                  defaultBorder: false,
                },
                alignment: "right",
              },
            ],
            margin: [0, 10],
          },
          {
            table: {
              headerRows: 1,
              widths: ["*", 35],
              heights: 30,
              body: [
                [
                  {
                    text: this.l("ItemDescription"),
                    fontSize: 9,
                    bold: true,
                    color: "#ffffff",
                    margin: [0, 10],
                  },
                  {
                    text: this.l("Qty"),
                    fontSize: 9,
                    bold: true,
                    alignment: "center",
                    color: "#ffffff",
                    margin: [0, 10],
                  },
                ],
                ...billItems.map((p) => [
                  {
                    text: p.product.name,
                    fontSize: 9,
                    margin: [0, 5],
                  },
                  {
                    text: p.quantity.toFixed(0),
                    fontSize: 9,
                    alignment: "center",
                    margin: [0, 5],
                  },
                ]),
              ],
            },
            layout: {
              defaultBorder: false,
              fillColor: function (rowIndex, node, columnIndex) {
                return rowIndex === 0 ? myCustomColor : null;
              },
            },
            margin: [0, 10],
          },
          {
            width: "auto",
            layout: "noBorders",
            alignment: "center",
            table: {
              body: [
                [
                  bill.goodsReceived == false
                    ? {
                      text: this.l("ClickHereToConfirmThatAllGoodsReceivedAreInGoodOrderAndCondition"),
                      bold: true,
                      color: myCustomColor,
                      margin: [0, 10],
                      link:
                        AppConsts.appBaseUrl + "/sell/do_pdf?id=" +
                        bill.id,
                      decoration: "underline",
                      alignment: "center",
                    }
                    : {
                      text:
                        this.l("GoodsReceivedDate") +
                        this.convertDate(bill.goodsReceivedDate),
                      bold: true,
                      color: myCustomColor,
                      margin: [0, 10],
                      alignment: "center",
                    },
                ],
                [{ text: "", margin: [0, 15, 0, 0], fontSize: 10 }],
              ],
            },
          },
        ],
        footer: function (currentPage, pageCount, pageSize) {
          if (currentPage == pageCount) {
            return {};
          }
        },
        defaultStyle: {
          color: "#3F3F46",
        },
      };

      var compnayName = companyProfile.companyName.replace(/\s/g, "");

      var fileName = "DO_" + compnayName + "_" + bill.billNo + ".pdf";

      if (action === "download") {
        pdfMake.createPdf(docDefinition).download(fileName);
      } else if (action === "print") {
        pdfMake.createPdf(docDefinition).print();
      } else {
        // pdfMake.createPdf(docDefinition).open();
        pdfMake.createPdf(docDefinition).download(fileName);
      }
    } else return "No items found in invoice";
  }

  addTimestamp(name) {
    return name + "?" + Date.now().toString();
  }

  getBase64ImageFromURL(input) {
    var url = this.addTimestamp(input);

    if (url == null || url == "") {
      url = this.defaultImage;
    }

    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL();
        resolve(dataURL);
      };
      img.onerror = (error) => {
        img.src = this.defaultImage; // Set img.src to defaultImage
        img.onload = () => {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          var ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0);
          var dataURL = canvas.toDataURL();
          resolve(dataURL);
        };
        img.onerror = (error) => {
          reject(error);
        };
      };
      img.src = url;
    });
  }

  capitalLetterSign(word) {
    var lowerCase = word.toLowerCase();
    let str = lowerCase.split(" ");

    for (var i = 0, x = str.length; i < x; i++) {
      if (str[i][0]) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
      }
    }

    return str.join(" ");
  }

  convertDate(momentObject) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var returnObject = moment(momentObject).tz(userTimezone).format("DD/MM/YYYY");
    if (returnObject === "01/01/0001" || momentObject == null) {
      return "";
    } else {
      return returnObject;
    }
  }

  public moneyFormat(number: any): string {
    var result = Number(number)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    return result;
  }

  l(key: string, ...args: any[]): string {
    let localizedText = abp.localization.localize(key, AppConsts.localization.defaultLocalizationSourceName);

    if (!localizedText) {
      localizedText = key;
    }

    if (!args || !args.length) {
      return localizedText;
    }

    args.unshift(localizedText);
    return abp.utils.formatString.apply(this, args);
  }
}
