import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Country, State, City } from 'country-state-city';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.css']
})
export class LocationSelectorComponent implements OnInit {

  @Output() countryChange = new EventEmitter<{ name: string, isoCode: string }>();
  @Output() stateChange = new EventEmitter<{ name: string, isoCode: string }>();
  @Output() cityChange = new EventEmitter<{ name: string }>();

  @Input() savedCountry: string = '';
  @Input() savedState: string = '';
  @Input() savedCity: string = '';

  countries: any[] = [];
  states: any[] = [];
  cities: any[] = [];

  selectedCountry = new FormControl('');
  selectedState = new FormControl('');
  selectedCity = new FormControl('');

  filteredCountries!: Observable<any[]>;
  filteredStates!: Observable<any[]>;
  filteredCities!: Observable<any[]>;

  constructor() {}

  ngOnInit() {
    this.countries = Country.getAllCountries();

    this.filteredCountries = this.selectedCountry.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value, this.countries, 'name'))
    );

    this.filteredStates = this.selectedState.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value, this.states, 'name'))
    );

    this.filteredCities = this.selectedCity.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value, this.cities, 'name'))
    );

    if (this.savedCountry) {
      const country = this.countries.find(c => c.name === this.savedCountry);
      if (country) {
        this.selectedCountry.setValue(country.name);
        this.loadStates(country.isoCode);

        if (this.savedState) {
          const state = State.getStatesOfCountry(country.isoCode).find(s => s.name === this.savedState);
          if (state) {
            this.selectedState.setValue(state.name);
            this.loadCities(country.isoCode, state.isoCode);
          }
        }

        this.selectedCity.setValue(this.savedCity);
      }
    }
  }

  private filter(value: string | null, collection: any[], key: string): any[] {
    if (!value) return collection;
    const filterValue = value.toLowerCase();
    return collection.filter(item => item[key].toLowerCase().includes(filterValue));
  }  

  onCountrySelect(countryName: string) {
    const country = this.countries.find(c => c.name === countryName);
    if (country) {
      this.selectedState.reset();
      this.selectedCity.reset();
      this.states = [];
      this.cities = [];
  
      this.countryChange.emit({ name: country.name, isoCode: country.isoCode });
  
      this.loadStates(country.isoCode);
  
      this.filteredCities = this.selectedCity.valueChanges.pipe(
        startWith(''),
        map(value => this.filter(value, [], 'name')) // No cities yet
      );
    }
  }  
  onStateSelect(stateName: string) {
    const state = this.states.find(s => s.name === stateName);
    if (state) {
      this.selectedCity.reset();
      this.cities = [];
    
      this.stateChange.emit({ name: state.name, isoCode: state.isoCode });
    
      const country = this.countries.find(c => c.name === this.selectedCountry.value);
      if (country) {
        this.loadCities(country.isoCode, state.isoCode);
      }
    }
  }  

  onCitySelect(cityName: string) {
    const city = this.cities.find(c => c.name === cityName);
    if (city) {
      this.cityChange.emit({ name: cityName });
    }
  }  

  loadStates(countryIsoCode: string) {
    this.states = State.getStatesOfCountry(countryIsoCode);
    this.filteredStates = this.selectedState.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value, this.states, 'name'))
    );
  }

  loadCities(countryIsoCode: string, stateIsoCode: string) {
    this.cities = City.getCitiesOfState(countryIsoCode, stateIsoCode) || [];
    
    this.filteredCities = this.selectedCity.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value, this.cities, 'name'))
    );
  }
}
