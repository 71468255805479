<div class="w-100">
    <div class="row justify-content-center w-100">
        <div *ngIf="!initialItem" class="col-6">
            <ng-container *ngIf="itemDropdown.length != 0">
              <app-custom-dropdown
                [items]="itemDropdown"
                [title]="mode == 2 ? 'Asset Name' : 'Item'"
                [placeholderLabel]="'Search' | localize"
                [notFoundLabel]="'NotFound' | localize"
                (dropdownEvent)="onSelectItem($event)"
              ></app-custom-dropdown>
            </ng-container>
        </div>
        <div class="col-6">
            <ng-container *ngIf="warehouseDropdown.length != 0">
            <app-custom-dropdown
                [items]="warehouseDropdown"
                [title]="mode == 2 ? 'Asset Item' : 'Warehouse'"
                [placeholderLabel]="'Search' | localize"
                [notFoundLabel]="'NotFound' | localize"
                [initialValue]="selectedItemText"
                (dropdownEvent)="onSelectWarehouse($event)"
            ></app-custom-dropdown>
            </ng-container>
        </div>
        <div [class.col-6]="initialItem" [class.col-12]="!initialItem">
            <ng-container *ngIf="shelfDropdown.length != 0">
            <app-custom-dropdown
                [items]="shelfDropdown"
                [title]="'Shelf'"
                [placeholderLabel]="'Search' | localize"
                [notFoundLabel]="'NotFound' | localize"
                (dropdownEvent)="onSelectShelf($event)"
            ></app-custom-dropdown>
            </ng-container>
        </div>
    </div>
  </div>
  