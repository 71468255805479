import { InputFileComponent } from "./lib/components/input-file/input-file.component";
import { InputFileModule } from "./lib/input-file.module";
import { InputFile } from "./lib/interfaces/input-file";
import { InputFileConfig } from "./lib/interfaces/input-file-config";
import { InputFileService } from "./lib/services/input-file.service";

export {
  InputFile,
  InputFileComponent,
  InputFileConfig,
  InputFileModule,
  InputFileService,
};
