import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TfxInvoiceComponent } from '@shared/components/tfx-invoice/tfx-invoice.component';
import { TfxBillItem } from '@shared/service-proxies/service-proxies';
import moment from 'moment';

@Component({
  selector: 'app-purchase-plan',
  templateUrl: './purchase-plan.component.html',
  styleUrls: ['./purchase-plan.component.css']
})
export class PurchasePlanComponent implements OnInit {

  planId;
  total1 = 0;
  total2 = 0;
  total3 = 0;
  discount1 = 0;
  discount2 = 0;
  save1 = 0;
  save2 = 0;
  isChecked = false;
  originalPrice = 0;

  paymentAmount = 0;
  subscriptionEnd;
  planString = "";

  edition = "Standard";
  numOfMonths = 1;

  

  constructor(
    @Inject(MAT_DIALOG_DATA) public myData: DialogData,
    public dialogRef: MatDialogRef<PurchasePlanComponent>,
    private _dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.discount1 = 15;
    this.discount2 = 30;
    this.planId = this.myData.planId;
    this.updatePlanDetails();
  }

  updatePlanDetails(): void {
    switch (this.planId) {
      case 1:
        this.planString = "Standard";
        this.edition = "Standard";
        this.originalPrice = 60;
        this.calculateDiscount(this.originalPrice);
        break;
      case 2:
        this.planString = "Essential";
        this.edition = "Premium";
        this.originalPrice = 400;
        this.calculateDiscount(this.originalPrice);
        break;
      case 7:
        this.planString = "Growth";
        this.edition = "Growth";
        this.originalPrice = 500;
        this.calculateDiscount(this.originalPrice);
        break;
      case 8:
        this.planString = "Performance";
        this.edition = "Performance";
        this.originalPrice = 600;
        this.calculateDiscount(this.originalPrice);
        break;
      case 9:
        this.planString = "Advance";
        this.edition = "Advance";
        this.originalPrice = 800;
        this.calculateDiscount(this.originalPrice);
        break;
      default:
        break;
    }
  }


  calculateDiscount(price) {
    if(this.isChecked){
      price += 200;
    }
    else{
      price = this.originalPrice;
    }
    this.total1 = price;
    this.save1 = (price * 6) * this.discount1 / 100;
    this.save2 = (price * 12) * this.discount2 / 100;
    this.total2 = (price * 6) - this.save1;
    this.total3 = (price * 12) - this.save2;

  }

  onToggleChange(): void {
    this.calculateDiscount(this.originalPrice);  // Recalculate based on current price
  }

  purchase(optionNum) {
    var myDate = moment();
    if (this.myData.subscriptionEndDateUtc != null) {
      myDate = this.myData.subscriptionEndDateUtc;
    }

    switch (optionNum) {
      case 1:
        this.paymentAmount = this.total1;
        this.subscriptionEnd = myDate.add(1, "months");
        this.numOfMonths = 1;
        this.makePayment();
        break;
      case 2:
        this.paymentAmount = this.total2; 
        this.subscriptionEnd = myDate.add(6, "months");
        this.numOfMonths = 6;

        this.makePayment();
        break;
      case 3:
        this.paymentAmount = this.total3;
        this.subscriptionEnd = myDate.add(12, "months");
        this.numOfMonths = 12;

        this.makePayment();
        break;
    }

  }

  makePayment() {
    var billItem = new TfxBillItem();
    billItem.quantity = 1;
    billItem.unitPrice = this.paymentAmount;
    billItem.productName = "Purchase " + this.planString + " Plan for " + this.numOfMonths + " months ( Untill " + this.subscriptionEnd.format("DD MMM YYYY") + ")";


    const randomNumber = Math.floor(Math.random() * 9000) + 1000;
    const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');
    const invoiceNumber = `TFX${currentDate}-${randomNumber}`;


    const dialogRef = this._dialog.open(TfxInvoiceComponent, {
      disableClose: false,
      width: "70%",
      data: {
        billItems: [billItem],
        invoiceNumber: invoiceNumber,
        isSubscription: true,
        tenancyName: this.myData.tenancyName,
        edition: this.edition,
        addHr: this.isChecked,
        subscriptionEnd: this.subscriptionEnd,
        paymentAmount: this.paymentAmount
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      //after close
    });
  }

}

export interface DialogData {
  planId: number;
  tenancyName: string;
  subscriptionEndDateUtc: moment.Moment;
}