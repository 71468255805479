import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { AppSessionService } from '@shared/session/app-session.service';

@Pipe({
    name: 'customCurrency'
})
@Injectable()
export class CustomCurrencyPipe implements PipeTransform {

    constructor(
        private currencyPipe: CurrencyPipe,
        private _appSession: AppSessionService
    ) { }

    transform(value: number, currencyCode?: string): string {
        if (currencyCode) {
            if (currencyCode == "MYR") {
                return this.currencyPipe.transform(value, "RM ");
            } else {
                return this.currencyPipe.transform(value, currencyCode);
            }
        } else {
            if (this._appSession.companyProfile != null && this._appSession.companyProfile.currency) {
                if (this._appSession.companyProfile.currency == "MYR") {
                    return this.currencyPipe.transform(value, "RM ");
                } else {
                    return this.currencyPipe.transform(value, this._appSession.companyProfile.currency) + " ";
                }
            } else {
                return this.currencyPipe.transform(value, "RM ");
            }
        }
    }


    convertCurrency(fromCode: string, toCode: string, value: number): number {
        const latest = this._appSession.CurrencyRate;

        if (!latest) {
            throw new Error('No exchange rate data available.');
        }

        const getRate = (code: string): number => {
            switch (code) {
                case 'MYR': return 1;
                case 'USD': return Number(latest.usd);
                case 'GBP': return Number(latest.gbp);
                case 'EUR': return Number(latest.eur);
                case 'SGD': return Number(latest.sgd);
                case 'IDR': return Number(latest.idr);
                case 'CNY': return Number(latest.cny);
                case 'JPY': return Number(latest.jpy);
                default: throw new Error(`Unsupported currency code: ${code}`);
            }
        };

        const baseRate = getRate(fromCode);
        const targetRate = getRate(toCode);
        const exchangeRate = fromCode === 'MYR' ? targetRate : 1 / baseRate * targetRate;

        return Number((value * exchangeRate).toFixed(2));
    }


}
