import { Injectable, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AppConsts } from "@shared/AppConsts";
import {
  AuthenticateModel,
  AuthenticateResultModel,
  CompanyProfile,
  CompanyProfileServiceProxy,
  TenantDto,
  TenantServiceProxy,
  TokenAuthServiceProxy,
} from "@shared/service-proxies/service-proxies";
import * as scf from "@shared/service-proxies2/service-proxies2";
import {
  SupplierServiceProxy2,
  TenantServiceProxy2,
  TokenAuthServiceProxy2,
} from "@shared/service-proxies2/service-proxies2";
import {
  LocalizationService,
  LogService,
  TokenService,
  UtilsService,
} from "abp-ng2-module";
import { PickPlanComponent } from "account/pick-plan/pick-plan.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { finalize, switchMap } from "rxjs/operators";

@UntilDestroy()
@Injectable()
export class AppAuthService {
  localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
  authenticateModel: AuthenticateModel;
  authenticateResult: AuthenticateResultModel;
  rememberMe: boolean;
  tenancyName = "";
  scfTenancyName = "";
  scfTenantId = 0;
  userPassword;
  userEmail;
  modalRef: BsModalRef;
  @ViewChild("templateName") templateName: TemplateRef<any>;

  constructor(
    private _companyProfileService: CompanyProfileServiceProxy,
    private _logService: LogService,
    private _router: Router,
    private _supplierService: SupplierServiceProxy2,
    private _tenantService1: TenantServiceProxy,
    private _tenantService2: TenantServiceProxy2,
    private _tokenAuthService: TokenAuthServiceProxy,
    private _tokenService1: TokenService,
    private _tokenService2: TokenAuthServiceProxy2,
    private _utilsService: UtilsService,
    private localization: LocalizationService,
    public _dialog: MatDialog,
    public modalService: BsModalService
  ) {
    this.clear();
  }

  public logout(reload?: boolean): void {
    // this._utilsService.deleteCookie("scfTenantId");
    // this._utilsService.deleteCookie("scfToken");
    // this._utilsService.deleteCookie("Abp.AuthToken");
    // this._utilsService.deleteCookie("Abp.TenantId");
    this.clearSiteData();

    abp.auth.clearToken();
    abp.utils.deleteCookie(AppConsts.authorization.encryptedAuthTokenName);

    if (reload !== false) {
      location.href = AppConsts.appBaseUrl;
    }
  }

  public clearSiteData(): void {
    // Clear all cookies
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
      const [name] = cookie.split("=");
      document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    }

    // Clear localStorage
    localStorage.clear();

    // Clear sessionStorage
    sessionStorage.clear();

    // Clear IndexedDB
    if ("indexedDB" in window) {
      // Attempt to list all IndexedDB databases (for supported browsers)
      const indexedDB = window.indexedDB as any; // Explicitly cast as "any" for experimental `databases` method
      if (indexedDB.databases) {
        indexedDB.databases().then((databases) => {
          databases.forEach((db) => indexedDB.deleteDatabase(db.name!));
        });
      }
    }
  }

  public authenticate(finallyCallback?: () => void): void {
    finallyCallback = finallyCallback || (() => {});

    this._tokenAuthService
      .authenticate(this.authenticateModel)
      .pipe(
        finalize(() => {
          finallyCallback();
        })
      )
      .subscribe((result: AuthenticateResultModel) => {
        this.processAuthenticateResult(result);
      });
  }

  public newAuthenticate(email: string, password: string, tenancyName: string) {
    this.tenancyName = tenancyName;
    this._tokenAuthService
      .newAuthenticate(email, password, tenancyName)
      .pipe(untilDestroyed(this))

      .subscribe(
        (result: AuthenticateResultModel) => {
          this.processAuthenticateResult(result);
        },
        (error) => {
          const dialogRef = this._dialog.open(PickPlanComponent, {
            disableClose: false,
            width: "70%",
            data: {
              tenancyName: tenancyName,
            },
          });
          dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((result) => {
              //after close
            });
        }
      );
  }

  private processAuthenticateResult(
    authenticateResult: AuthenticateResultModel
  ) {
    this.authenticateResult = authenticateResult;

    if (authenticateResult.accessToken) {
      // Successfully logged in
      this.login(
        authenticateResult.accessToken,
        authenticateResult.encryptedAccessToken,
        authenticateResult.expireInSeconds,
        this.rememberMe
      );
    } else {
      // Unexpected result!
      abp.message.error(this.l("WrongPasswordOrEmailAddress"), this.l("Error"));

      this._logService.warn("Unexpected authenticateResult!");
      this._router.navigate(["account/login"]);
    }
  }

  public showPopupModal(templateName: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateName, {
      class: "modal-dialog-centered modal-lg",
    });
    this.modalService.onHide.pipe(untilDestroyed(this)).subscribe((result) => {
      console.log("closed method ");
    });
  }

  public modalCloseFunction(value) {
    this.modalRef.hide();
  }

  private login(
    accessToken: string,
    encryptedAccessToken: string,
    expireInSeconds: number,
    rememberMe?: boolean
  ): void {
    const tokenExpireDate = rememberMe
      ? new Date(new Date().getTime() + 1000 * expireInSeconds)
      : undefined;

    this._tokenService1.setToken(accessToken, tokenExpireDate);

    this._utilsService.setCookieValue(
      AppConsts.authorization.encryptedAuthTokenName,
      encryptedAccessToken,
      tokenExpireDate,
      abp.appPath
    );

    this._tenantService1
      .getTenantByTenancyName(this.tenancyName)
      .pipe(
        switchMap((tenantObj: TenantDto) =>
          this._companyProfileService.getCompanyProfileByTenantId(tenantObj.id)
        )
      )
      .subscribe({
        next: (companyObj: CompanyProfile) => this.getSupplier(companyObj),
        error: (err) => console.error("Error fetching data:", err),
      });
  }

  private clear(): void {
    this.authenticateModel = new AuthenticateModel();
    this.authenticateModel.rememberClient = false;
    this.authenticateResult = null;
    this.rememberMe = false;
  }

  public getSupplier(myCompany: CompanyProfile) {
    this._supplierService
      .getSupplierByRegistrationNo(myCompany.companyRegNo)
      .pipe(untilDestroyed(this))
      .subscribe((supplierObj) => {
        abp.ui.clearBusy();

        if (supplierObj.tenantId > 0) {
          this._tenantService2
            .getTenancyNameById(supplierObj.tenantId)
            .pipe(untilDestroyed(this))

            .subscribe((tenancyName) => {
              this.scfTenancyName = tenancyName;

              this.scfTenantId = supplierObj.tenantId;

              if (
                myCompany.scfPassword != null &&
                myCompany.scfPassword.length > 0
              ) {
                const aeskey = "MbPeShVmYq3t6w9z$C&F)J@NcRfTjWnZ";

                this._companyProfileService
                  .decryptString(aeskey, myCompany.scfPassword)
                  .pipe(untilDestroyed(this))

                  .subscribe((password) => {
                    if (password.length > 0) {
                      this.userPassword = password;
                      this.userEmail = supplierObj.picEmail;
                      this.scfAuth();
                    } else {
                      location.href = AppConsts.appBaseUrl;
                    }
                  });
              }
            });
        } else {
          location.href = AppConsts.appBaseUrl;
        }
      });
  }

  public scfAuth() {
    var authBody = new scf.AuthenticateModel();
    authBody.userNameOrEmailAddress = this.userEmail;
    authBody.password = this.userPassword;

    this._tokenService2
      .authenticateWithTenancyName(this.scfTenancyName, authBody)
      .pipe(untilDestroyed(this))

      .subscribe((result: scf.AuthenticateResultModel) => {
        abp.ui.clearBusy();

        if (result.accessToken) {
          const tokenExpireDate = new Date(
            new Date().getTime() + 1000 * result.expireInSeconds
          );
          console.log(result.accessToken);
          this._utilsService.setCookieValue(
            "scfToken",
            result.accessToken,
            tokenExpireDate
          );
          this._utilsService.setCookieValue(
            "scfTenantId",
            result.tenantId.toString()
          );
        }

        location.href = AppConsts.appBaseUrl;
      });
  }

  l(key: string, ...args: any[]): string {
    let localizedText = this.localization.localize(
      key,
      this.localizationSourceName
    );

    if (!localizedText) {
      localizedText = key;
    }

    if (!args || !args.length) {
      return localizedText;
    }

    args.unshift(localizedText);
    return abp.utils.formatString.apply(this, args);
  }
}
