<mat-error *ngIf="control.invalid && (control.dirty || control.touched)">
  <span *ngFor="let validationError of validationErrors">
    <span
      *ngIf="!!this.control.errors[validationError.name]"
      class="error invalid-feedback"
      [class.d-block]="!!this.control.errors[validationError.name]"
    >
      {{ getValidationErrorMessage(validationError) }}
    </span>
  </span>
</mat-error>
