<div class="row">
  <div class="col ml-4 text-left" *ngIf="myData.title">
    <h2>{{ myData.title }}</h2>
  </div>
  <div class="col mr-2 text-right">
    <ng-container *ngIf="isAcceptance">
      <button
        mat-button
        class="btn btn-danger kt-font-bold kt-font-transform-u mr-2 text-light"
        type="button"
        (click)="closeFunction()"
      >
        Decline
      </button>
      <button
        mat-button
        class="btn btn-primary kt-font-bold kt-font-transform-u mr-2 text-light"
        type="button"
        (click)="addFunction()"
      >
        Accept
      </button>
    </ng-container>

    <ng-container *ngIf="!isAcceptance">
      <button
        mat-button
        class="btn btn-secondary kt-font-bold kt-font-transform-u mr-2"
        type="button"
        (click)="closeFunction()"
      >
        Close
      </button>
    </ng-container>
  </div>
  <div class="w-100 mt-3">
    <pdf-viewer
      [src]="pdfSrc"
      [render-text]="true"
      [original-size]="false"
      style="width: 100%; height: 80vh"
    ></pdf-viewer>
  </div>
</div>
