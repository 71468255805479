import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-custom-dropdown",
  templateUrl: "./custom-dropdown.component.html",
  styleUrls: ["./custom-dropdown.component.css"],
})
export class CustomDropdownComponent implements OnInit {
  public filteredItem: ReplaySubject<any> = new ReplaySubject(1);
  public itemFilterCtrl: FormControl = new FormControl();

  @Input() items;
  @Input() title;
  @Input() placeholderLabel;
  @Input() notFoundLabel;
  @Input() initialValue;
  @Input() selectedItem;


  @Output() dropdownEvent: EventEmitter<{}> = new EventEmitter<{}>();

  protected _onDestroy = new Subject<void>();

  constructor() { }

  ngOnInit() {
    this.getData();
    this.itemFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItem();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.getData();
    }
  }


  getData() {
    if (this.items) {
      this.filteredItem.next(this.items.slice());
      this.selectedItem = this.initialValue;
    } else {
      window.location.reload();
    }
  }

  compareFn(a, b) {
    if (a == b) return true;
    return false;
  }

  protected filterItem() {
    // get the search keyword
    let search = this.itemFilterCtrl.value;
    if (!search) {
      this.filteredItem.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItem.next(
      this.items.filter((item) => item.toLowerCase().indexOf(search) > -1)
    );
  }

  selectionChange($event) {
    this.selectedItem = $event.value;

    var returnObject = {
      type: "dropdown",
      selectedItem: this.selectedItem,
    };
    this.dropdownEvent.emit(returnObject);
  }
}
