<div *ngIf="isDialog" class="row">
  <div class="col mr-2 text-right">
    <button
      mat-button
      class="btn btn-secondary kt-font-bold kt-font-transform-u mr-2"
      type="button"
      (click)="close()"
    >
      {{ "Close" | localize }}
    </button>
  </div>
</div>
<div #youTubePlayer
  class="container-fluid h-100 d-flex align-items-center justify-content-center"
>
  <div *ngIf="!isLoading" style="position: relative">
    <youtube-player
      [videoId]="videoId"
      [height]="height"
      [width]="width"
    ></youtube-player>
  </div>
</div>
