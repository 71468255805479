<div class="w-100">
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "Location" | localize }}</mat-label>
        <mat-select
            [(ngModel)]="selectedLocation"
            (selectionChange)="onSelect($event.value)"
        >
            <mat-option *ngFor="let data of locationDropdown" [value]="data">
            {{ data | localize }}
            </mat-option>
        </mat-select>
        <mat-error>* {{ "Required" | localize }}</mat-error>
    </mat-form-field>
  </div>
  