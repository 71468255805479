<div class="col ml-4 text-left">
  <div class="col mr-2 text-right">
    <button
      mat-button
      class="btn btn-secondary kt-font-bold kt-font-transform-u mr-2"
      type="button"
      (click)="closeFunction()"
    >
      Close
    </button>
  </div>
</div>
<mat-dialog-content
  ><div class="w-100 mt-3">
    <pdf-viewer
      [src]="pdfSrc"
      [render-text]="true"
      [original-size]="false"
      style="width: 100%; height: 80vh"
    ></pdf-viewer>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="row justify-content-center mb-3">
  <button
    mat-button
    class="btn btn-primary kt-font-bold kt-font-transform-u col-5 ml-2 text-light"
    type="button"
    (click)="addFunction()"
  >
    Make Payment Now
  </button>
</div>
