<div class="row">
  <div class="col ml-4 text-left">
    <label>Create New Journal Entry</label>
  </div>
  <div class="col mr-2 text-right" *ngIf="!isMigration">
    <button
      mat-button
      class="btn btn-secondary kt-font-bold kt-font-transform-u mr-2"
      type="button"
      (click)="closeFunction()"
    >
      {{ "Close" | localize }}
    </button>
  </div>
</div>
<mat-dialog-content>
  <form [formGroup]="formGroupName">
    <div class="row justify-content-center">
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Journal Reference No</mat-label>
          <input matInput formControlName="jvNo" />
          <mat-error>* {{ "Required" | localize }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <ng-container *ngIf="dropdownList.length > 0">
          <app-custom-dropdown
            [items]="dropdownList"
            [title]="'Journal Type'"
            [placeholderLabel]="'Search' | localize"
            [notFoundLabel]="'NotFound' | localize"
            (dropdownEvent)="onSelectType($event)"
            [initialValue]="formGroupName.controls.journalType.value"
          ></app-custom-dropdown>
        </ng-container>
        <!-- <mat-form-field appearance="outline" class="w-100">
          <mat-label>Journal Type</mat-label>
          <input matInput formControlName="journalType" />
          <mat-error>* {{ "Required" | localize }}</mat-error>
        </mat-form-field> -->
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="date" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error>* {{ "Required" | localize }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Reversal Date (optional)</mat-label>
          <input matInput [matDatepicker]="picker2" formControlName="reversalDate" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "Description" | localize }}</mat-label>
          <input matInput formControlName="description" />
          <mat-error>* {{ "Required" | localize }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Prepared By</mat-label>
          <input matInput formControlName="requestedBy" />
          <mat-error>* {{ "Required" | localize }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Upload Supporting Document</mat-label>
          <!-- TODO: To replace ngx-mat-file-input with something else -->
          <ngx-mat-file-input
            [accept]="fileAccept"
            (change)="uploadDocument($event)"
            [valuePlaceholder]="formGroupName.controls.attachmentUrl.value"
          >
          </ngx-mat-file-input>
          <mat-error>* {{ "Required" | localize }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="row justify-content-center">
    <div class="mb-2">
      <app-custom-table
        [inputTableDataSource]="MyTable"
        [tableColumns]="MyTableColumn"
        [showTitle]="true"
        [isLeftTitle]="false"
        [isInputTable]="true"
        [titleRowArray]="[MyTable.data.length - 1]"
        (inputMessageEvent)="inputMessageEvent($event)"
        [showBorder]="true"
        (messageEvent)="messageEvent($event)"
        [addButton]="true"
        [addString]="'Add Journal Entry'"
        (addEvent)="addEntry()"
        [rowActionIcon]="'trash'"
        (rowAction)="removeTable($event)"
      >
      </app-custom-table>
    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions class="row justify-content-center mb-3">
  <div class="col"></div>

  <div class="col-4 text-right mt-2">
    <button
      mat-button
      class="btn btn-primary kt-font-bold kt-font-transform-u ml-2 text-light"
      type="button"
      (click)="addFunction()"
    >
      Confirm & Submit
    </button>
  </div>
</div>
