import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, pluck } from "rxjs/operators";
import { LayoutConfig } from "./layout-config";

@Injectable()
export class LayoutStoreService {
  public readonly config$: Observable<LayoutConfig>;
  private readonly initialLayoutConfig: LayoutConfig = {
    sidebarExpanded: false,
  };
  private configSource: BehaviorSubject<LayoutConfig>;

  private readonly pageTitle$: Observable<string>;
  private readonly initialPageTitle = "Home Page";
  private pageTitleSource: BehaviorSubject<string>;

  private readonly modeId$: Observable<number>;
  private readonly initialmodeId = 1;
  private modeIdSource: BehaviorSubject<number>;
  editionId = 1;

  private readonly showLandingPic$: Observable<boolean>;
  private showLandingPicSource: BehaviorSubject<boolean>;


  constructor() {
    this.configSource = new BehaviorSubject(this.initialLayoutConfig);
    this.config$ = this.configSource.asObservable();

    this.pageTitleSource = new BehaviorSubject(this.initialPageTitle);
    this.pageTitle$ = this.pageTitleSource.asObservable();

    this.modeIdSource = new BehaviorSubject(this.initialmodeId);
    this.modeId$ = this.modeIdSource.asObservable();

    this.showLandingPicSource = new BehaviorSubject(true);
    this.showLandingPic$ = this.showLandingPicSource.asObservable();
  }

  get sidebarExpanded(): Observable<boolean> {
    return this.config$.pipe(
      pluck("sidebarExpanded"),
      distinctUntilChanged()
    ) as Observable<boolean>;
  }

  public setSidebarExpanded(value: boolean): void {
    this.configSource.next(
      Object.assign(this.configSource.value, { sidebarExpanded: value })
    );
  }

  get pageTitle(): Observable<string> {
    return this.pageTitle$ as Observable<string>;
  }

  public setPageTitle(value: string): void {
    this.pageTitleSource.next(value);
  }

  get getModeId(): Observable<number> {
    return this.modeId$ as Observable<number>;
  }



  public setModeId(value: number): void {
    this.modeIdSource.next(value);
  }

  get getShowpicture(): Observable<boolean> {
    return this.showLandingPic$ as Observable<boolean>;
  }

  public setShowpicture(value: boolean): void {
    this.showLandingPicSource.next(value);
  }
}
