import { Component, Inject, Injector, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { accountModuleAnimation } from "@shared/animations/routerTransition";
import { Tenant, TenantServiceProxy } from "@shared/service-proxies/service-proxies";
import moment from "moment";
import { PurchasePlanComponent } from './purchase-plan/purchase-plan.component';
import { AppComponentBase } from '@shared/app-component-base';
import { PickContactComponent } from './pick-contact/pick-contact.component';

@Component({
  selector: "app-pick-plan",
  templateUrl: "./pick-plan.component.html",
  styleUrls: ["./pick-plan.component.css"],
  animations: [accountModuleAnimation()],
})
export class PickPlanComponent extends AppComponentBase implements OnInit {
  tenancyName;
  myTenant: Tenant;
  edition;
  subscriptionEndDateUtc: moment.Moment;


  pricingPlans = [
    {
      name: 'Standard',
      originalPrice: '200',
      earlyBird:'60',
      planType: 'Basic',
      description: 'Ideal for small business or startups that need compliant invoicing',
      features: {
        featureA: true,
        featureB: true,
        featureC: true,
        featureD: false,
        featureE: false,
        featureF: false,
        featureG: false,
        featureH: false,
        featureI: false,
      }
    },
    {
      name: 'Essential',
      originalPrice: '800',
      earlyBird:'400',
      planType:'Essential',
      description: 'Ideal for small business or startups in need of essential operational tools',
      features: {
        featureA: true,
        featureB: true,
        featureC: true,
        featureD: true,
        featureE: true,
        featureF: true,
        featureG: false,
        featureH: false,
        featureI: false,
      }
    },
    {
      name: 'Growth',
      originalPrice: '900',
      earlyBird:'500',
      planType:'Growth',
      description: 'Ideal for growing business with enhanced reporting and financial oversight',
      features: {
        featureA: true,
        featureB: true,
        featureC: true,
        featureD: true,
        featureE: true,
        featureF: true,
        featureG: true,
        featureH: false,
        featureI: false,
      }
    },
    {
      name: 'Performance',
      originalPrice: '950',
      earlyBird:'600',
      planType:'Performance',
      description: 'Ideal for business that aims to optimize project execution and performance',
      features: {
        featureA: true,
        featureB: true,
        featureC: true,
        featureD: true,
        featureE: true,
        featureF: true,
        featureG: true,
        featureH: true,
        featureI: false,
      }
    },
    {
      name: 'Advance',
      originalPrice: '1250',
      earlyBird:'800',
      planType:'Advance',
      description: 'For enterprise business, includes everything from previous plan and ESG Module for ESG Compliance ',
      features: {
        featureA: true,
        featureB: true,
        featureC: true,
        featureD: true,
        featureE: true,
        featureF: true,
        featureG: true,
        featureH: true,
        featureI: true,
      }
    }
  ];



  constructor(
    injector: Injector,
    private _tenantService: TenantServiceProxy,
    @Inject(MAT_DIALOG_DATA) public myData: DialogData,
    public _dialog: MatDialog,
    public dialogRef: MatDialogRef<PickPlanComponent>) {
    super(injector);

  }

  ngOnInit(): void {



    if (this.myData.tenancyName) {
      this.tenancyName = this.myData.tenancyName;


    } else {
      this._tenantService.getTenantById(this.appSession.tenantId).subscribe(
        myTenant => {
          this.myTenant = myTenant;
          this.tenancyName = myTenant.tenancyName;
          this.edition = myTenant.edition.name;
          this.subscriptionEndDateUtc = myTenant.subscriptionEndDateUtc;

        }
      )

    }

  }

  pickBasic() {

    if (this.edition != "Premium") {
      this.closeFunction();

      const dialogRef = this._dialog.open(PurchasePlanComponent, {
        disableClose: false,
        width: "70%",
        data: {
          planId: 1,
          tenancyName: this.tenancyName,
          subscriptionEndDateUtc: this.subscriptionEndDateUtc
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        //after close
      });
    } else {
      abp.message.error(this.l("YouAlreadyHaveAnActivePremiumPlanYouCanDowngradeToBasicPlanAfterYourPremiumPlanExpired"));
    }
  }

  pickPlan(planType: string) {
    this.closeFunction();

    const planIdType = {
      Basic: 1,
      Essential: 2,
      Growth: 7,
      Performance: 8,
      Advance: 9,
    }

    const dialogRef = this._dialog.open(PurchasePlanComponent, {
      disableClose: false,
      width: "70%",
      data: {
        planId: planIdType[planType],
        tenancyName: this.tenancyName,
        subscriptionEndDateUtc: this.subscriptionEndDateUtc
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      //after close
    });
  }



  contact() {
    this.closeFunction();
    const dialogRef = this._dialog.open(PickContactComponent, {
      disableClose: false,
      width: "70%",
      data: {
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      //after close
    });
  }

  closeFunction() {
    this.dialogRef.close();
  }

  upgrade() {
    this.myTenant = null;
  }

  convertDate(momentObject) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var retunObject = moment(momentObject).tz(userTimezone).format("DD/MM/YYYY");
    if (retunObject == "01/01/0001" || momentObject == null) {
      return "";
    } else {
      return retunObject;
    }
  }
}


export interface DialogData {
  tenancyName: string;
}