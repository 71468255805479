<ng-container *ngIf="isUser">
  <ng-container *ngIf="isPrintable">
    <div class="row justify-content-end">
      <div class="col"></div>
      <div class="text-right">
        <button mat-button style="white-space: normal" (click)="printTable()">
          <mat-icon
            class="mr-1 pt-1"
            color="primary"
            style="width: 32px; height: 32px"
          >
            print
          </mat-icon>
          <u class="text-primary"> {{ "PDF" | localize }} </u>
        </button>
      </div>
      <div class="text-right">
        <button
          mat-button
          style="white-space: normal"
          (click)="exportToExcel()"
        >
          <mat-icon
            class="mr-1 pt-1"
            color="primary"
            style="width: 32px; height: 32px"
          >
            print
          </mat-icon>
          <u class="text-primary"> {{ "Excel" | localize }} </u>
        </button>
      </div>
    </div>
  </ng-container>

  <!-- Filter -->
  <ng-container *ngIf="isFilterable" [matColumnDef]="this.rowActionIcon">
    <div class="row">
      <div class="row col-6 align-items-center">
        <div style="margin-bottom: 10px">
          <div class="input-group" style="width: 160%">
            <div class="input-group-prepend">
              <button
                type="button"
                class="btn bg-primary"
                (click)="applyFilter()"
              >
                <i class="fas fa-search"></i>
              </button>
            </div>
            <input
              name="filterText"
              autoFocus
              class="form-control m-input"
              [placeholder]="l('SearchWithThreeDot')"
              type="text"
              [value]="inputFilterText ? inputFilterText : ''"
              [(ngModel)]="filterText"
              (keyup.enter)="applyFilter()"
            />
          </div>
        </div>
      </div>
      <div *ngIf="addButton == true" class="col text-right">
        <button
          mat-flat-button
          color="primary"
          *ngIf="addButton"
          style="
            height: 50px;
            margin-right: 5px;
            margin-left: 15px;
            margin-top: 4px;
            margin-bottom: 18px;
          "
          (click)="addMessage()"
        >
          {{ addString | localize }}
        </button>
      </div>
      <div
        class="col row w-100 justify-content-end"
        *ngIf="isFilterRange == true"
        style="max-height: 75px !important"
      >
        <mat-card-header style="position: relative; right: 20px">
          <mat-card-subtitle>
            <mat-card-subtitle *ngIf="selectedIndex == 2">
              <div class="row justify-content-end">
                <mat-form-field appearance="outline">
                  <mat-label>{{ "EnterADateRange" | localize }}</mat-label>
                  <mat-date-range-input
                    [formGroup]="range"
                    [rangePicker]="picker"
                  >
                    <input
                      matStartDate
                      formControlName="start"
                      [placeholder]="'StartDate' | localize"
                    />
                    <input
                      matEndDate
                      formControlName="end"
                      [placeholder]="'EndDate' | localize"
                      (dateChange)="rangePicked()"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>

                  <mat-error
                    *ngIf="range.controls.start.hasError('matStartDateInvalid')"
                    >{{ "InvalidStartDate" | localize }}</mat-error
                  >
                  <mat-error
                    *ngIf="range.controls.end.hasError('matEndDateInvalid')"
                    >{{ "InvalidEndDate" | localize }}</mat-error
                  >
                </mat-form-field>
              </div>
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="selectedIndex == 1">
              <div class="row justify-content-end">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>{{ "Year" | localize }}</mat-label>
                  <mat-select
                    #financingType
                    [(ngModel)]="selectedYear"
                    (selectionChange)="onSelectYear($event)"
                  >
                    <mat-option
                      *ngFor="let data of yearDropdown"
                      [value]="data"
                    >
                      {{ data | localize }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-card-subtitle>

            <mat-card-subtitle *ngIf="selectedIndex == 0">
              <div class="row">
                <div class="col-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{ "Month" | localize }}</mat-label>
                    <mat-select
                      #financingType
                      [(ngModel)]="selectedMonthString"
                      (selectionChange)="onSelectMonth($event)"
                    >
                      <mat-option
                        *ngFor="let data of monthDropdown"
                        [value]="data"
                      >
                        {{ data | localize }}
                      </mat-option>
                    </mat-select>
                    <mat-error>* {{ "Required" | localize }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{ "Year" | localize }}</mat-label>
                    <mat-select
                      #financingType
                      [(ngModel)]="selectedYear"
                      (selectionChange)="onSelectYear($event)"
                    >
                      <mat-option
                        *ngFor="let data of yearDropdown"
                        [value]="data"
                      >
                        {{ data | localize }}
                      </mat-option>
                    </mat-select>
                    <mat-error>* {{ "Required" | localize }}</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </mat-card-subtitle>
          </mat-card-subtitle>
        </mat-card-header>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isFilterRange">
    <div *ngIf="isFilterable == false" style="max-height: 75px !important">
      <div class="row" style="position: relative; right: 14px">
        <div *ngIf="addButton == true" class="col">
          <button
            mat-flat-button
            color="primary"
            *ngIf="addButton"
            style="
              height: 50px;
              margin-right: 5px;
              margin-left: 15px;
              margin-top: 4px;
              margin-bottom: 18px;
            "
            (click)="addMessage()"
          >
            {{ addString | localize }}
          </button>
        </div>
        <div class="row justify-content-end">
          <div
            class="col row w-100 justify-content-end"
            *ngIf="isFilterRange == true"
            style="max-height: 75px !important"
          >
            <mat-card-header style="position: relative; right: 20px">
              <mat-card-subtitle>
                <mat-card-subtitle *ngIf="selectedIndex == 2">
                  <div class="row justify-content-end">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ "EnterADateRange" | localize }}</mat-label>
                      <mat-date-range-input
                        [formGroup]="range"
                        [rangePicker]="picker"
                      >
                        <input
                          matStartDate
                          formControlName="start"
                          [placeholder]="'StartDate' | localize"
                        />
                        <input
                          matEndDate
                          formControlName="end"
                          [placeholder]="'EndDate' | localize"
                          (dateChange)="rangePicked()"
                        />
                      </mat-date-range-input>
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                      ></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>

                      <mat-error
                        *ngIf="
                          range.controls.start.hasError('matStartDateInvalid')
                        "
                        >{{ "InvalidStartDate" | localize }}</mat-error
                      >
                      <mat-error
                        *ngIf="range.controls.end.hasError('matEndDateInvalid')"
                        >{{ "InvalidEndDate" | localize }}</mat-error
                      >
                    </mat-form-field>
                  </div>
                </mat-card-subtitle>
                <mat-card-subtitle *ngIf="selectedIndex == 1">
                  <div class="row justify-content-end">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>{{ "Year" | localize }}</mat-label>
                      <mat-select
                        #financingType
                        [(ngModel)]="selectedYear"
                        (selectionChange)="onSelectYear($event)"
                      >
                        <mat-option
                          *ngFor="let data of yearDropdown"
                          [value]="data"
                        >
                          {{ data | localize }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </mat-card-subtitle>

                <mat-card-subtitle *ngIf="selectedIndex == 0">
                  <div class="row">
                    <div class="col-6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{ "Month" | localize }}</mat-label>
                        <mat-select
                          #financingType
                          [(ngModel)]="selectedMonthString"
                          (selectionChange)="onSelectMonth($event)"
                        >
                          <mat-option
                            *ngFor="let data of monthDropdown"
                            [value]="data"
                          >
                            {{ data | localize }}
                          </mat-option>
                        </mat-select>
                        <mat-error>* {{ "Required" | localize }}</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{ "Year" | localize }}</mat-label>
                        <mat-select
                          #financingType
                          [(ngModel)]="selectedYear"
                          (selectionChange)="onSelectYear($event)"
                        >
                          <mat-option
                            *ngFor="let data of yearDropdown"
                            [value]="data"
                          >
                            {{ data | localize }}
                          </mat-option>
                        </mat-select>
                        <mat-error>* {{ "Required" | localize }}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </mat-card-subtitle>
              </mat-card-subtitle>
            </mat-card-header>
          </div>
        </div>
      </div>
    </div>

    <div class="col row w-100 justify-content-end">
      <mat-card-header>
        <div style="width: 100%; position: relative">
          <mat-form-field>
            <mat-select
              [(value)]="selectedIndex"
              (selectionChange)="onSelect($event)"
            >
              <mat-option
                *ngFor="let data of indexRange"
                [value]="data.value"
                >{{ data.show | localize }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-header>
    </div>
    <!-- <div class="row">
      <div class="col-md-12 text-start py-0 px-2">
        <div class="w-100">
          <div class="p-0">
            <mat-tab-group
              mat-stretch-tabs
              [(selectedIndex)]="selectedIndex"
              (selectedTabChange)="onSelect($event)"
              class="remove-border-bottom all-border d-none d-md-block"
            >
              <mat-tab [label]="'MonthToDate' | localize"></mat-tab>
              <mat-tab [label]="'YearToDate' | localize"></mat-tab>
              <mat-tab
                [label]="'CustomizeYourOwnReportingPeriod' | localize"
              ></mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div> -->
  </ng-container>

  <ng-container *ngIf="isFilterRange == false">
    <div
      class="row justify-content-end"
      *ngIf="isFilterable == false"
      style="max-height: 75px !important"
    >
      <div class="col"></div>
      <div class="text-right">
        <button
          mat-flat-button
          color="primary"
          *ngIf="addButton"
          style="
            height: 50px;
            margin-right: 5px;
            margin-left: 15px;
            margin-top: 4px;
            margin-bottom: 18px;
          "
          (click)="addMessage()"
        >
          {{ addString | localize }}
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <!-- Table -->

  <div
    class="table-responsive"
    [style]="
      isStickyTitle == true ? 'overflow: auto; height:' + stickyHeight : ''
    "
  >
    <table
      mat-table
      [dataSource]="tableDataSource"
      matSort
      (matSortChange)="sortTable($event)"
      class="p-1 table-hover"
      [style]="isStickyTitle == true ? 'border-collapse: separate' : ''"
    >
      <!-- action column -->
      <ng-container [matColumnDef]="rowActionIcon">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="newTitle"
          [ngStyle]="{
            background: '#f4f4f4',
            border: showBorder == true ? '1px solid #c8c8c8' : ''
          }"
        >
          {{ "Action" | localize }}
        </th>

        <td
          *matCellDef="let element"
          [id]="rowActionIcon"
          class="actionRow justify-content-center"
          [ngClass]="element?.darkTitle == true ? 'rowTitle' : 'newData2'"
          [ngStyle]="{
            background: '#f4f4f4',
            border: showBorder == true ? '1px solid #c8c8c8' : ''
          }"
        >
          <ng-container *ngIf="canEdit == true && element?.id != null">
            <div class="row ml-1" style="flex-wrap: nowrap">
              <ng-container *ngIf="rowActionIcon != 'attachment'">
                <button
                  type="button"
                  class="btn btn-outline-primary btn-icon mt-2 actionRow mr-1"
                  (click)="emitEditRowAction(element)"
                >
                  <i class="fa fa-edit"></i>
                </button>
              </ng-container>
              <ng-container *ngIf="rowActionIcon == 'attachment'">
                <button
                  type="button"
                  class="btn btn-outline-primary btn-icon mt-2 actionRow mr-1"
                  (click)="emitEditRowAction(element)"
                >
                  <i class="fa fa-paperclip"></i>
                </button>
              </ng-container>
              <button
                type="button"
                class="btn btn-outline-danger btn-icon mt-2 actionRow"
                (click)="emitRowAction(element)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </ng-container>

          <ng-container *ngIf="canEdit == false && element.id != null">
            <ng-container *ngIf="rowActionIcon == 'edit'">
              <button
                type="button"
                class="btn btn-outline-primary btn-icon mt-2 actionRow mr-1"
                (click)="emitRowAction(element)"
              >
                <i class="fa fa-edit"></i>
              </button>
            </ng-container>

            <ng-container *ngIf="rowActionIcon != 'edit'">
              <ng-container *ngIf="rowActionIcon == 'trash'">
                <button
                  type="button"
                  class="btn btn-outline-danger btn-icon mt-2 actionRow"
                  (click)="emitRowAction(element)"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </ng-container>

              <ng-container *ngIf="rowActionIcon != 'trash'">
                <button
                  type="button"
                  class="btn btn-outline-primary btn-icon mt-2 actionRow"
                  (click)="emitRowAction(element)"
                >
                  <mat-icon class="my-icon">{{ rowActionIcon }}</mat-icon>
                </button>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="canEdit == false && element.id == null">
            <ng-container *ngIf="rowActionIcon == 'edit'">
              <button
                type="button"
                class="btn btn-outline-primary btn-icon mt-2 actionRow mr-1"
                (click)="emitRowAction(element)"
              >
                <i class="fa fa-edit"></i>
              </button>
            </ng-container>

            <ng-container
              *ngIf="
                rowActionIcon != 'edit' &&
                titleRowArray.includes(
                  tableDataSource.filteredData.indexOf(element)
                ) == false
              "
            >
              <button
                type="button"
                class="btn btn-outline-danger btn-icon mt-2 actionRow"
                (click)="emitRowAction(element)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>
      <ng-container
        *ngFor="let tableColumn of tableColumns"
        [matColumnDef]="tableColumn.name"
      >
        <!-- if tooltip column header -->
        <ng-container *ngIf="tableColumn.tooltip == true">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="rowTitle"
            [ngStyle]="{
              background: '#f4f4f4',
              width: tableColumn.width ? tableColumn.width : '100vw',
              border: showBorder == true ? '1px solid #c8c8c8' : ''
            }"
          >
            <div class="text-center row justify-content-center">
              <span class="pt-1">{{ tableColumn.name }}</span>
              <mat-icon
                matTooltip="{{ tableColumn.tooltipText }}"
                matTooltipPosition="above"
              >
                info_outline
              </mat-icon>
            </div>
          </th>
        </ng-container>

        <!-- if sortable column header -->
        <ng-container
          *ngIf="
            tableColumn.isSortable == true || isSortable == true;
            else notSortable
          "
        >
          <th
            mat-sort-header
            class="rowTitle"
            [ngStyle]="{
              width: tableColumn.width ? tableColumn.width : '100vw',
              border: showBorder == true ? '1px solid #c8c8c8' : ''
            }"
            header-cell
            *matHeaderCellDef
            [mat-sort-header]="tableColumn.name"
            [arrowPosition]="
              tableColumn.position === 'right' ? 'before' : 'after'
            "
          >
            <div class="justify-content-center">
              {{ tableColumn.name }}
            </div>
          </th>
        </ng-container>
        <!-- else not sortable -->
        <ng-template #notSortable>
          <ng-container
            mat-header-cell
            *matHeaderCellDef="let element"
            [class.text-right]="tableColumn.position == 'right'"
          >
            <th
              class="rowTitle"
              [ngStyle]="{
                width: tableColumn.width ? tableColumn.width : '100vw',
                border: showBorder == true ? '1px solid #c8c8c8' : ''
              }"
            >
              <ng-container
                *ngIf="
                  tableColumn.type == 'checkbox' &&
                    tableColumn.name.includes('col');
                  else elseBlock
                "
              >
                <mat-checkbox
                  class="w-100"
                  [labelPosition]="'after'"
                  color="primary"
                  (change)="allCheck($event, tableColumn.dataKey)"
                  [(ngModel)]="selectAll"
                  *ngIf="element?.[tableColumn.dataKey] != 'na'"
                >
                </mat-checkbox>
              </ng-container>
              <ng-template #elseBlock>
                {{ tableColumn.name }}
              </ng-template>
            </th>
          </ng-container>
        </ng-template>

        <!-- column data -->

        <ng-container *matCellDef="let element">
          <ng-container *ngIf="isLeftTitle == true; else notLeftTitle">
            <ng-container
              *ngIf="
                titleRowArray.includes(
                  tableDataSource.filteredData.indexOf(element)
                ) == false;
                else darkTitle
              "
            >
              <ng-container *ngIf="tableColumns.indexOf(tableColumn) == 0">
                <ng-container
                  *ngIf="
                    titleRowArray.includes(
                      tableDataSource.filteredData.indexOf(element)
                    ) == false;
                    else darkTitle
                  "
                >
                  <td
                    class="rowTitle"
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    {{ element[tableColumn.dataKey] | localize }}
                  </td>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="tableColumns.indexOf(tableColumn) != 0">
                <ng-container *ngIf="tableColumn.type == 'underlineButton'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData2'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <button
                      mat-button
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          tableColumn.typeName,
                          tableColumn.dataKey
                        )
                      "
                      style="white-space: normal"
                    >
                      <u class="text-primary">
                        {{
                          element?.title
                            ? (element.title | localize)
                            : (element | localize)
                            ? (element[tableColumn.dataKey] | localize)
                            : ""
                        }}
                      </u>
                    </button>
                  </td>
                </ng-container>

                <ng-container
                  *ngIf="isInputTable == false && tableColumn.type == null"
                >
                  <ng-container
                    *ngIf="
                      mergeColumnArray.includes(
                        tableColumns.indexOf(tableColumn) + 1
                      ) == false;
                      else darkTitle
                    "
                  >
                    <td
                      [ngClass]="
                        element?.darkTitle == true ? 'rowTitle' : 'newData'
                      "
                      [ngStyle]="{
                        width: tableColumn.width ? tableColumn.width : '100vw',
                        border: showBorder == true ? '1px solid #c8c8c8' : ''
                      }"
                    >
                      {{
                        isNumber(
                          element[tableColumn.dataKey],
                          tableColumn.name.toLowerCase()
                        )
                          ? (element[tableColumn.dataKey] | number : "1.2-2")
                          : (element[tableColumn.dataKey] | localize)
                      }}
                    </td>
                  </ng-container>
                </ng-container>
                <ng-container
                  *ngIf="isInputTable == true && tableColumn.type == null"
                >
                  <td
                    class="newData m-0 p-0"
                    [ngStyle]="{
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <ng-container>
                      <textarea
                        class="form-control newDataInput"
                        type="text"
                        style="height: 30px; border-color: gray; resize: none"
                        value="{{
                          tableDataSource.data[element.col1 - 1][
                            'col' + (tableColumns.indexOf(tableColumn) + 2)
                          ]
                        }}"
                        (change)="
                          onInputChange(
                            element,
                            tableColumns.indexOf(tableColumn),
                            $event
                          )
                        "
                      ></textarea>
                    </ng-container>
                  </td>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-template #notLeftTitle>
            <ng-container
              *ngIf="
                titleRowArray.includes(
                  tableDataSource.filteredData.indexOf(element)
                ) == false;
                else darkTitle
              "
            >
              <ng-container
                *ngIf="tableColumn.type != null && isInputTable == false"
              >
                <ng-container *ngIf="tableColumn.type == 'color'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <span
                      class="badge w-100"
                      [ngClass]="getBadgeColor(element.color)"
                      >{{ element[tableColumn.dataKey] }}</span
                    >
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'type'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <span
                      class="badge w-100"
                      [ngClass]="{
                        'badge-secondary':
                          element.status == 'Pending' ||
                          element.status == 'Disabled',
                        'badge-info': element.status == 'Sent',
                        'badge-primary':
                          element.status == 'Selected' ||
                          element.status == 'Enabled' ||
                          element.status == 'Received',
                        'badge-warning': element.status == 'Approval',
                        'badge-danger':
                          element.status == 'Overdue' ||
                          element.status == 'Rejected',
                        'badge-success':
                          element.status == 'Paid' ||
                          element.status == 'Completed' ||
                          element.status == 'Approved'
                      }"
                      >{{ element.status | localize }}</span
                    >
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'typeInt'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <span
                      class="badge w-100"
                      [ngClass]="{
                        'badge-secondary':
                          element.status == 'Pending' ||
                          element.status == 'Quoting',
                        'badge-info':
                          element.status == 'Sent' ||
                          element.status == 'Received',
                        'badge-danger': element.status == 'Rejected',
                        'badge-warning': element.status == 'PR Approved',
                        'badge-success':
                          element.status == 'PO Created' ||
                          element.status == 'Approved' ||
                          element.status == 'Paid'
                      }"
                      >{{ element.status | localize }}</span
                    >
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'typeIntFactory'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <span
                      class="badge w-100"
                      [ngClass]="{
                        'badge-secondary': element.factoryStatus == 'Pending',
                        'badge-danger': element.factoryStatus == 'Rejected',
                        'badge-success': element.factoryStatus == 'Completed'
                      }"
                      >{{ element.factoryStatus | localize }}</span
                    >
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'link'">
                  <ng-container *ngIf="element.link != null">
                    <td
                      [ngClass]="
                        element?.darkTitle == true ? 'rowTitle' : 'newData'
                      "
                      [ngStyle]="{
                        width: tableColumn.width ? tableColumn.width : '100vw',
                        border: showBorder == true ? '1px solid #c8c8c8' : ''
                      }"
                    >
                      <a
                        href="//{{ element.link }}"
                        target="_blank"
                        rel="noopener noreferrer"
                        >{{ "Open" | localize }}</a
                      >
                    </td>
                  </ng-container>
                  <ng-container *ngIf="element.link == null">
                    <td
                      [ngClass]="
                        element?.darkTitle == true ? 'rowTitle' : 'newData'
                      "
                      [ngStyle]="{
                        width: tableColumn.width ? tableColumn.width : '100vw',
                        border: showBorder == true ? '1px solid #c8c8c8' : ''
                      }"
                    >
                      <p></p>
                    </td>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'linkInvoice'">
                  <ng-container *ngIf="element.link != null">
                    <td
                      [ngClass]="
                        element?.darkTitle == true ? 'rowTitle' : 'newData'
                      "
                      [ngStyle]="{
                        width: tableColumn.width ? tableColumn.width : '100vw',
                        border: showBorder == true ? '1px solid #c8c8c8' : ''
                      }"
                    >
                      <a
                        href="//{{ element.link }}"
                        target="_blank"
                        rel="noopener noreferrer"
                        >{{ element.customerObject.invoiceReferenceNumber }}</a
                      >
                    </td>
                  </ng-container>
                  <ng-container *ngIf="element.link == null">
                    <td
                      [ngClass]="
                        element?.darkTitle == true ? 'rowTitle' : 'newData'
                      "
                      [ngStyle]="{
                        width: tableColumn.width ? tableColumn.width : '100vw',
                        border: showBorder == true ? '1px solid #c8c8c8' : ''
                      }"
                    >
                      <p></p>
                    </td>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'underlineButton'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData2'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <button
                      mat-button
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          tableColumn.typeName,
                          tableColumn.dataKey
                        )
                      "
                      style="white-space: normal"
                    >
                      <u class="text-primary">
                        {{
                          element?.title
                            ? (element.title | localize)
                            : (element | localize)
                            ? (element[tableColumn.dataKey] | localize)
                            : ""
                        }}
                      </u>
                    </button>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'lhdnClassification'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData2'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <ng-container *ngIf="classificationCodesStrings.length > 0">
                      <app-custom-dropdown
                        [items]="classificationCodesStrings"
                        [title]="'Classification'"
                        [placeholderLabel]="'Search Classification'"
                        [notFoundLabel]="'Classification Not Found'"
                        (dropdownEvent)="
                          sendLhdnClassification(
                            $event,
                            element,
                            tableColumn.typeName,
                            tableColumn.dataKey
                          )
                        "
                      ></app-custom-dropdown>
                    </ng-container>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'lhdnTaxCategory'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData2'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <ng-container *ngIf="taxTypesStrings.length > 0">
                      <app-custom-dropdown
                        [items]="taxTypesStrings"
                        [title]="'Tax Type'"
                        [placeholderLabel]="'Search Tax Type'"
                        [notFoundLabel]="'Tax Type Not Found'"
                        (dropdownEvent)="
                          sendLhdnTaxTypes(
                            $event,
                            element,
                            tableColumn.typeName,
                            tableColumn.dataKey
                          )
                        "
                      ></app-custom-dropdown>
                    </ng-container>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'uploadButton'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData2'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>{{ "UploadFile" | localize }}</mat-label>
                      <ngx-mat-file-input
                        [accept]="acceptPdfOnly"
                        (change)="
                          uploadFile(
                            element,
                            tableColumns.indexOf(tableColumn) + 1,
                            tableDataSource.filteredData.indexOf(element),
                            $event,
                            tableDataSource.data[
                              tableDataSource.filteredData.indexOf(element)
                            ]
                          )
                        "
                        [valuePlaceholder]="
                          tableDataSource.data[
                            tableDataSource.filteredData.indexOf(element)
                          ]['file' + (tableColumns.indexOf(tableColumn) + 1)]
                            ? tableDataSource.data[
                                tableDataSource.filteredData.indexOf(element)
                              ][
                                'file' + (tableColumns.indexOf(tableColumn) + 1)
                              ].name
                            : ''
                        "
                      >
                      </ngx-mat-file-input>
                      <mat-error>* {{ "Required" | localize }}</mat-error>
                      <mat-hint>* pdf only</mat-hint>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'inputNumber'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData2'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <div class="m-2">
                      <input
                        matInput
                        class="form-control newDataInput plain-input"
                        type="number"
                        style="height: 30px; resize: none"
                        [value]="
                          tableDataSource.data[
                            tableDataSource.filteredData.indexOf(element)
                          ]['col' + (tableColumns.indexOf(tableColumn) + 1)]
                            ? tableDataSource.data[
                                tableDataSource.filteredData.indexOf(element)
                              ]['col' + (tableColumns.indexOf(tableColumn) + 1)]
                            : ''
                        "
                        (change)="
                          onInputChange(
                            element,
                            tableColumns.indexOf(tableColumn) + 1,
                            tableDataSource.filteredData.indexOf(element),
                            $event.target.value,
                            $event
                          )
                        "
                      />
                    </div>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'status'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <span
                      class="badge w-100"
                      [ngClass]="{
                        'badge-warning':
                          element?.[tableColumn.dataKey] != 'rejected' &&
                          element?.[tableColumn.dataKey] != 'approved' &&
                          element?.[tableColumn.dataKey] != 'paid',
                        'badge-danger':
                          element?.[tableColumn.dataKey] == 'rejected',
                        'badge-success':
                          element?.[tableColumn.dataKey] == 'approved' ||
                          element?.[tableColumn.dataKey] == 'paid'
                      }"
                      >{{
                        element[tableColumn.dataKey] | uppercase | localize
                      }}</span
                    >
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'profile'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <h6 class="table-avatar">
                      <a class="avatar"
                        ><img alt="" src="{{ element.profileLink }}"
                      /></a>
                      <a>
                        {{ element.profileTitle | localize }}
                      </a>
                      <br />
                    </h6>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'checkbox'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true
                        ? 'rowTitle justify-content-center'
                        : 'newData2 justify-content-center'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <mat-checkbox
                      #checkbox
                      class="w-100"
                      [labelPosition]="'after'"
                      color="primary"
                      [(ngModel)]="element[tableColumn.dataKey]"
                      (change)="
                        checkOne(
                          $event,
                          element,
                          tableColumn.typeName,
                          tableColumn.dataKey
                        )
                      "
                      *ngIf="element?.[tableColumn.dataKey] != 'na'"
                    >
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container
                  *ngIf="tableColumn.type == 'undoButton' && isCashbookEditor"
                >
                  <div *ngIf="chechIfToday(element.object)">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-icon actionRow"
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          tableColumn.typeName,
                          tableColumn.dataKey
                        )
                      "
                    >
                      <mat-icon class="my-icon">undo</mat-icon>
                    </button>
                  </div>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'normalButton'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw'
                    }"
                  >
                    <button
                      mat-button
                      class="btn btn-primary py-0 px-2 m-0 w-100"
                      *ngIf="
                        element?.darkTitle == false ||
                        element?.darkTitle == null
                      "
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          tableColumn.typeName,
                          tableColumn.dataKey
                        )
                      "
                      style="white-space: normal"
                    >
                      <span class="text-white">
                        {{
                          element?.title
                            ? (element.title | localize)
                            : (element | localize)
                            ? (element[tableColumn.dataKey] | localize)
                            : ""
                        }}
                      </span>
                    </button>
                    <ng-container *ngIf="element?.darkTitle == true">
                      {{
                        element?.title
                          ? (element.title | localize)
                          : (element | localize)
                          ? (element[tableColumn.dataKey] | localize)
                          : ""
                      }}
                    </ng-container>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'approvalButton'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw'
                    }"
                  >
                    <div style="display: flex; justify-content: space-evenly">
                      <button
                        *ngIf="element.isAllowedApproval"
                        mat-button
                        class="btn btn-success py-0 px-0 m-0 w-20"
                        (click)="
                          sendMessage(
                            $event,
                            element,
                            'approve',
                            tableColumn.dataKey
                          )
                        "
                        style="white-space: normal"
                      >
                        <mat-icon class="text-white">done</mat-icon>
                      </button>
                      <button
                        *ngIf="element.isAllowedApproval"
                        mat-button
                        class="btn btn-danger py-0 px-0 m-0 w-20"
                        (click)="
                          sendMessage(
                            $event,
                            element,
                            'reject',
                            tableColumn.dataKey
                          )
                        "
                        style="white-space: normal"
                      >
                        <mat-icon class="text-white">close</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'resendInvoice'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <button
                      *ngIf="isInvoiceEditor"
                      mat-button
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          'resendInvoice',
                          tableColumn.dataKey
                        )
                      "
                    >
                      <u class="text-primary">
                        {{ "ResendInvoice" | localize }}
                      </u>
                    </button>
                    <!-- <button
                      *ngIf="isInvoiceEditor"
                      mat-button
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          'reminders',
                          tableColumn.dataKey
                        )
                      "
                    >
                      <u class="text-primary">
                        {{ "SendPaymentReminder" | localize }}
                      </u>
                    </button> -->
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'priceListAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <button
                      *ngIf="
                        element.object.priceList.length > 0 && isProductViewer
                      "
                      mat-button
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          'viewApproval',
                          tableColumn.dataKey
                        )
                      "
                    >
                      <u class="text-primary">
                        {{ "ViewApproval" | localize }}
                      </u>
                    </button>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'primaryButton'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <button
                      class="btn btn-primary m-0 text-light"
                      (click)="
                        sendMessage($event, element, tableColumn.typeName)
                      "
                    >
                      {{ element.title | localize }}
                    </button>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'dropdownButton'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <ng-container
                      *ngIf="element?.[tableColumn.dataKey] != 'na'"
                    >
                      <div class="row justify-content-center">
                        <button
                          [id]="
                            'focusAction' +
                            tableDataSource.filteredData.indexOf(element) +
                            idString
                          "
                          mat-icon-button
                          [matMenuTriggerFor]="actions"
                        >
                          <mat-icon class="material-icons-outlined green-icon"
                            >expand_circle_down</mat-icon
                          >
                        </button>
                      </div>
                      <mat-menu #actions>
                        <ng-container *ngFor="let item of dropdownButtonList">
                          <button
                            mat-menu-item
                            (click)="
                              sendMessage(
                                $event,
                                element,
                                item,
                                tableColumn.dataKey
                              )
                            "
                          >
                            {{ item | localize }}
                          </button>
                        </ng-container>
                      </mat-menu>
                    </ng-container>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'invoiceAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="isInvoiceViewer"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'viewbill')"
                      >
                        {{ "viewInvoice" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'createOrder')"
                      >
                        {{ "createDeliveryOrder" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'editbill')"
                        *ngIf="element.status == 'Pending' && isInvoiceEditor"
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'sendProforma')"
                        *ngIf="element.status == 'Pending' && isInvoiceEditor"
                      >
                        {{ "SendProFormaInvoice" | localize }}
                      </button>
                      <ng-container
                        *ngIf="
                          element.invoiceApproval == false ||
                          isInvoiceApprover == true
                        "
                      >
                        <button
                          mat-menu-item
                          (click)="sendMessage($event, element, 'sendbill')"
                          *ngIf="element.status == 'Pending'"
                        >
                          {{ "SendOfficialInvoice" | localize }}
                        </button>

                        <button
                          mat-menu-item
                          (click)="sendMessage($event, element, 'submitLhdn')"
                        >
                          Submit to LHDN
                        </button>
                        <button
                          mat-menu-item
                          (click)="sendMessage($event, element, 'lhdnInfo')"
                          *ngIf="element.object.billLhdn != null"
                        >
                          LHDN Info
                        </button>
                      </ng-container>

                      <ng-container
                        *ngIf="
                          element.invoiceApproval == true &&
                          isInvoiceApprover == false
                        "
                      >
                        <button
                          mat-menu-item
                          (click)="
                            sendMessage($event, element, 'requestApproval')
                          "
                          *ngIf="element.status == 'Pending' && isInvoiceEditor"
                        >
                          {{ "RequestApproval" | localize }}
                        </button>
                      </ng-container>

                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'deletebill')"
                        *ngIf="element.status == 'Pending' && isInvoiceEditor"
                      >
                        {{ "Delete" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'resendbill')"
                        *ngIf="
                          (element.status == 'Sent' ||
                            element.status == 'Overdue') &&
                          isInvoiceEditor
                        "
                      >
                        {{ "ResendInvoice" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'creditnote')"
                        *ngIf="
                          (element.status == 'Sent' ||
                            element.status == 'Overdue' ||
                            element.status == 'Paid') &&
                          isCreditNoteEditor
                        "
                      >
                        {{ "CreditNote" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        *ngIf="
                          (element.status == 'Sent' ||
                            element.status == 'Overdue') &&
                          isInvoiceEditor
                        "
                        (click)="sendMessage($event, element, 'paybill')"
                      >
                        {{ "ReceivePayment" | localize }}
                      </button>
                      <button
                        *ngIf="myCompany.scfSupplierId > 0"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'applyFinancing')"
                      >
                        {{ "ApplyFinancing" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'invoiceAction2'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="isInvoiceViewer"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'viewbill')"
                      >
                        {{ "viewInvoice" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'creditnote')"
                        *ngIf="
                          (element.status == 'Sent' ||
                            element.status == 'Overdue' ||
                            element.status == 'Paid') &&
                          isCreditNoteEditor
                        "
                      >
                        {{ "CreditNote" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'orderAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="element.object.billId == null && isOrderEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'editOrder')"
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        *ngIf="isOrderViewer"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'viewOrder')"
                      >
                        {{ "viewOrder" | localize }}
                      </button>
                      <button
                        *ngIf="element.object.billId == null && isOrderEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'sendQuotation')"
                      >
                        {{ "SendQuotation" | localize }}
                      </button>
                      <button
                        *ngIf="element.object.billId == null && isInvoiceEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'createInvoice')"
                      >
                        {{ "CreateInvoice" | localize }}
                      </button>
                      <button
                        *ngIf="element.object.billId != null && isInvoiceViewer"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'viewInvoice')"
                      >
                        {{ "viewInvoice" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.orderStatusId == 1 && isOrderEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'orderShipped')"
                      >
                        {{ "Order Shipped" }}
                      </button>
                      <button
                        *ngIf="
                          element.object.orderStatusId <= 1 && isOrderEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'parcelDelivery')"
                      >
                        {{ "Parcel Delivery" }}
                      </button>
                      <button
                        *ngIf="
                          (element.object.orderStatusId == 2 ||
                            element.object.orderStatusId == 3) &&
                          isOrderEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'deliveryOrder')"
                      >
                        {{ "SendDeliveryOrder" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.orderStatusId == 2 && isOrderEditor
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'confirmDelivery')
                        "
                      >
                        {{ "ConfirmDelivery" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'grnAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="element.object.status == 0 && isGrnEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'approve')"
                      >
                        {{ "Approve" | localize }}
                      </button>
                      <button
                        *ngIf="element.object.status == 0 && isGrnEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'reject')"
                      >
                        {{ "Reject" | localize }}
                      </button>
                      <button
                        *ngIf="element.object.status == 0 && isGrnEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'edit')"
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        *ngIf="isGrnViewer"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'viewGrn')"
                      >
                        {{ "ViewGrn" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.hasSupplementaryGrn == true &&
                          isGrnViewer
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'viewSupplementaryGrn')
                        "
                      >
                        {{ "ViewSgrn" | localize }}
                      </button>
                      <button
                        *ngIf="
                          (element.object.supplierDeliveryOrderUrl?.length <=
                            0 ||
                            (element.object.hasSupplementaryGrn == true &&
                              element.object.supplementaryGrnDoUrl?.length <=
                                0)) &&
                          isGrnEditor
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'requestDeliveryOrder')
                        "
                      >
                        {{ "RequestDeliveryOrder" | localize }}
                      </button>
                      <button
                        *ngIf="
                          (element.object.supplierDeliveryOrderUrl?.length <=
                            0 ||
                            (element.object.hasSupplementaryGrn == true &&
                              element.object.supplementaryGrnDoUrl?.length <=
                                0)) &&
                          isGrnEditor
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'attachDeliveryOrder')
                        "
                      >
                        {{ "AttachDeliveryOrder" | localize }}
                      </button>
                      <button
                        *ngIf="
                          (element.object.hasRejectedItem == true ||
                            element.object.hasShortItem == true) &&
                          element.object.hasSupplementaryGrn == false &&
                          isGrnEditor
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'addSupplementaryGrn')
                        "
                      >
                        {{ "AddSgrn" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.matchSupplementary == false &&
                          (element.object.creditNoteAttachmentUrl == null ||
                            element.object.creditNoteAttachmentUrl == '') &&
                          isGrnEditor
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'requestCreditNote')
                        "
                      >
                        {{ "RequestCreditNote" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.matchSupplementary == false &&
                          (element.object.creditNoteAttachmentUrl == null ||
                            element.object.creditNoteAttachmentUrl == '') &&
                          isGrnEditor
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'attachCreditNote')
                        "
                      >
                        {{ "AttachCreditNote" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.status == 2 &&
                          element.object.hasInventory == true &&
                          element.object.isUpdatedInventory == false &&
                          element.object?.vendorInvoice?.totalAmount ==
                            element.object.totalAmount +
                              element.object.supplementaryGrnTotalAmount +
                              element.object.creditNoteTotalAmount &&
                          isInventoryEditor
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'updateInventory')
                        "
                      >
                        {{ "UpdateInventory" | localize }}
                      </button>
                      <!-- <button
                        *ngIf="element.object.hasAsset == true && isAssetEditor"
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'updateAssetRegister')
                        "
                      >
                        {{ "UpdateAssetRegister" | localize }}
                      </button> -->
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'grnAction2'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="isGrnViewer"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'viewGrn')"
                      >
                        {{ "ViewGrn" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.hasSupplementaryGrn == true &&
                          isGrnViewer
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'viewSupplementaryGrn')
                        "
                      >
                        {{ "ViewSgrn" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'assetRegisterAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="isAssetEditor"
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'addEditAssetRegister')
                        "
                      >
                        {{ "UpdateAssetRegister" | localize }}
                      </button>
                      <!-- <button
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'viewAssetRegister')
                        "
                      >
                        View Asset Register
                      </button> -->
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container
                  *ngIf="tableColumn.type == 'assetRegisterAction2'"
                >
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="
                          isAssetEditor &&
                          element.object.nonCurrentAssetId?.length <= 0
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'RegisterThisAsset')
                        "
                      >
                        {{ "Register This Asset" }}
                      </button>
                      <button
                        *ngIf="isAssetEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'edit')"
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        *ngIf="isAssetViewer"
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'viewMaintenance')
                        "
                      >
                        View Maintenance
                      </button>
                      <button
                        *ngIf="isAssetEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'impairment')"
                      >
                        Impairment
                      </button>
                      <button
                        *ngIf="isAssetEditor"
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'writeOffAndDisposal')
                        "
                      >
                        Write-Off/Disposal
                      </button>
                      <button
                        *ngIf="
                          element.object.installationCost <= 0 &&
                          element.object.vendorInvoiceId != null &&
                          isAssetEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, '3rdPartyCost')"
                      >
                        Add 3rd Party Cost
                      </button>
                      <button
                        *ngIf="
                          element.object.vendorInvoiceId <= 0 && isAssetEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'delete')"
                      >
                        {{ "Delete" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'scfAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'viewFinancingTerm')
                        "
                      >
                        View Financing Term
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'viewCfs')"
                      >
                        View Client Fact Sheet
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'vendorInvoiceAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="
                          element.object.status == 0 &&
                          element.object.isPurchaseWithoutPo &&
                          isRequisitionEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'edit')"
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.goodsReceivedNotes == null &&
                          isGrnEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'createGrn')"
                      >
                        {{ "CreateGoodsReceivedNote" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.status == 0 &&
                          element.object.referenceNumbber == null &&
                          isRequisitionEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'changeReminder')"
                      >
                        {{ "ChangeDeliveryReminder" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.status == 0 &&
                          element.object.referenceNumbber == null &&
                          isRequisitionEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'sendReminder')"
                      >
                        {{ "SendEmailReminder" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.status == 0 &&
                          element.object.referenceNumbber == null &&
                          isRequisitionEditor
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'changeDeliveryDate')
                        "
                      >
                        {{ "ChangeDeliveryDate" | localize }}
                      </button>
                      <button
                        *ngIf="element.object.status != 2 && isCashbookEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'makePayment')"
                      >
                        {{ "MakePayment" | localize }}
                      </button>
                      <button
                        *ngIf="isAccountingEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'chargeAccount')"
                      >
                        {{ "ChargeAccount" | localize }}
                      </button>

                      <button
                        *ngIf="myCompany.scfSupplierId > 0"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'applyFinancing')"
                      >
                        {{ "ApplyFinancing" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'purchaseOrderAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="isRequisitionViewer"
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'viewPurchaseOrder')
                        "
                      >
                        {{ "ViewPurchaseOrder" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.customerObject.status == 'Approval' &&
                          isRequisitionEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'edit')"
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.customerObject.status != 'Approval' &&
                          isRequisitionEditor
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'sendEmailtoVendor')
                        "
                      >
                        {{ "EmailPurchaseOrderToVendor" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.customerObject.status != 'Approval' &&
                          isRequisitionEditor
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'attachVendorInvoice')
                        "
                      >
                        {{ "AttachVendorsInvoice" | localize }}
                      </button>
                      <!-- <button
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'chargeAccount')
                        "
                      >
                        {{ "ChargeAccount" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'makePayment')
                        "
                      >
                        {{ "MakePayment" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'createGrn')
                        "
                      >
                        {{ "CreateGoodsReceivedNote" | localize }}
                      </button> -->
                      <button
                        *ngIf="
                          element.customerObject.status == 'Approval' &&
                          isRequisitionEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'delete')"
                      >
                        {{ "Delete" | localize }}
                      </button>

                      <button
                        *ngIf="myCompany.scfSupplierId > 0"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'applyFinancing')"
                      >
                        {{ "ApplyFinancing" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container
                  *ngIf="
                    tableColumn.type == 'purchaseOrderReference' &&
                    isRequisitionViewer
                  "
                >
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <button
                      style="font-size: 12px"
                      mat-button
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          'purchaseOrderRef',
                          tableColumn.dataKey
                        )
                      "
                    >
                      <u class="text-primary">
                        {{ element.customerObject.poReferenceNumber }}
                      </u>
                    </button>
                    <button
                      style="font-size: 12px"
                      mat-button
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          'purchaseRequisitionRef',
                          tableColumn.dataKey
                        )
                      "
                    >
                      <u class="text-primary">
                        {{ element.customerObject.prReferenceNumber }}
                      </u>
                    </button>
                  </td>
                </ng-container>

                <ng-container
                  *ngIf="
                    tableColumn.type == 'purchaseOrderReference2' &&
                    isRequisitionViewer
                  "
                >
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <button
                      style="font-size: 12px"
                      mat-button
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          'voItemRef',
                          tableColumn.dataKey
                        )
                      "
                    >
                      <u class="text-primary">{{
                        "VendorInvoice" | localize
                      }}</u>
                    </button>
                    <br />
                    <button
                      style="font-size: 12px"
                      mat-button
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          'purchaseOrderRef',
                          tableColumn.dataKey
                        )
                      "
                    >
                      <u class="text-primary">{{
                        "PurchaseOrder" | localize
                      }}</u>
                    </button>
                    <br />
                    <button
                      style="font-size: 12px"
                      mat-button
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          'purchaseRequisitionRef',
                          tableColumn.dataKey
                        )
                      "
                    >
                      <u class="text-primary">{{
                        "PurchaseRequisition" | localize
                      }}</u>
                    </button>
                  </td>
                </ng-container>

                <ng-container
                  *ngIf="tableColumn.type == 'purchaseOrderReference3'"
                >
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="isRequisitionViewer"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'voItemRef')"
                      >
                        {{ "VendorInvoice" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.isPurchaseWithoutPo == false &&
                          isRequisitionViewer
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'purchaseOrderRef')
                        "
                      >
                        {{ "PurchaseOrder" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.isPurchaseWithoutPo == false &&
                          isRequisitionViewer
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'purchaseRequisitionRef')
                        "
                      >
                        {{ "PurchaseRequisition" | localize }}
                      </button>
                      <button
                        *ngIf="element.status == 'Pending GRN' && isGrnEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'createGrn')"
                      >
                        {{ "CreateGoodsReceivedNote" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.status == 'Pending Payment' &&
                          isCashbookEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'makePayment')"
                      >
                        {{ "MakePayment" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container
                  *ngIf="tableColumn.type == 'purchaseRequisitionAction'"
                >
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="isRequisitionViewer"
                        mat-menu-item
                        (click)="
                          sendMessage(
                            $event,
                            element,
                            'viewPurchaseRequisition'
                          )
                        "
                      >
                        {{ "View" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.status == 0 && isRequisitionEditor
                        "
                        mat-menu-item
                        (click)="
                          sendMessage(
                            $event,
                            element,
                            'editPurchaseRequisition'
                          )
                        "
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.status == 0 && isRequisitionEditor
                        "
                        mat-menu-item
                        (click)="
                          sendMessage(
                            $event,
                            element,
                            'deletePurchaseRequisition'
                          )
                        "
                      >
                        {{ "Delete" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.status == 3 &&
                          element.object.vendorId == null &&
                          element.object.isDraftApproved == true &&
                          element.object.isCalledForQuotation == false &&
                          isRequisitionEditor
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'callForQuotation')
                        "
                      >
                        {{ "CallForQuotation" | localize }}
                      </button>
                      <button
                        *ngIf="myCompany.scfSupplierId > 0"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'applyFinancing')"
                      >
                        {{ "ApplyFinancing" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container
                  *ngIf="tableColumn.type == 'requisitionCategoryAction'"
                >
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'selectCategory')"
                      >
                        Select Account Chargeable
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'quotationAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="isRequisitionViewer"
                        mat-menu-item
                        (click)="
                          sendMessage(
                            $event,
                            element,
                            'viewQuotationFromVendors'
                          )
                        "
                      >
                        {{ "ViewQuotationOrTenderBox" | localize }}
                      </button>
                      <button
                        *ngIf="isRequisitionViewer"
                        mat-menu-item
                        (click)="
                          sendMessage(
                            $event,
                            element,
                            'viewPurchaseRequisition'
                          )
                        "
                      >
                        {{ "ViewPurchaseRequisition" | localize }}
                      </button>
                      <!-- <button
                        *ngIf="
                          element.object.vendorId == null &&
                          element.status == 'Pending'
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'sendInvitation')"
                      >
                        Send Invitation to Vendors
                      </button> -->
                      <!-- <button
                        *ngIf="element.object.vendorId != null"
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'generatePurchaseOrder')
                        "
                      >
                        Generate Purchase Order
                      </button> -->
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container
                  *ngIf="tableColumn.type == 'recommendedVendorAction'"
                >
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div
                      *ngIf="element.showButton == true"
                      class="row justify-content-center"
                    >
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="
                          element.object.offeredPrice == 0 &&
                          element.status == 'Sent' &&
                          isRequisitionEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'sendEmail')"
                      >
                        Send Invitation To Email & SMS
                      </button>
                      <button
                        *ngIf="
                          element.object.quotationAttachment != null &&
                          element.difference > 0 &&
                          isRequisitionViewer
                        "
                        mat-menu-item
                        (click)="
                          sendMessage(
                            $event,
                            element,
                            'viewQuotationFromVendor'
                          )
                        "
                      >
                        {{ "ViewQuotationFromVendor" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.offeredPrice != 0 &&
                          element.difference > 0 &&
                          isRequisitionEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'selectVendor')"
                      >
                        {{ "SelectThisVendor" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.quotationStatus == 0 ||
                          (element.status == 'Pending' && isRequisitionEditor)
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'deleteVendor')"
                      >
                        {{ "Delete" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'inventoryItemAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="isInventoryEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'editItem')"
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        *ngIf="isInventoryEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'adjustItem')"
                      >
                        Adjustments
                      </button>
                      <button
                        *ngIf="isInventoryEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'delete')"
                      >
                        {{ "Delete" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container
                  *ngIf="tableColumn.type == 'inventoryItemAction2'"
                >
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="isInventoryEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'editItem')"
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        *ngIf="isInventoryEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'adjustItem')"
                      >
                        Adjustments
                      </button>
                      <button
                        *ngIf="isInventoryEditor"
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'issueToProduction')
                        "
                      >
                        Issue to Production
                      </button>
                      <button
                        *ngIf="isInventoryEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'issueToProject')"
                      >
                        Issue to Project
                      </button>
                      <button
                        *ngIf="isInventoryEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'delete')"
                      >
                        {{ "Delete" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'adjustmentAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="element.object.status == 0 && isInventoryEditor"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'edit')"
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        *ngIf="isInventoryViewer"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'viewAdjustment')"
                      >
                        {{ "View" | localize }}
                      </button>
                      <button
                        *ngIf="
                          (element.object.type == 5 ||
                            element.object.type == 6) &&
                          element.object.factoryStatus == 0 &&
                          element.object.status == 2 &&
                          isInventoryEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'sendGrnLink')"
                      >
                        Send GRN Link
                      </button>
                      <button
                        *ngIf="
                          (element.object.type == 5 ||
                            element.object.type == 6) &&
                          element.object.factoryStatus == 0 &&
                          element.object.status == 2 &&
                          (isInventoryEditor || isGrnEditor)
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'addGrn')"
                      >
                        Add GRN Section
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'productionAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="
                          element.object.isTransferredFromFactory == false &&
                          element.object.status == 0 &&
                          isInventoryEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'editLabor')"
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        *ngIf="isInventoryViewer"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'viewProduction')"
                      >
                        {{ "View" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.isTransferredFromFactory == false &&
                          isInventoryEditor
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'transferFromFactory')
                        "
                      >
                        {{ "TransferToProductInventory" | localize }}
                      </button>
                      <!-- <button
                        *ngIf="
                          element.object.produceQuantity > 0 &&
                          (isInventoryEditor || isProductEditor)
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'updateUnitCost')"
                      >
                        {{ "UpdateProductUnitCost" | localize }}
                      </button> -->
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'productionAction2'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="isInventoryViewer"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'viewProduction')"
                      >
                        View Production Report
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'projectAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="
                          element.object.status == 0 &&
                          (isProjectEditor || isInventoryEditor)
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'edit')"
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        *ngIf="isProjectViewer || isInventoryViewer"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'viewProject')"
                      >
                        {{ "View" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'debitNoteAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="isDebitNoteViewer"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'viewDebitNote')"
                      >
                        {{ "View" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.approvalStatusInt == 0 &&
                          isDebitNoteEditor
                        "
                        mat-menu-item
                        (click)="sendMessage($event, element, 'editDebitNote')"
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        *ngIf="
                          element.object.approvalStatusInt == 0 &&
                          isDebitNoteEditor
                        "
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'deleteDebitNote')
                        "
                      >
                        {{ "Delete" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container
                  *ngIf="tableColumn.type == 'generalLedgerDataAction'"
                >
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <button
                      *ngIf="
                        isAccountingEditor && element.object.isAudited == false
                      "
                      mat-button
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          'confirmAudit',
                          tableColumn.dataKey
                        )
                      "
                    >
                      <u class="text-primary"> Confirm Audit </u>
                    </button>
                    <button
                      *ngIf="
                        isAccountingEditor && element.object.isAudited == true
                      "
                      mat-button
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          'reverseAudit',
                          tableColumn.dataKey
                        )
                      "
                    >
                      <u class="text-primary"> Reverse Audit </u>
                    </button>
                  </td>
                </ng-container>

                <ng-container
                  *ngIf="tableColumn.type == 'transactionDetailAction'"
                >
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'view')"
                      >
                        {{ "View" | localize }}
                      </button>
                      <button
                        *ngIf="element.object.type == 'out'"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'chargeAccount')"
                      >
                        {{ "ChargeAccount" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container
                  *ngIf="tableColumn.type == 'leaveApplicationAction'"
                >
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        *ngIf="element.object.status == 'Pending'"
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'edit')"
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'delete')"
                      >
                        {{ "Delete" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'approve')"
                      >
                        {{ "Approve" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'Reject')"
                      >
                        {{ "Reject" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'payrollReportAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'view')"
                      >
                        {{ "View" | localize }}
                      </button>
                      <button
                        *ngIf="element.object.status == 'Pending'"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'confirm')"
                      >
                        {{ "Confirm" | localize }}
                      </button>
                      <button
                        *ngIf="element.object.status == 'Confirmed'"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'unconfirm')"
                      >
                        {{ "Unconfirm" | localize }}
                      </button>
                      <button
                        *ngIf="element.object.status == 'Confirmed'"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'paid')"
                      >
                        {{ "Paid" | localize }}
                      </button>
                      <button
                        *ngIf="element.object.status == 'Paid'"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'unpaid')"
                      >
                        {{ "Unpaid" | localize }}
                      </button>
                      <button
                        *ngIf="element.object.status == 'Paid'"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'email')"
                      >
                        {{ "EmailPayslipToEmployee" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'employeeAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'edit')"
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'resetPassword')"
                      >
                        {{ "ResetPassword" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'setRole')"
                      >
                        {{ "SetRole" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'currentAccount')"
                      >
                        {{ "Current Account" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'assetDisposalAction'">
                  <td
                    [ngClass]="
                      element.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        *ngIf="element.object.cashbookId <= 0"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'receivePayment')"
                      >
                        {{ "ReceivePayment" | localize }}
                      </button>
                      <!-- <button
                        *ngIf="element.object.cashbookId > 0"
                        mat-menu-item
                        (click)="sendMessage($event, element, 'viewCashbook')"
                      >
                        {{ "ViewCashbook" | localize }}
                      </button> -->
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'investmentAction'">
                  <td
                    [ngClass]="
                      element.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'edit')"
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'impairment')"
                      >
                        {{ "Impairment" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'redeem')"
                      >
                        {{ "Redeem" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'companyBankAction'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                  >
                    <div class="row justify-content-center">
                      <button
                        [id]="
                          'focusAction' +
                          tableDataSource.filteredData.indexOf(element) +
                          idString
                        "
                        mat-icon-button
                        [matMenuTriggerFor]="actions"
                      >
                        <mat-icon class="material-icons-outlined green-icon"
                          >expand_circle_down</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu #actions>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'makePrimary')"
                      >
                        {{ "Make Primary" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="sendMessage($event, element, 'makeSecondary')"
                      >
                        {{ "Make Secondary" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'editBankAccount')
                        "
                      >
                        {{ "Edit" | localize }}
                      </button>
                      <button
                        mat-menu-item
                        (click)="
                          sendMessage($event, element, 'deleteBankAccount')
                        "
                      >
                        {{ "Delete" | localize }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>
              </ng-container>

              <ng-container
                *ngIf="isInputTable == false && tableColumn.type == null"
              >
                <td
                  [ngClass]="
                    element?.darkTitle == true ? 'rowTitle' : 'newData'
                  "
                  [ngStyle]="{
                    width: tableColumn.width ? tableColumn.width : '100vw',
                    border: showBorder == true ? '1px solid #c8c8c8' : ''
                  }"
                >
                  {{ element ? (element[tableColumn.dataKey] | localize) : "" }}
                </td>
              </ng-container>
              <ng-container
                *ngIf="isInputTable == true && tableColumn.type == null"
              >
                <td
                  class="newData m-0 p-0"
                  [ngStyle]="{
                    border: showBorder == true ? '1px solid #c8c8c8' : ''
                  }"
                >
                  <div class="m-2">
                    <input
                      matInput
                      class="form-control newDataInput plain-input"
                      type="text"
                      style="height: 30px; resize: none"
                      [value]="
                        tableDataSource.data[
                          tableDataSource.filteredData.indexOf(element)
                        ]['col' + (tableColumns.indexOf(tableColumn) + 1)]
                          ? tableDataSource.data[
                              tableDataSource.filteredData.indexOf(element)
                            ]['col' + (tableColumns.indexOf(tableColumn) + 1)]
                          : ''
                      "
                      (change)="
                        onInputChange(
                          element,
                          tableColumns.indexOf(tableColumn) + 1,
                          tableDataSource.filteredData.indexOf(element),
                          $event.target.value,
                          $event
                        )
                      "
                    />
                  </div>
                </td>
              </ng-container>
              <ng-container
                *ngIf="isInputTable == true && tableColumn.type != null"
              >
                <ng-container *ngIf="tableColumn.type == 'text'">
                  <td
                    class="newData m-0 p-0 text-center"
                    [ngStyle]="{
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    {{
                      tableDataSource.data[
                        tableDataSource.filteredData.indexOf(element)
                      ]["col" + (tableColumns.indexOf(tableColumn) + 1)]
                        ? (tableDataSource.data[
                            tableDataSource.filteredData.indexOf(element)
                          ]["col" + (tableColumns.indexOf(tableColumn) + 1)]
                          | localize)
                        : ""
                    }}
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'underlineButton'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <button
                      mat-button
                      (click)="
                        sendMessage(
                          $event,
                          element,
                          tableColumn.typeName,
                          tableColumn.dataKey
                        )
                      "
                    >
                      <u class="text-primary pre-line">
                        {{
                          element.title
                            ? (element.title | localize)
                            : (element[tableColumn.dataKey] | localize)
                        }}
                      </u>
                    </button>
                  </td>
                </ng-container>

                <ng-container *ngIf="tableColumn.type == 'account'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData2'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <app-account-charge
                      [initialCode]="element[tableColumn.dataKey]"
                      [isSingle]="true"
                      (messageEvent)="
                        sendMessage(
                          $event,
                          element,
                          tableColumn.typeName,
                          tableColumn.dataKey
                        )
                      "
                    ></app-account-charge>
                  </td>
                </ng-container>
                <ng-container *ngIf="tableColumn.type == 'dropdown'">
                  <td
                    [ngClass]="
                      element?.darkTitle == true ? 'rowTitle' : 'newData2'
                    "
                    [ngStyle]="{
                      width: tableColumn.width ? tableColumn.width : '100vw',
                      border: showBorder == true ? '1px solid #c8c8c8' : ''
                    }"
                  >
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>{{ dropdownTitle | localize }}</mat-label>
                      <mat-select
                        [(ngModel)]="element[tableColumn.dataKey]"
                        (selectionChange)="
                          onInputChange(
                            element,
                            tableColumns.indexOf(tableColumn) + 1,
                            tableDataSource.filteredData.indexOf(element),
                            $event.value,
                            $event
                          )
                        "
                      >
                        <mat-option
                          *ngFor="let data of dropdownList"
                          [value]="data"
                        >
                          {{ data | localize }}
                        </mat-option>
                      </mat-select>
                      <mat-error>* {{ "Required" | localize }}</mat-error>
                    </mat-form-field>
                  </td>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-template>

          <ng-template #darkTitle>
            <ng-container
              *ngIf="
                mergeRowArray.includes(
                  tableDataSource.filteredData.indexOf(element)
                ) == true;
                else noMerge
              "
            >
              <ng-container *ngIf="!(mergeColumnArray.length > 1)">
                <td
                  [attr.colspan]="tableColumns.length"
                  [ngClass]="
                    isStickyTitle == true
                      ? 'rowTitle sticky'
                      : isLeftTitle == false
                      ? 'rowTitle'
                      : element?.darkTitle
                      ? 'rowTitle'
                      : tableColumns.indexOf(tableColumn) == 0
                      ? 'rowTitle'
                      : 'newData'
                  "
                  [ngStyle]="{
                    width: tableColumn.width ? tableColumn.width : '100vw',
                    border: showBorder == true ? '1px solid #c8c8c8' : '',
                    top: element.top ? element.top : '0',
                    bottom: '0'
                  }"
                  *ngIf="tableColumns.indexOf(tableColumn) == 0"
                >
                  {{ element[tableColumn.dataKey] | localize }}
                </td>
              </ng-container>

              <ng-container *ngIf="mergeColumnArray.length > 1">
                <ng-container
                  *ngIf="
                    mergeColumnArray.includes(tableColumns.indexOf(tableColumn))
                  "
                >
                  <ng-container
                    *ngIf="
                      mergeColumnArray.includes(
                        tableColumns.indexOf(tableColumn) + 1
                      ) == false;
                      else noMerge
                    "
                  >
                    <td
                      [attr.colspan]="
                        mergeColumnArray[
                          mergeColumnArray.indexOf(
                            tableColumns.indexOf(tableColumn)
                          ) + 1
                        ] -
                        mergeColumnArray[
                          mergeColumnArray.indexOf(
                            tableColumns.indexOf(tableColumn)
                          )
                        ]
                          ? mergeColumnArray[
                              mergeColumnArray.indexOf(
                                tableColumns.indexOf(tableColumn)
                              ) + 1
                            ] -
                            mergeColumnArray[
                              mergeColumnArray.indexOf(
                                tableColumns.indexOf(tableColumn)
                              )
                            ]
                          : tableColumns.length -
                            mergeColumnArray[
                              mergeColumnArray.indexOf(
                                tableColumns.indexOf(tableColumn)
                              )
                            ]
                      "
                      [ngClass]="
                        isStickyTitle == true
                          ? 'rowTitle sticky'
                          : isLeftTitle == false
                          ? 'rowTitle'
                          : element?.darkTitle
                          ? 'rowTitle'
                          : tableColumns.indexOf(tableColumn) == 0
                          ? 'rowTitle'
                          : 'newData'
                      "
                      [ngStyle]="{
                        width: tableColumn.width ? tableColumn.width : '100vw',
                        border: showBorder == true ? '1px solid #c8c8c8' : '',
                        top: element.top ? element.top : '0',
                        bottom: isBottomSticky == true ? '0' : ''
                      }"
                    >
                      {{ element[tableColumn.dataKey] | localize }}
                    </td>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-template>

          <ng-template #noMerge>
            <td
              [ngClass]="
                isStickyTitle == true
                  ? 'rowTitle sticky'
                  : isLeftTitle == false
                  ? 'rowTitle'
                  : tableColumns.indexOf(tableColumn) == 0
                  ? 'rowTitle'
                  : titleRowArray.includes(
                      tableDataSource.filteredData.indexOf(element)
                    ) == true
                  ? 'rowTitle'
                  : element?.darkTitle
                  ? 'rowTitle'
                  : 'newData'
              "
              [ngStyle]="{
                width: tableColumn.width ? tableColumn.width : '100vw',
                border: showBorder == true ? '1px solid #c8c8c8' : '',
                top: element.top ? element.top : '0',
                bottom: '0'
              }"
            >
              {{ element[tableColumn.dataKey] | localize }}
            </td>
          </ng-template>
        </ng-container>
      </ng-container>
      <tbody>
        <ng-container *ngIf="showTitle">
          <ng-container *ngIf="isStickyTitle == false">
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          </ng-container>
          <ng-container *ngIf="isStickyTitle == true">
            <tr
              class="sticky"
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
          </ng-container>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </tbody>
    </table>
  </div>

  <!-- Pagination -->
  <mat-paginator
    *ngIf="isPageable == true"
    [length]="totalItem"
    [pageSizeOptions]="paginationSizes"
    [pageSize]="defaultPageSize"
    showFirstLastButtons
    (page)="getNext($event)"
    style="height: 20px !important"
  >
  </mat-paginator>
</ng-container>
