import { CustomCurrencyPipe } from "./pipes/currency.pipe";
import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { RouterModule } from "@angular/router";
import {
  MAT_DIALOG_DATA,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";

import { NgxPaginationModule } from "ngx-pagination";

import { AppSessionService } from "./session/app-session.service";

import { AppUrlService } from "./nav/app-url.service";

import { AppAuthService } from "./auth/app-auth.service";
import { AppRouteGuard } from "./auth/auth-route-guard";

import { LocalizePipe } from "./pipes/localize.pipe";

import { LayoutStoreService } from "./layout/layout-store.service";

import { BusyDirective } from "./directives/busy.directive";
import { EqualValidator } from "./directives/equal-validator.directive";
import {
  MAT_COLOR_FORMATS,
  NGX_MAT_COLOR_FORMATS,
  NgxMatColorPickerModule,
} from "@shared/components/custom-color-picker";
import { LayoutModule } from "@angular/cdk/layout";
import { CdkTableModule } from "@angular/cdk/table";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import {
  MatRippleModule,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { GoogleChartsModule } from "angular-google-charts";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { NgxDropzoneModule } from "ngx-dropzone-v14";
// import { InputFileConfig, InputFileModule } from "ngx-input-file";
import {
  InputFileConfig,
  InputFileModule,
} from "@shared/components/custom-input-file";
import { LightboxModule } from "@shared/components/custom-lightbox";
// import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxMatSelectSearchModule } from "@shared/components/custom-mat-select-search";
// import { MaterialFileInputModule } from "ngx-material-file-input";
import { MaterialFileInputModule } from "@shared/components/custom-material-file-input";
import { NgxQrcodeStylingModule } from "ngx-qrcode-styling";
import {
  SWIPER_CONFIG,
  SwiperConfigInterface,
  SwiperModule,
} from "ngx-swiper-wrapper-v-13";
import { ServiceProxyModule } from "./service-proxies/service-proxy.module";
import { ServiceProxyModule2 } from "./service-proxies2/service-proxy2.module";
import { NgxImageCompressService } from "ngx-image-compress";
import { UploadService } from "./upload-service/upload.service";
import { NgxYoutubePlayerModule } from "@hercilio/ngx-youtube-player";
import { FullCalendarModule } from "@fullcalendar/angular";
import { QuillModule } from "ngx-quill";

import { AbpPaginationControlsComponent } from "./components/pagination/abp-pagination-controls.component";
import { AbpValidationSummaryComponent } from "./components/validation/abp-validation.summary.component";
import { MaterialValidationSummaryComponent } from "./components/validation/material-validation.summary.component";
import { AbpModalHeaderComponent } from "./components/modal/abp-modal-header.component";
import { AbpModalFooterComponent } from "./components/modal/abp-modal-footer.component";
import { CustomTableComponent } from "./components/custom-table/custom-table.component";
import { CustomDropdownComponent } from "./components/custom-dropdown/custom-dropdown.component";
import { AccountChargeComponent } from "./components/accounting-chart/account-charge/account-charge.component";
import { InventoryAssetsLocationDropdownComponent } from "./components/inventory-assets-location-dropdown/inventory-assets-location-dropdown.component";
import { InventoryItemsDropdownComponent } from "./components/inventory-items-dropdown/inventory-items-dropdown.component";
import { AddJournalPopupComponent } from "./components/add-journal-popup/add-journal-popup.component";
import { CustomVideoPlayerComponent } from "./components/custom-video-player/custom-video-player.component";
import { TfxInvoiceComponent } from "./components/tfx-invoice/tfx-invoice.component";
import { PdfPopupComponent } from "./pdf-popup/pdf-popup.component";
import { LocationSelectorComponent } from "./components/location-selector/location-selector.component";
import { CheckAccessComponent } from "./check-access/check-access.component";

 const config: InputFileConfig = {
   fileAccept: "*",
 };

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto",
};

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ModalModule.forChild(),
        BsDatepickerModule.forRoot(),
        InputFileModule.forRoot(config),
        NgxYoutubePlayerModule.forRoot(),
        QuillModule.forRoot(),
        FullCalendarModule,
        NgxDocViewerModule,
        NgxQrcodeStylingModule,
        SwiperModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        BsDropdownModule,
        CollapseModule,
        TabsModule,
        ServiceProxyModule,
        ServiceProxyModule2,
        NgxPaginationModule,
        MatTreeModule,
        MatAutocompleteModule,
        MatSidenavModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTooltipModule,
        MatToolbarModule,
        MatDialogModule,
        MatTabsModule,
        MatRippleModule,
        MatButtonModule,
        MatListModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatCardModule,
        MatMenuModule,
        MatIconModule,
        MatBadgeModule,
        MatButtonToggleModule,
        LayoutModule,
        CdkTableModule,
        NgxChartsModule,
        NgxDropzoneModule,
        NgxMatSelectSearchModule,
        NgxMatColorPickerModule,
        MatChipsModule,
        LightboxModule,
        GoogleChartsModule,
        MaterialFileInputModule,
        PdfViewerModule,
        FlexLayoutModule,
    ],
    declarations: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        CustomCurrencyPipe,
        BusyDirective,
        EqualValidator,
        MaterialValidationSummaryComponent,
        CustomTableComponent,
        CustomDropdownComponent,
        AccountChargeComponent,
        InventoryAssetsLocationDropdownComponent,
        InventoryItemsDropdownComponent,
        AddJournalPopupComponent,
        CustomVideoPlayerComponent,
        TfxInvoiceComponent,
        PdfPopupComponent,
        LocationSelectorComponent,
        CheckAccessComponent,
    ],
    exports: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        CustomCurrencyPipe,
        BusyDirective,
        EqualValidator,
        MaterialValidationSummaryComponent,
        CustomTableComponent,
        CustomDropdownComponent,
        AccountChargeComponent,
        InventoryAssetsLocationDropdownComponent,
        InventoryItemsDropdownComponent,
        AddJournalPopupComponent,
        CustomVideoPlayerComponent,
        NgxDocViewerModule,
        NgxQrcodeStylingModule,
        SwiperModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        BsDropdownModule,
        CollapseModule,
        TabsModule,
        ServiceProxyModule,
        ServiceProxyModule2,
        NgxPaginationModule,
        MatTreeModule,
        MatAutocompleteModule,
        MatSidenavModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTooltipModule,
        MatToolbarModule,
        MatDialogModule,
        MatTabsModule,
        MatRippleModule,
        MatButtonModule,
        MatListModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatCardModule,
        MatMenuModule,
        MatIconModule,
        MatBadgeModule,
        MatButtonToggleModule,
        LayoutModule,
        CdkTableModule,
        NgxChartsModule,
        NgxDropzoneModule,
        NgxMatSelectSearchModule,
        NgxMatColorPickerModule,
        MatChipsModule,
        LightboxModule,
        GoogleChartsModule,
        MaterialFileInputModule,
        PdfViewerModule,
        FlexLayoutModule,
        FullCalendarModule,
        QuillModule,
        LocationSelectorComponent,
        CheckAccessComponent,
    ],
    providers: [
        MatDatepickerModule,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { hasBackdrop: true, width: "800px" },
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: "outline" },
        },
        { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
        UploadService,
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG,
        },
        DatePipe,
        CurrencyPipe,
        {
            provide: MatDialogRef,
            useValue: {},
        },
        {
            provide: MAT_DIALOG_DATA,
            useValue: {},
        },
        { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
        NgxImageCompressService,
        CustomCurrencyPipe,
    ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        AppSessionService,
        AppUrlService,
        AppAuthService,
        AppRouteGuard,
        LayoutStoreService,
        CurrencyPipe,

        MatDatepickerModule,
        {
          provide: MAT_DIALOG_DEFAULT_OPTIONS,
          useValue: { hasBackdrop: true, width: "800px" },
        },
        {
          provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
          useValue: { appearance: "outline" },
        },
        { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
        UploadService,
        {
          provide: SWIPER_CONFIG,
          useValue: DEFAULT_SWIPER_CONFIG,
        },
        DatePipe,
        {
          provide: MatDialogRef,
          useValue: {},
        },
        {
          provide: MAT_DIALOG_DATA,
          useValue: {},
        },
        { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
        NgxImageCompressService,
      ],
    };
  }
}
