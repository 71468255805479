import { Injectable } from '@angular/core';
import { Observable, Observer, from } from 'rxjs';
import axios from 'axios';
import { S3Client, PutObjectCommand, PutObjectAclCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { MD5, HmacSHA1, enc } from 'crypto-js';
import AWS, { S3 } from 'aws-sdk';
import filter from 'lodash-es/filter';
import camelCase from 'lodash-es/camelCase';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private s3Client: S3Client;
  private fileCache = new Map<string, { data: Promise<string>; expiration: number }>();

  accessKeyId = '3GC73C8BJ8Z2I3U2UOEC';
  secretAccessKey = 'GdhN0KDM03O0gLVSIZHA1cU9UHiQX1E2NLdLDRde';
  private s3: S3;

  constructor() {
    this.s3Client = new S3Client({
      region: 'ap-south-1',
      endpoint: 'https://ap-south-1.linodeobjects.com',
      credentials: {
        accessKeyId: this.accessKeyId,
        secretAccessKey: this.secretAccessKey,
      },
    });

    this.s3 = new AWS.S3({
      accessKeyId: this.accessKeyId,
      secretAccessKey: this.secretAccessKey,
      endpoint: 'https://ap-south-1.linodeobjects.com',
      region: 'ap-south-1'
    });
  }

  public uploadFileWithName(file: File, filename: string): Observable<any> {
    const bucket = 'tfxibucket';
    const key = filename;

    const putObjectCommand = new PutObjectCommand({
      Bucket: bucket,
      Key: key,
      ContentType: file.type,
    });

    return from(
      (async () => {
        const presignedUrl = await getSignedUrl(this.s3Client, putObjectCommand, {
          expiresIn: 3600,
        });

        await axios.put(presignedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        });

        return {
          result: {
            name: filename,
            storageUrl: `https://tfxibucket.ap-south-1.linodeobjects.com/`,
          }
        };
      })(),
    );
  }

  uploadFileNoUniqueName(file: File): Observable<any> {
    const oldname = file.name.replace(/\s+/g, '_');
    const bucket = 'tfxibucket';
    const key = oldname;

    return new Observable<any>(observer => {
      const params: any = {
        Bucket: bucket,
        Key: key,
        Body: file,
        ContentType: file.type,
        Metadata: {
          'x-amz-meta-md5-hash': MD5(file).toString()
        },
        ACL: 'public-read'
      };

      this.s3.putObject(params, (err: any, data: any) => {
        if (err) {
          console.error('Error uploading file:', err);
          observer.error(err);
        } else {
          observer.next({
            result: {
              name: oldname,
              storageUrl: `https://${bucket}.ap-south-1.linodeobjects.com/`,
              stype: "s3"
            },
          });
        }
        observer.complete();
      });
    });
  }

  public pickFile(): Observable<File> {
    return new Observable((observer: Observer<File>) => {
      const inputElement = document.createElement('input');
      inputElement.type = 'file';

      inputElement.addEventListener('change', (event: Event) => {
        const target = event.target as HTMLInputElement;
        const file = target.files[0];
        observer.next(file);
        observer.complete();
      });

      inputElement.click();
    });
  }

  public uploadFile(file: File): Observable<any> {
    const newName = file.name.replace(/\s+/g, '_');
    const uniqueName = new Date().getTime() + '-' + newName;
    const bucket = 'tfxibucket';
    const key = uniqueName;

    const putObjectCommand = new PutObjectCommand({
      Bucket: bucket,
      Key: key,
      ContentType: file.type,
    });

    return from(
      (async () => {
        const presignedUrl = await getSignedUrl(this.s3Client, putObjectCommand, {
          expiresIn: 3600,
        });

        await axios.put(presignedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        });

        await this.s3Client.send(
          new PutObjectAclCommand({
            Bucket: bucket,
            Key: key,
            ACL: 'public-read',
          }),
        );

        return {
          result: {
            name: uniqueName,
            storageUrl: `https://tfxibucket.ap-south-1.linodeobjects.com/`,
          }
        };
      })(),
    );
  }

  downloadAndConvertPrivateFile(url: string): Promise<string> {
    const normalizedUrl = url.replace(/\s/g, '%20');
    const currentTime = Date.now();

    // Check if the file is cached and if the cache is still valid (1 minute expiration)
    if (this.fileCache.has(normalizedUrl)) {
      const cacheEntry = this.fileCache.get(normalizedUrl);
      if (cacheEntry && currentTime < cacheEntry.expiration) {
        return cacheEntry.data;
      }
      // Remove expired cache entry
      this.fileCache.delete(normalizedUrl);
    }

    const parts = normalizedUrl.split('/');
    const objectKey = parts[parts.length - 1];
    const presignedUrl = this.generatePresignedUrl(objectKey, 3600); // Presigned URL expiration in seconds

    const filePromise = axios
      .get(presignedUrl, { responseType: 'blob' })
      .then((response) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = () => reject('Error reading the file as text.');
          reader.readAsText(response.data);
        });
      })
      .catch((error) => {
        return '';

      });

    // Cache the result with a 1-minute expiration
    this.fileCache.set(normalizedUrl, {
      data: filePromise,
      expiration: currentTime + 60000, // 60 seconds
    });

    return filePromise;
  }

  downloadFile(url: string): Promise<File> {
    const parts = url.split('/');
    const objectKey = parts[parts.length - 1];

    return axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        const blob = response.data;
        const file = new File([blob], objectKey);
        return file;
      })
      .catch((error) => {
        console.error('Error in downloadFile:', error);
        return Promise.reject(error);
      });
  }

  generatePresignedUrl(objectKey: string, expirationInSeconds: number): string {
    const bucket = 'tfxibucket';
    const endpoint = 'https://ap-south-1.linodeobjects.com';
    const expires = Math.floor(Date.now() / 1000) + expirationInSeconds;
    const signature = HmacSHA1(`GET\n\n\n${expires}\n/${bucket}/${objectKey}`, this.secretAccessKey);
    const signatureBase64 = signature.toString(enc.Base64);

    return `${endpoint}/${bucket}/${objectKey}?AWSAccessKeyId=${this.accessKeyId}&Expires=${expires}&Signature=${encodeURIComponent(signatureBase64)}`;
  }

  readCsv(file: File): Observable<any[]> {
    return new Observable<any[]>((observer: Observer<any[]>) => {
      const fileReader = new FileReader();
  
      fileReader.onload = () => {
        const text = fileReader.result as string;
        try {
          const convertedJSONResult = filter(
            JSON.parse(this.csvJSON(text)),
            (x) => Object.keys(x).length > 1
          );
          observer.next(convertedJSONResult);
          observer.complete();
        } catch (error) {
          observer.error(error);
        }
      };
  
      fileReader.onerror = (e) => {
        observer.error(e);
      };
  
      fileReader.readAsText(file);
    });
  }
  
  private csvJSON(csv: string): string {
    const array = this.CSVToArray(csv, ",");
    
    // Check for valid array and skip empty rows
    if (!array || array.length <= 1) {
      return JSON.stringify([]);
    }
  
    const headers = array[0].map(header => camelCase(header));
    const objArray = array.slice(1).map(row => 
      row.reduce((acc, value, index) => {
        if (headers[index]) {
          acc[headers[index]] = value;
        }
        return acc;
      }, {})
    );
  
    return JSON.stringify(objArray, null, 2);
  }
  
  public CSVToArray(strData: string, strDelimiter: string = ","): string[][] {
    const objPattern = new RegExp(
      `(\\${strDelimiter}|\\r?\\n|\\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^"\\${strDelimiter}\\r\\n]*))`,
      "gi"
    );
  
    const arrData: string[][] = [[]];
    let arrMatches: RegExpExecArray | null;
  
    while ((arrMatches = objPattern.exec(strData))) {
      const strMatchedDelimiter = arrMatches[1];
      if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
        arrData.push([]);
      }
  
      const strMatchedValue = arrMatches[2]
        ? arrMatches[2].replace(new RegExp('""', "g"), '"')
        : arrMatches[3];
  
      arrData[arrData.length - 1].push(strMatchedValue);
    }
  
    // Remove any empty rows from arrData
    return arrData.filter(row => row.length > 0);
  }  
}
