import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppConsts } from '@shared/AppConsts';
import { UtilsService } from 'abp-ng2-module';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
    constructor(private spinner: NgxSpinnerService, private _utilService: UtilsService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.spinner.show();
        if (req.url.includes(AppConsts.scfAddress)) {

            let newHeaders: HttpHeaders = req.headers;
            var accessToken = this._utilService.getCookieValue("scfToken");
            newHeaders = newHeaders.set('authorization', "Bearer " + accessToken);

            let newReq = req.clone({ headers: newHeaders });

            return next.handle(newReq).pipe(map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.spinner.hide();
                }
                return event;
            }),
                catchError((error: HttpErrorResponse) => {
                    const started = Date.now();
                    const elapsed = Date.now() - started;
                    console.log(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`);
                    // debugger;

                    this._utilService.deleteCookie("scfToken");
                    this._utilService.deleteCookie("scfTenantId");
                    abp.ui.clearBusy();

                    return throwError(error);
                })
            );

        } else {
            return next.handle(req).pipe(map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.spinner.hide();
                }
                return event;
            }),
                catchError((error: HttpErrorResponse) => {
                    const started = Date.now();
                    const elapsed = Date.now() - started;
                    console.log(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`);
                    // debugger;
                    abp.ui.clearBusy();

                    return throwError(error);
                })
            );

        }




    }
}