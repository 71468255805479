import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { CompanyProfile } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'app-inventory-assets-location-dropdown',
  templateUrl: './inventory-assets-location-dropdown.component.html',
  styleUrls: ['./inventory-assets-location-dropdown.component.css']
})
export class InventoryAssetsLocationDropdownComponent extends AppComponentBase implements OnInit {
  @Input() initialLocation?: string;

  @Output() messageEvent: EventEmitter<{}> = new EventEmitter<{}>();
  
  companyProfile: CompanyProfile

  locationDropdown = [
    "Warehouse",
    "Factory",
    "Office Premise",
    "Employees",
    "Others",
  ];

  selectedLocation = ""

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.companyProfile = this.appSession.companyProfile

    switch (this.companyProfile.typeOfCompany) {
      // case "MANUFACTURING COMPANY":
      //   this.locationDropdown = [
      //     "Factory",
      //     "Others",
      //   ]
      //   break;
      case "TRADING COMPANY":
        this.locationDropdown = [
          "Warehouse",
          "Others",
        ]
        break;
      case "SERVICE COMPANY":
        this.locationDropdown = [
          "Office Premise",
          "Employees",
          "Others",
        ]
        break;
      case "MANUFACTURING & TRADING COMPANY":
        this.locationDropdown = [
          "Warehouse",
          "Factory",
          "Others",
        ]
        break;
      case "OTHERS":
        this.locationDropdown = [
          "Warehouse",
          "Factory",
          "Office Premise",
          "Employees",
          "Others",
        ];
        break;
      default:
        this.locationDropdown = [
          "Warehouse",
          "Factory",
          "Office Premise",
          "Employees",
          "Others",
        ];
        break;
    }
  }

  ngOnChanges(): void {
    if (this.initialLocation) {
      var location = this.locationDropdown.find(x => x === this.initialLocation)
      if (location) {
        this.selectedLocation = location
        this.messageEvent.emit(this.selectedLocation)
      }
    }
  }

  onSelect(location: string) {
    this.selectedLocation = location
    this.messageEvent.emit(this.selectedLocation)
  }

}
