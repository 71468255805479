
import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AccountChargeComponent } from '@shared/components/accounting-chart/account-charge/account-charge.component';
import { AppComponentBase } from '@shared/app-component-base';
import { AccountsChartDto, AccountsChartServiceProxy, JournalServiceProxy, JournalsDto, UserDto, UserServiceProxy } from '@shared/service-proxies/service-proxies';
import { UploadService } from '@shared/upload-service/upload.service';
import moment from 'moment';
import { CustomCurrencyPipe } from '@shared/pipes/currency.pipe';
import { Router } from '@angular/router';



@Component({
  selector: 'app-add-journal-popup',
  templateUrl: './add-journal-popup.component.html',
  styleUrls: ['./add-journal-popup.component.css']
})
export class AddJournalPopupComponent extends AppComponentBase implements OnInit {
  fileAccept = ".pdf,.xls,.xlsx,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"

  dropdownList = [
    "General",
    "Provision",
    "Accrual",
    "Recurring",
    "Account Adjustment",
    "Data Migration",
    "Year End Closing",
    "Deterred Tax",
    "Share of Associate P&L",
    "Write Off / Disposal of Assets",
    "Impairment of Assets"
  ];

  disable = false

  @Input() additionalList: JournalsDto[] = [];

  user = new UserDto();

  formGroupName: FormGroup;

  isMigration = false;

  MyTable = new MatTableDataSource();

  columnWidth = "200px";

  MyTableColumn = [];

  myAccountList: AccountsChartDto[] = [];
  jvNo;

  isEdit = false;

  journalsToRemove: JournalsDto[] = []

  constructor(
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) public myData: DialogData,
    public dialogRef: MatDialogRef<AddJournalPopupComponent>,
    private formBuilder: FormBuilder,
    private _journalService: JournalServiceProxy,
    public _dialog: MatDialog,
    private _uploadService: UploadService,
    private _userService: UserServiceProxy,
    private currencyPipe: CustomCurrencyPipe,
    private _accountCharge: AccountsChartServiceProxy,
    private _router: Router
  ) {
    super(injector);
  }
  ngOnInit(): void {

    if (this.myData.journals != null) {
      this.isEdit = true;
    }

    if (this.isEdit == false) {
      var today = moment();
      this.jvNo = today.format('YYYYMMDD') + '-' + Math.floor(Math.random() * 1000).toString().padStart(3, '0');

      this.formGroupName = this.formBuilder.group({
        jvNo: [{ value: this.jvNo, disabled: true }, Validators.required],
        description: ["", Validators.required],
        requestedBy: [{value: "", disabled: true}, Validators.required],
        date: ["", Validators.required],
        reversalDate: [""],
        journalType: [""],
        attachmentUrl: [""],
      });


    this._userService.getUserbyUserId(this.appSession.userId).subscribe(
      result => {
        this.user.name = result.name
        this.formGroupName.controls.requestedBy.setValue(this.user.name)
      }
    )


    } else {
      //if edit
      this.additionalList = this.myData.journals;
      var firstObject = this.additionalList[0];
      this.jvNo = firstObject.jvNo;


      this.formGroupName = this.formBuilder.group({
        jvNo: [{ value: this.jvNo, disabled: true }, Validators.required],
        description: [firstObject.description, Validators.required],
        requestedBy: [{value:firstObject.requestedBy, disabled:true}, Validators.required],
        date: [this.convertMomentToDate(firstObject.date), Validators.required],
        reversalDate: [firstObject.reversalDate == null ? null : this.convertMomentToDate(firstObject.reversalDate)],
        journalType: [firstObject.journalType],
        attachmentUrl: [firstObject.attachmentUrl],
      });
    }


    if (this.myData.isMigration == null) {
      this.isMigration = true;
    }



    this.MyTableColumn = [
      {
        name: this.isMigration ? "Legacy Code" : "No",
        dataKey: "col1",
        width: this.columnWidth,
      },
      {
        name: "Account Code",
        dataKey: "col2",
        width: this.columnWidth
      },
      {
        name: "Account Charge",
        dataKey: "col3",
        type: "underlineButton"
      },
      {
        name: this.l("Debit"),
        dataKey: "col4",
        width: this.columnWidth
      },
      {
        name: this.l("Credit"),
        dataKey: "col5",
        width: this.columnWidth
      },
    ];
    this.updateTable();



    abp.ui.setBusy();



    this._accountCharge.getAccountsChart(this.appSession.tenantId).subscribe(
      result => {
        this.myAccountList = result;
        abp.ui.clearBusy();
      }
    )


  }


  convertMomentToDate(myDate) {
    return moment(myDate).toDate();
  }


  addFunction() {
    this.formGroupName.markAllAsTouched();


    if (this.formGroupName.valid) {
      abp.ui.setBusy()
      this.disable = true

      var isValid = true;
      var debitAmount = 0;
      var creditAmount = 0;

      var addList: JournalsDto[] = [];


      this.additionalList.forEach(
        eachItem => {
          if (eachItem.debit > 0) debitAmount += Number(eachItem.debit);
          if (eachItem.credit > 0) creditAmount += Number(eachItem.credit);

          if (!eachItem.categoryCode) {
            abp.ui.clearBusy()
            this.disable = false
            isValid = false;
          }

          if (this.isMigration) {
            var newObject = new JournalsDto();
            newObject.jvNo = this.jvNo;
            newObject.debit = eachItem.debit;
            newObject.credit = eachItem.credit;
            newObject.categoryName = eachItem.categoryName;
            newObject.categoryCode = eachItem.categoryCode;
            newObject.attachmentUrl = eachItem.attachmentUrl;
            newObject.legacyCode = eachItem.legacyCode;
            addList.push(newObject);
          } else {
            addList.push(eachItem);
          }
        }
      )

      if (
        debitAmount != creditAmount ||
        creditAmount + debitAmount == 0
      ) {
        abp.ui.clearBusy()
        this.disable = false
        isValid = false;


      }

      if (isValid) {
        this.journalsToRemove.forEach(eachJournal => {
          this._journalService.deleteJournalById(eachJournal.journalId).subscribe(_ => {})
        })
        abp.ui.setBusy();
        this.disable = true

        var newJournal = new JournalsDto();
        newJournal.date = this.convertDateToMoment(this.formGroupName.controls.date.value);
        newJournal.reversalDate = this.convertDateToMoment(this.formGroupName.controls.reversalDate.value);
        newJournal.jvNo = this.jvNo;
        newJournal.tenantId = this.appSession.tenantId;
        newJournal.status = "pending";
        newJournal.description = this.formGroupName.controls.description.value;
        newJournal.requestedBy = this.formGroupName.controls.requestedBy.value;
        newJournal.requestedDate = moment();
        newJournal.additionalList = addList;
        newJournal.journalType = this.formGroupName.controls.journalType.value;
        newJournal.attachmentUrl = this.formGroupName.controls.attachmentUrl.value;

        if (this.isEdit == false) {

          this._journalService.createJournal(newJournal).subscribe(
            result => {
              abp.ui.clearBusy();
              this.disable = false
              if (this.isMigration) {
                this._router.navigate(["accounting/journal"]);
              } else {
                this.closeFunction();
              }
            }
          )
        } else {
          this._journalService.updateJournal(newJournal).subscribe(
            result => {
              abp.ui.clearBusy();
              this.disable = false
              if (this.isMigration) {
                this._router.navigate(["accounting/journal"]);
              } else {
                this.closeFunction();
              }
            }
          )
        }


      } else {
        abp.ui.clearBusy()
        this.disable = false
        this.message.error(
          "One or more entry is Invalid and Journal Entry must be balance. \n Debit Amount = " + debitAmount + " \n Credit amount = " + creditAmount
        )
      }


    } else { abp.ui.clearBusy; this.disable = false }

  }

  convertDateToMoment(myDate) {
    var convert: Date = myDate;
    var momentValue = moment(convert).format("DD/MM/YYYY");
    if (momentValue == "01/01/0001") {
      var defaultValue = null;
      return defaultValue;
    } else if (convert == null) {
      return null;
    } else {
      return moment(convert);
    }
  }


  closeFunction() {
    this.dialogRef.close();
  }


  pickACcount(item: JournalsDto) {
    const dialogRef = this._dialog.open(AccountChargeComponent, {
      disableClose: false,
      width: "70%",
      data: {
        justPick: true
      },
    });
    dialogRef.afterClosed().subscribe((result: AccountsChartDto) => {
      //after close
      if (result) {
        this.codeChange(result, item);
      }
    });
  }


  addEntry() {
    this.formGroupName.markAllAsTouched();
    if (this.formGroupName.valid) {
      var newEntry = new JournalsDto();
      newEntry.jvNo = this.formGroupName.controls.jvNo.value;
      this.additionalList.push(newEntry);
      this.updateTable();
    }
  }

  
  debitAmountChange(amount, item: JournalsDto) {
    const index = this.additionalList.findIndex(obj => obj === item);
    item.debit = amount
    item.credit = 0;
    this.additionalList[index] = item;


  }

  creditAmountChange(amount, item: JournalsDto) {
    const index = this.additionalList.findIndex(obj => obj === item);
    item.credit = amount
    item.debit = 0;
    this.additionalList[index] = item;

  }

  codeChange($event: AccountsChartDto, item: JournalsDto) {
    if ($event) {
      const index = this.additionalList.findIndex(obj => obj === item);
      item.categoryCode = $event.code;
      item.categoryName = $event.description;
      this.additionalList[index] = item;
      this.updateTable();
    }
  }


  uploadFile(e, item: JournalsDto): void {
    abp.ui.setBusy()
    const index = this.additionalList.findIndex(obj => obj === item);
    const file = e.target.files[0]
    this._uploadService.uploadFile(file).subscribe((result) => {
      const url = result.result.storageUrl + result.result.name
      item.attachmentUrl = url
      this.additionalList[index] = item;
      abp.ui.clearBusy()
    })
  }


  inputMessageEvent($event) {

    switch ($event.col) {
      case "col1":
        if (this.isMigration) {
          this.additionalList[$event.index].legacyCode = $event.value;
        }
        break;
      case "col2":
        var accountObject = this.myAccountList.find(x => x.code == $event.value);
        if (accountObject) {
          this.additionalList[$event.index].categoryCode = accountObject.code;
          this.additionalList[$event.index].categoryName = accountObject.description;
        } else {
          this.additionalList[$event.index].categoryCode = null;
          this.additionalList[$event.index].categoryName = null;
          if ($event.value != "") {
            this.message.error(this.l("ThatAccountCodeDoesNotExist"));
          }
        }
        break;
      case "col4":
        const debitValue = Number($event.value);
        if (!isNaN(debitValue) && debitValue >= 0) {
          this.debitAmountChange(Math.round((debitValue + Number.EPSILON) * 100) / 100, $event.element.object);
        } else {
          this.debitAmountChange(0, $event.element.object);
          this.notify.error(this.l("ValueIsNotANumber"));
        }
        break;
      case "col5":
        const creditValue = Number($event.value);
        if (!isNaN(creditValue) && creditValue >= 0) {
          this.creditAmountChange(Math.round((creditValue + Number.EPSILON) * 100) / 100, $event.element.object);
        } else {
          this.creditAmountChange(0, $event.element.object);
          this.notify.error(this.l("ValueIsNotANumber"));
        }
        break;
      default:
        break;
    }
    this.updateTable();

  }

  updateTable() {
    this.MyTable.data = [];
    var totalDebit: number = 0;
    var totalCredit: number = 0;
    var index = 1;
    this.additionalList.forEach((eachItem: JournalsDto) => {
      var newObject = {
        col1: this.isMigration ? eachItem.legacyCode : index,
        col2: eachItem.categoryCode,
        col3: eachItem.categoryName ? eachItem.categoryName : "Please Pick An Account",
        col4: eachItem.debit != 0 ? eachItem.debit : "",
        col5: eachItem.credit != 0 ? eachItem.credit : "",
        object: eachItem
      };
      totalDebit += eachItem.debit ? Number(eachItem.debit) : 0;
      totalCredit += eachItem.credit ? Number(eachItem.credit) : 0;
      index += 1;

      this.MyTable.data.push(newObject);
    });

    var lastObject = {
      col1: "",
      col2: "",
      col3: "Total :",
      col4: this.currencyPipe.transform(totalDebit, ""),
      col5: this.currencyPipe.transform(totalCredit, ""),

    };
    this.MyTable.data.push(lastObject);

    this.MyTable.data = this.MyTable.data;
  }

  messageEvent($event) {

    if ($event.columnName == "col3") {
      // this.codeChange()
      this.pickACcount($event.element.object)
    }
  }

  removeTable($event) {
    var data: JournalsDto = $event.object;

    abp.message.confirm(
      this.l("DeleteThisJournalEntry"),
      this.l("AreYouSure"),
      (result: boolean) => {
        if (result) {
          this,this.journalsToRemove.push(data)
          const index = this.additionalList.findIndex(obj => obj === data);
          this.additionalList.splice(index, 1);
          this.updateTable();
        }
      }
    );
  }

  onSelectType($event: any) {
    this.formGroupName.controls.journalType.setValue($event.selectedItem)
  }

  uploadDocument(e): void {
    abp.ui.setBusy()
    const file = e.target.files[0]
    this._uploadService.uploadFile(file).subscribe((result) => {
      this.formGroupName.controls.attachmentUrl.setValue(result.result.storageUrl + result.result.name)
      abp.ui.clearBusy()
    })
  }
}

export interface DialogData {
  isMigration: boolean;
  journals: any
}
