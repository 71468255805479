import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppComponentBase } from "@shared/app-component-base";
import {
  BillLhdnItemDto,
  CompanyProfile,
} from "@shared/service-proxies/service-proxies";
import * as moment from "moment";
import { CustomTableService } from "./custom-table.service";
import { MatCheckbox } from "@angular/material/checkbox";
import { UploadService } from "@shared/upload-service/upload.service";
import * as ExcelJS from "exceljs";
import { MatSelectChange } from "@angular/material/select";
import * as classificationCodes from "@shared/json/LHDN/ClassificationCodes.json";
import * as taxTypes from "@shared/json/LHDN/TaxTypes.json";

@Component({
  selector: "app-custom-table",
  templateUrl: "./custom-table.component.html",
  styleUrls: ["./custom-table.component.css"],
})
export class CustomTableComponent extends AppComponentBase implements OnInit {
  classificationCodes = (classificationCodes as any).default;
  classificationCodesStrings = [];

  taxTypes = (taxTypes as any).default;
  taxTypesStrings = [];

  public tableDataSource = new MatTableDataSource([]);
  public displayedColumns: string[];

  booltest: boolean = true;
  sortDirection = "asc";

  pageSize = "10";
  pageIndex = "0";
  dataSource;
  filterText = "";

  selectedIndex = 0;
  selectedValue = "Day";
  selectedValueString = "This Month";
  startDate: moment.Moment;
  endDate: moment.Moment;
  selectedDate;

  rangeSwitch = ["Day", "Month", "Custom"];
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  fileAccept =
    ".pdf,.xls,.xlsx,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";

  acceptPdfOnly = ".pdf";

  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) matSort: MatSort;

  @Input() isPageable = false;
  @Input() isSortable = false;
  @Input() isFilterable = false;
  @Input() isFilterRange = false;

  @Input() showTitle = true;
  @Input() isStickyTitle = false;
  @Input() isBottomSticky = false;

  @Input() stickyHeight = "50vh";

  @Input() isLeftTitle = false;
  // @Input() isLeftTitleArray = false;

  @Input() isInputTable = false;
  @Input() uploadSpinner = false;
  @Input() totalItem = "100";

  @Input() tableColumns: TableColumn[];
  @Input() rowActionIcon: string;

  @Input() canEdit: boolean = false;

  @Input() paginationSizes: number[] = [5, 10, 25, 50, 100, 250, 500];

  @Input() titleRowArray: number[] = [];
  @Input() mergeRowArray: number[] = [];
  @Input() mergeColumnArray: number[] = [];
  @Input() showBorder: boolean = false;
  @Input() addButton: boolean = false;
  @Input() isPrintable: boolean = true;
  @Input() dropdownList = [];
  @Input() dropdownTitle: string;
  @Input() dropdownButtonList = [];

  @Input() addString: string = "Add";

  @Output() addEvent: EventEmitter<{}> = new EventEmitter<{}>();

  @Input() defaultPageSize = this.paginationSizes[1];
  @Input() buyers: any = [];
  @Input() idString: string = "";

  @Input() inputFilterText: string = this.l("SearchWithThreeDot");

  @Output() sort: EventEmitter<Sort> = new EventEmitter();
  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() editRowAction: EventEmitter<any> = new EventEmitter<any>();

  @Output() messageEvent: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() inputMessageEvent: EventEmitter<{}> = new EventEmitter<{}>();

  @Output() authenticationEvent: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() uploadButtonEvent: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() buyerDropDownEvent: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() cancelUploadEvent: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() paginatorEvent: EventEmitter<any> = new EventEmitter();
  @Output() uploadCfsEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteCfs: EventEmitter<any> = new EventEmitter();
  @Output() rejectInvoice: EventEmitter<any> = new EventEmitter();
  @Output() searchEvent: EventEmitter<any> = new EventEmitter();

  @Output() rangeEvent: EventEmitter<any> = new EventEmitter();

  // this property needs to have a setter, to dynamically get changes from parent component
  @Input() set tableData(data: any[]) {
    this.setTableDataSource(data);
  }

  @Input() set inputTableDataSource(input) {
    this.tableDataSource = input;
    this.tableDataSource.sort = this.matSort;
    if (this.isPageable == false) {
      this.tableDataSource.paginator = this.matPaginator;
    }
  }

  isInvoiceApprover = false;

  isUser = false;

  isProductViewer = false;
  isInvoiceViewer = false;
  isOrderViewer = false;
  isGrnViewer = false;
  isAssetViewer = false;
  isRequisitionViewer = false;
  isInventoryViewer = false;
  isProjectViewer = false;
  isDebitNoteViewer = false;

  isCashbookEditor = false;
  isInvoiceEditor = false;
  isCreditNoteEditor = false;
  isOrderEditor = false;
  isGrnEditor = false;
  isInventoryEditor = false;
  isAssetEditor = false;
  isRequisitionEditor = false;
  isAccountingEditor = false;
  isProductEditor = false;
  isProjectEditor = false;
  isDebitNoteEditor = false;

  yearDropdown = [];
  selectedYear = 2023;
  selectedMonth = 0;
  selectedMonthString = "";

  monthDropdown = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  indexRange = [
    { show: "MonthToDate", value: 0 },
    { show: "YearToDate", value: 1 },
    { show: "Custom Date", value: 2 },
  ];

  selectAll = false;
  @ViewChildren("checkbox") checkboxes: QueryList<MatCheckbox>;

  myCompany: CompanyProfile;

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    private _customTableService: CustomTableService,
    private _uploadService: UploadService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.myCompany = this.appSession.companyProfile;

    var yearInt = 2019;

    for (let i = 1; i < 20; i++) {
      this.yearDropdown.push(yearInt);
      yearInt += 1;
    }

    this.classificationCodesStrings = this.classificationCodes.map(
      (x) => x.Description
    );
    this.taxTypesStrings = this.taxTypes.map((x) => x.Description);

    var date = new Date();
    this.selectedMonth = date.getMonth();
    this.selectedMonthString = this.monthDropdown[this.selectedMonth];
    this.selectedYear = date.getFullYear();

    const columnNames = this.tableColumns.map(
      (tableColumn: TableColumn) => tableColumn.name
    );
    if (this.rowActionIcon) {
      this.displayedColumns = [...columnNames, this.rowActionIcon];
    } else {
      this.displayedColumns = columnNames;
    }

    this.isInvoiceApprover = this.isGranted("Invoices.approve");

    // this.isUser = this.isGranted("Pages.Users")
    this.isUser = true;

    this.isProductViewer = this.isGranted("Pages.Products");
    this.isInvoiceViewer = this.isGranted("Pages.Invoices");
    this.isOrderViewer = this.isGranted("Pages.Orders");
    this.isGrnViewer = this.isGranted("Pages.GoodsReceivedNote");
    this.isAssetViewer = this.isGranted("Pages.AssetRegister");
    this.isRequisitionViewer = this.isGranted("Pages.Requisition");
    this.isInventoryViewer = this.isGranted("Pages.Inventory");
    this.isProjectViewer = this.isGranted("Pages.Project");
    this.isDebitNoteViewer = this.isGranted("Pages.DebitNote");

    this.isCashbookEditor = this.isGranted("Cashbook.Edit");
    this.isInvoiceEditor = this.isGranted("Invoices.edit");
    this.isCreditNoteEditor = this.isGranted("Creditnote.edit");
    this.isOrderEditor = this.isGranted("Orders.edit");
    this.isGrnEditor = this.isGranted("GoodsReceivedNote.edit");
    this.isInventoryEditor = this.isGranted("Inventory.Edit");
    this.isAssetEditor = this.isGranted("AssetRegister.Edit");
    this.isRequisitionEditor = this.isGranted("Requisition.edit");
    this.isAccountingEditor = this.isGranted("Accounting.Edit");
    this.isProductEditor = this.isGranted("Products.edit");
    this.isProjectEditor = this.isGranted("Project.Edit");
    this.isDebitNoteEditor = this.isGranted("DebitNote.Edit");
  }

  // we need this, in order to make pagination work with *ngIf
  ngAfterViewInit(): void {
    if (this.isPageable == false) {
      this.tableDataSource.paginator = this.matPaginator;
    }
    this.tableDataSource.sort = this.matSort;
  }

  setTableDataSource(data: any) {
    this.tableDataSource = new MatTableDataSource<any>(data);
    this.tableDataSource.sort = this.matSort;
    if (this.isPageable == false) {
      this.tableDataSource.paginator = this.matPaginator;
    }
  }

  applyFilter() {
    this.searchEvent.emit(this.filterText);
  }

  sortTable(sortParameters: Sort) {
    // defining name of data property, to sort by, instead of column name
    sortParameters.active = this.tableColumns.find(
      (column) => column.name === sortParameters.active
    ).dataKey;

    // if (this.sortDirection == "asc") {
    //   this.matSort.direction = "desc";
    //   this.sortDirection = "desc";
    // } else {
    //   this.matSort.direction = "asc";
    //   this.sortDirection = "asc";
    // }

    this.sort.emit(sortParameters);

    // this.matSort.active = sortParameters.active;
    // this.matSort.disableClear = false;

    // this.tableDataSource.sort = this.matSort;
  }

  emitRowAction(row: any) {
    this.rowAction.emit(row);
  }

  emitEditRowAction(element) {
    this.editRowAction.emit(element);
  }

  uploadFile(element, columnNumber, index, $event, test): void {
    const file: File = $event.target.files[0];

    abp.ui.setBusy();
    this._uploadService.uploadFile(file).subscribe((result) => {
      var scannedDocumentURL = result.result.storageUrl + result.result.name;

      var returnObject = {
        element: element,
        col: "col" + columnNumber,
        index: index,
        value: scannedDocumentURL,
        event: $event,
        file: file,
        test: test,
      };

      this.inputMessageEvent.emit(returnObject);

      abp.ui.clearBusy();
    });
  }

  onInputChange(element, columnNumber, $event, index?, value?) {
    var returnObject = {
      element: element,
      col: "col" + columnNumber,
      index: index,
      value: value,
      event: $event,
    };
    this.inputMessageEvent.emit(returnObject);
  }

  getNumberOfKeys(element) {
    return Object.keys(element).length;
  }

  onAuthenticationClick(data) {
    var returnObject = {
      objectData: data,
    };

    this.authenticationEvent.emit(returnObject);
  }

  onRejectInvoiceClick(data) {
    var returnObject = {
      objectData: data,
    };

    this.rejectInvoice.emit(returnObject);
  }

  onUploadButtonClick(rowNumber, columnNumber, $event) {
    var columnString = "col" + columnNumber;

    var returnObject = {
      rowNumber: rowNumber.col1,
      columnNumber: columnNumber + 1,
      event: $event,
    };
    this.uploadButtonEvent.emit(returnObject);
  }

  onBuyerChangeSelection(rowNumber, columnNumber, $event) {
    var test: object = $event.target.value;

    var columnString = "col" + columnNumber;

    var returnObject = {
      rowNumber: rowNumber.col1,
      columnNumber: columnNumber + 1,
      buyerId: $event.target.value,
    };

    this.buyerDropDownEvent.emit(returnObject);
  }

  cancelUploadClick(rowNumber, columnNumber, element) {
    this.uploadSpinner = true;

    var returnObject = {
      rowNumber: rowNumber.col1,
      columnNumber: columnNumber + 1,

      element: element,
    };

    this.cancelUploadEvent.emit(returnObject);
  }

  openWindow(fileURL) {
    window.open(fileURL, "_blank");
  }

  getNext($event) {
    this.selectAll = false;
    this.paginatorEvent.emit($event);
  }

  uploadCfcFunction($event, element) {
    this.uploadCfsEvent.emit({
      event: $event,
      element: element,
    });
  }

  deleteCfsFunction(element) {
    this.deleteCfs.emit(element);
  }

  openLink(link) {
    window.open(link, "_blank");
  }

  sendInvitationEmail($event, element) {
    var message = {
      type: "sendEmail",
      element: element,
      event: $event,
    };

    this.messageEvent.emit(message);
  }

  sendMessageEvent($event, element, typeName) {
    var message = {
      type: typeName,
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  sendLhdnClassification($event, element, typeName, dataKey) {
    var classificationCode = this.classificationCodes.find(
      (x) => x.Description == $event.selectedItem
    );
    var object: BillLhdnItemDto = element.object;
    object.itemClassificationCode = classificationCode.Code;
    element.object = object;

    var message = {
      type: typeName,
      element: element,
      event: $event,
      dataKey: dataKey
    };
    this.messageEvent.emit(message);
  }

  sendLhdnTaxTypes($event, element, typeName, dataKey) {
    var taxType = this.taxTypes.find(
      (x) => x.Description == $event.selectedItem
    );
    var object: BillLhdnItemDto = element.object;
    object.taxCategoryID = taxType.Code;
    element.object = object;

    var message = {
      type: typeName,
      element: element,
      event: $event,
      dataKey: dataKey
    };
    this.messageEvent.emit(message);
  }

  sendMessage($event, element, typeName, columnName?) {
    var message = {
      typeName: typeName,
      element: element,
      event: $event,
      columnName: columnName,
    };

    this.messageEvent.emit(message);
  }

  addMessage() {
    this.addEvent.emit();
  }

  companyInfo(element) {
    var message = {
      type: "companyInfo",
      element: element,
    };

    this.messageEvent.emit(message);
  }

  accept($event, element) {
    var message = {
      type: "accept",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  reject($event, element) {
    var message = {
      type: "reject",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  differ($event, element) {
    var message = {
      type: "defer",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  edit($event, element) {
    var message = {
      type: "edit",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  deleteProgram($event, element) {
    var message = {
      type: "delete",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  viewInvoices($event, element) {
    var message = {
      type: "invoices",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  view($event, element) {
    var message = {
      type: "view",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  statusActive($event, element) {
    var message = {
      type: "statusActive",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  statusRehabilitation($event, element) {
    var message = {
      type: "statusRehabilitation",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  statusNonPerforming($event, element) {
    var message = {
      type: "statusNonPerforming",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  statusOther($event, element) {
    var message = {
      type: "statusOther",
      element: element,
      event: $event,
    };
    this.messageEvent.emit(message);
  }

  isNumber(val, name): boolean {
    if (typeof val === "number" && name) {
      if (name.includes("amount")) {
        return true;
      }
    }

    return false;
  }

  onSelect(event: MatSelectChange) {
    if (event.value != null) {
      if (event.value == 0) {
        this.selectedValue = "Day";
        this.selectedValueString = "This Month";
        this.selectedIndex = 0;
        this.setDateRange();
      } else if (event.value == 1) {
        this.selectedValue = "Month";
        this.selectedValueString = "This Year";
        this.selectedIndex = 1;
        this.setDateRange();
      } else if (event.value == 2) {
        this.selectedValue = "Custom";
        this.selectedIndex = 2;
      }

      this.selectedDate = null;
    }
  }

  setDateRange() {
    var date = new Date();
    var offset = -date.getTimezoneOffset() / 60;

    var startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    var endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.selectedYear = date.getFullYear();

    var startDay = this.myCompany.financialYearStartDay ?? 1;
    var startMonth = this.myCompany.financialYearStartMonth ?? 0;

    if (this.selectedIndex == 0) {
      startDate = new Date(date.getFullYear(), date.getMonth(), startDay + 1);
      endDate = new Date(date.getFullYear(), date.getMonth() + 1, startDay);
      this.selectedMonth = date.getMonth();
      this.selectedMonthString = this.monthDropdown[this.selectedMonth];
    } else if (this.selectedIndex == 1) {
      startDate = new Date(date.getFullYear(), startMonth, startDay + 1);
      endDate = new Date(date.getFullYear(), 12 + startMonth, startDay);
      if (startDate > date) {
        startDate.setFullYear(startDate.getFullYear() - 1);
        endDate.setFullYear(endDate.getFullYear() - 1);
      }
    }
    this.startDate = this.convertDateToMoment(startDate);
    this.endDate = this.convertDateToMoment(endDate);
    this.startDate.hour(0).minute(0).second(0);
    this.endDate.hour(23).minute(59).second(59);
    this.startDate.add(offset, "hours");
    this.endDate.add(offset, "hours");

    this.rangeEvent.emit({ startDate: this.startDate, endDate: this.endDate });
  }

  convertDateToMoment(myDate) {
    var convert: Date = myDate;
    var momentValue = moment(convert).format("DD/MM/YYYY");
    if (momentValue == "01/01/0001" || convert == null) {
      var defaultValue = null;
      return defaultValue;
    } else {
      return moment(convert);
    }
  }

  rangePicked() {
    var offset = -(new Date().getTimezoneOffset() / 60);
    this.startDate = this.convertDateToMoment(this.range.value.start);
    this.endDate = this.convertDateToMoment(this.range.value.end);
    this.startDate.hour(0).minute(0).second(0);
    this.endDate.hour(23).minute(59).second(59);
    this.startDate.add(offset, "hours");
    this.endDate.add(offset, "hours");

    if (this.startDate && this.endDate) {
      // this.getReportData();
      this.rangeEvent.emit({
        startDate: this.startDate,
        endDate: this.endDate,
      });
    }
  }

  onSelectYear($event) {
    var selectedvalue = $event.value;

    var startDate = new Date(selectedvalue, 0, 1);
    var endDate =
      this.selectedIndex == 1
        ? new Date(selectedvalue, 12, 0)
        : new Date(selectedvalue, 0, 31);

    this.selectedMonthString = this.monthDropdown[0];
    var date = new Date();
    var offset = -date.getTimezoneOffset() / 60;

    var startDay = this.myCompany.financialYearStartDay ?? 1;
    var startMonth = this.myCompany.financialYearStartMonth ?? 0;

    if (this.selectedIndex == 1) {
      date.setFullYear(selectedvalue);
      startDate = new Date(date.getFullYear(), startMonth, startDay + 1);
      endDate = new Date(date.getFullYear(), 12 + startMonth, startDay);
      if (startDate > date) {
        startDate.setFullYear(startDate.getFullYear() - 1);
        endDate.setFullYear(endDate.getFullYear() - 1);
      }
    }
    this.startDate = this.convertDateToMoment(startDate);
    this.endDate = this.convertDateToMoment(endDate);
    this.startDate.hour(0).minute(0).second(0);
    this.endDate.hour(23).minute(59).second(59);
    this.startDate.add(offset, "hours");
    this.endDate.add(offset, "hours");

    if (this.startDate && this.endDate) {
      // this.getReportData();
      this.rangeEvent.emit({
        startDate: this.startDate,
        endDate: this.endDate,
      });
    }
  }

  onSelectMonth($event) {
    var selectedvalue = $event.value;
    var index = this.monthDropdown.indexOf(selectedvalue);

    var date = new Date();
    var offset = -date.getTimezoneOffset() / 60;

    var startDate = new Date(this.selectedYear, index, 1);
    var endDate = new Date(this.selectedYear, index + 1, 0);
    this.selectedMonth = index;
    this.selectedMonthString = this.monthDropdown[this.selectedMonth];

    this.startDate = this.convertDateToMoment(startDate);
    this.endDate = this.convertDateToMoment(endDate);
    this.startDate.hour(0).minute(0).second(0);
    this.endDate.hour(23).minute(59).second(59);
    this.startDate.add(offset, "hours");
    this.endDate.add(offset, "hours");

    if (this.startDate && this.endDate) {
      // this.getReportData();
      this.rangeEvent.emit({
        startDate: this.startDate,
        endDate: this.endDate,
      });
    }
  }

  cashbookColumnRename(colName: String) {
    var colNum = Number(colName.replace("col", ""));
    return "col" + (colNum - 1).toString();
  }

  printTable() {
    if (this.tableColumns.length < 6) {
      this._customTableService.generatePdf(
        this.tableDataSource.data,
        this.tableColumns,
        this.showTitle,
        this.mergeRowArray,
        this.mergeColumnArray
      );
    } else {
      this._customTableService.generatePdfLandscape(
        this.tableDataSource.data,
        this.tableColumns,
        this.showTitle,
        this.mergeRowArray,
        this.mergeColumnArray
      );
    }
  }

  exportToExcel(): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    const headers = this.tableColumns.map((column) => column.name);
    worksheet.addRow(headers);

    const data = this.tableDataSource.data;

    data.forEach((row) => {
      const values = this.tableColumns
        .filter((column) => column.dataKey.includes("col"))
        .map((column) => row[column.dataKey]);

      worksheet.addRow(values);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "exported-data.xlsx";
      link.click();
    });
  }

  chechIfToday(object: any): boolean {
    const today = moment();

    if (today.isSame(object.date, "day") && object.reversalApprovalInt == 0) {
      return true;
    } else {
      return false;
    }
  }

  allCheck($event, columnName) {
    this.selectAll = $event.checked;

    if (this.selectAll == true) {
      this.checkboxes.forEach((checkbox) => (checkbox.checked = true));
    } else {
      this.checkboxes.forEach((checkbox) => (checkbox.checked = false));
    }

    this.tableDataSource.data.forEach((element) => {
      this.sendMessage($event, element, undefined, columnName);
    });
  }

  checkOne($event, element, typeName, columnName) {
    this.selectAll = false;

    var message = {
      typeName: typeName,
      element: element,
      event: $event,
      columnName: columnName,
    };

    this.messageEvent.emit(message);
  }

  getBadgeColor(status: string): string {
    switch (status) {
      case "secondary":
        return "badge badge-secondary";
      case "primary":
        return "badge badge-primary";
      case "info":
        return "badge badge-info";
      case "success":
        return "badge badge-success";
      case "warning":
        return "badge badge-warning";
      case "danger":
        return "badge badge-danger";
      default:
        return "badge badge-primary";
    }
  }
}

export interface TableColumn {
  name: string;
  dataKey: string;
  type?: string;
  typeName?: string;
  position?: "right" | "left";
  isSortable?: boolean;
  width: string;
}
