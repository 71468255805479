<div class="w-100">
  <div class="row justify-content-center w-100">
    <div class="col" *ngIf="isFixedAssetOnly != true && hideSector == false">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label
          >{{ title ? title : "" }}
          {{ "AccountCategory" | localize }}</mat-label
        >
        <mat-select
          [disabled]="disableCategory"
          [(ngModel)]="selectedSector"
          [compareWith]="compareSectors"
          (selectionChange)="onSelect($event.value)"
        >
          <mat-option *ngFor="let data of sectorList" [value]="data">
            {{ data.name }}
          </mat-option>
        </mat-select>
        <mat-error>* {{ "Required" | localize }}</mat-error>
      </mat-form-field>
    </div>
    <div class="col" *ngIf="accountChargeDropdown.length != 0">
      <app-custom-dropdown
        [items]="accountChargeDropdown"
        [title]="accountChargeString | localize"
        [placeholderLabel]="'Search' | localize"
        [notFoundLabel]="'NotFound' | localize"
        (dropdownEvent)="onSelectAccountCharge($event)"
        [initialValue]="initialAccountCharge"
      ></app-custom-dropdown>
    </div>
  </div>
</div>
