import { Injectable } from "@angular/core";
import { BillsPdfService } from "../bills-pdf.service";
import pdfMake from "pdfmake/build/pdfmake";
import { TfxBillItem, CompanyProfile, BillServiceProxy } from "@shared/service-proxies/service-proxies";
import { CustomCurrencyPipe } from '@shared/pipes/currency.pipe';
import moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class TfxInvoiceService extends BillsPdfService {


  constructor(
    private currencyPipe: CustomCurrencyPipe,
    private _billService: BillServiceProxy
  ) {
    super();
  }


  async generatePdf(
    companyProfile: CompanyProfile,
    productItems: TfxBillItem[],
    invoiceNumber: string
  ) {

    var Logo = this.defaultImage;



    var totalSubtotal = 0;
    var totalInc = 0;


    productItems.forEach((eachItem) => {
      var myprice = eachItem.unitPrice * eachItem.quantity;
      totalSubtotal += myprice;
    });


    totalInc = totalSubtotal;

    var myCustomColor = "#2810bf ";



    let docDefinition = {
      content: [
        {
          columns: [
            {
              columns: [
                {
                  width: "auto",
                  image: await this.getBase64ImageFromURL(Logo),
                  fit: [120, 70],
                  // fit: [100, 50],
                },
                {
                  width: "*",
                  layout: "noBorders",
                  text: this.l("BILL").toUpperCase(),
                  fontSize: 30,
                  bold: true,
                  alignment: "left",
                  margin: [15, 15, 0, 0],
                  color: myCustomColor,
                },
              ],
              alignment: "left",
            },
            {
              width: "auto",
              layout: "noBorders",
              alignment: "right",
              table: {
                body: [
                  [
                    {
                      text: "TFX ISLAMIC SDN BHD",
                      fontSize: 10,
                      bold: true,
                    },
                  ],
                  [{ text: "D-1-3, Strand Garden Office,", fontSize: 9 }],
                  [{ text: "12, Jalan PJU 5/1, Kota Damansara,", fontSize: 9 }],
                  [{ text: "47410, Selangor", fontSize: 9 }],
                  [{ text: "TEL: 03-61512225", fontSize: 9 }],
                  [
                    {
                      text: "info@tfx.global",
                      fontSize: 9,
                      link: "mailto:info@tfx.global",
                      color: myCustomColor,
                      decoration: "underline",
                    },
                  ],
                ],
              },
            },
          ],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 2,
              x2: 520,
              y2: 2,
              lineWidth: 2,
              color: myCustomColor,
            },
          ],
          margin: [0, 5, 0, 0],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 2,
              x2: 520,
              y2: 2,
              lineWidth: 2,
              color: myCustomColor,
            },
          ],
        },
        {
          columns: [
            {
              width: "*",
              layout: "noBorders",
              table: {
                body: [
                  [
                    {
                      text: this.l("BILLTO").toUpperCase(),
                      fontSize: 10,
                      bold: true,
                      margin: [0, 1],
                    },
                  ],
                  [
                    {
                      text: companyProfile.companyName,
                      fontSize: 10,
                      bold: true,
                    },
                  ],
                  [{ text: companyProfile.address1, fontSize: 9 }],
                  [{ text: companyProfile.address2, fontSize: 9 }],
                  [{ text: companyProfile.postCode, fontSize: 9 }],
                  [{ text: companyProfile.city, fontSize: 9 }],
                  [{ text: companyProfile.state, fontSize: 9 }],
                  [{ text: companyProfile.country, fontSize: 9 }],
                  [{ text: "", fontSize: 9 }],
                  [{ text: companyProfile.contactNo, fontSize: 9 }],
                  [
                    {
                      text: companyProfile.picEmailAddress,
                      fontSize: 10,
                      link: "mailto:" + companyProfile.picEmailAddress,
                      color: myCustomColor,
                      decoration: "underline",
                    },
                  ],
                ],
              },
            },
            {
              width: "auto",
              alignment: "right",
              table: {
                widths: ["auto", "auto", "auto"],
                body: [
                  [
                    {
                      text: this.l("INVOICEDETAILS").toUpperCase(),
                      fontSize: 10,
                      bold: true,
                      colSpan: 3,
                      margin: [0, 1],
                    },
                    {},
                    {},
                  ],
                  [
                    { text: this.l("InvNo"), fontSize: 10, alignment: "left" },
                    { text: " : ", fontSize: 10 },
                    { text: invoiceNumber, fontSize: 9 },
                  ],
                  [
                    { text: this.l("Date"), alignment: "left", fontSize: 10 },
                    { text: " : ", fontSize: 10 },
                    {
                      text: this.convertDate(moment()),
                      fontSize: 10,
                    },
                  ],
                ],
              },
              layout: {
                defaultBorder: false,
              },
            },
          ],
          margin: [0, 7, 0, 0],
        },
        productItems.length > 0
          ? {
            table: {
              headerRows: 1,
              widths: ["*", 35, 50, 52, 52],
              body: [
                [
                  {
                    text: this.l("ItemDescription"),
                    fontSize: 9,
                    bold: true,
                    color: "#ffffff",
                    margin: [0, 1],
                  },
                  {
                    text: this.l("Qty"),
                    fontSize: 9,
                    bold: true,
                    alignment: "center",
                    color: "#ffffff",
                    margin: [0, 1],
                  },
                  {
                    text: this.l("UnitPrice"),
                    fontSize: 9,
                    bold: true,
                    alignment: "center",
                    color: "#ffffff",
                    margin: [0, 1],
                  },
                  {
                    text: this.l("Subtotal"),
                    fontSize: 9,
                    bold: true,
                    alignment: "center",
                    color: "#ffffff",
                    margin: [0, 1],
                  },
                  {
                    text: this.l("Total"),
                    fontSize: 9,
                    bold: true,
                    alignment: "center",
                    color: "#ffffff",
                    margin: [0, 1],
                  },
                ],
                ...productItems.map((p: TfxBillItem) => {
                  var mysubtotal = p.unitPrice * p.quantity;
                  var mytotalincl = mysubtotal;

                  var priceString = p.quantity.toString();
                  if (p.productName != null) {
                    priceString = p.quantity.toString();
                  }

                  return [
                    {
                      text: p.productName,
                      fontSize: 9,
                      margin: [0, 5],
                    },
                    {
                      text: priceString,
                      fontSize: 9,
                      alignment: "center",
                      margin: [0, 5],
                    },
                    {
                      text: this.currencyPipe.transform(p.unitPrice),
                      fontSize: 9,
                      alignment: "center",
                      margin: [0, 5],
                    },
                    {
                      text: this.currencyPipe.transform(totalInc),
                      fontSize: 9,
                      alignment: "center",
                      margin: [0, 5],
                    },
                    {
                      text: this.currencyPipe.transform(mytotalincl),
                      fontSize: 9,
                      alignment: "center",
                      margin: [0, 5],
                    },
                  ];
                }),
              ],
            },
            layout: {
              defaultBorder: false,
              fillColor: function (rowIndex, node, columnIndex) {
                return rowIndex === 0 ? myCustomColor : null;
              },
            },
            margin: [0, 10],
          }
          : {},

        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 2,
              x2: 520,
              y2: 2,
              lineWidth: 2,
              color: myCustomColor,
            },
          ],
        },
        {
          columns: [
            [
              {
                columns: [
                  [
                    {
                      text: this.l("ThankYouForYourBusiness"),
                      bold: true,
                      color: myCustomColor,
                      margin: [0, 1],
                    },
                    {
                      text: this.capitalLetterSign(companyProfile.picName),
                      font: "callstories",
                      fontSize: 13,
                    },
                    {
                      text: companyProfile.companyName,
                      fontSize: 10,
                      bold: true,
                    },
                  ],
                ],
                margin: [0, 10, 0, 0],
              },
            ],

            {
              width: "auto",
              layout: "noBorders",
              table: {
                widths: ["auto", "auto", 100],
                body: [
                  [
                    {
                      text: this.l("Summary"),
                      fontSize: 11,
                      bold: true,
                      alignment: "left",
                      decoration: "underline",
                    },
                    {},
                    {},
                  ],
                  [
                    { text: this.l("Subtotal"), fontSize: 10 },
                    { text: ":", fontSize: 10 },
                    {
                      text: this.currencyPipe.transform(totalSubtotal),
                      fontSize: 10,
                      alignment: "right",
                    },
                  ],
                  [
                    {
                      text: this.l("TotalPayable"),
                      fontSize: 11,
                      bold: true,
                      margin: [0, 10, 0, 0],
                    },
                    {
                      text: ":",
                      fontSize: 10,
                      margin: [0, 10, 0, 0],
                    },
                    {
                      text: this.currencyPipe.transform(totalInc),
                      fontSize: 11,
                      bold: true,
                      margin: [0, 10, 0, 0],
                      alignment: "right",
                      decoration: "underline",
                    },
                  ],
                ],
              },
              alignment: "left",
            },
          ],
          margin: [10, 10, 0, 15],
        },
      ],
      footer: function (currentPage, pageCount, pageSize) {

        var paymentUrl = "";

        if (currentPage == pageCount) {
          if (paymentUrl) {
            return {
              text: "click here to pay",
              link: paymentUrl,
              color: myCustomColor,
              decoration: "underline",
              alignment: "center",
            };
            return {};
          } else {
            return {};
          }
        }
      },
      defaultStyle: {
        color: "#3F3F46",
      },
    };

    var compnayName = companyProfile.companyName.replace(/\s/g, "");

    var fileName = compnayName + "_" + invoiceNumber + ".pdf";

    return new Promise((resolve, reject) => {
      pdfMake.createPdf(docDefinition).getBase64((data) => {

        //send email 
        const json = { companyProfile: companyProfile, data: data, invoiceNo: invoiceNumber };
        const string = JSON.stringify(json); //
        const encodedString = btoa(string);

        this._billService.sendTfxbillWithPdf(encodedString).subscribe()


        // then return blob data
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        resolve(blob);

      }, (error) => {
        console.error(error);
        reject(error);
      });
    });
  };

} 
