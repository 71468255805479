export const defaultSettings = {
  classAnimation: "bounce-in",
  fileAccept: "*",
  fileLimit: 1,
  iconAdd: "add",
  iconDelete: "delete",
  iconFile: "insert_drive_file",
  iconLink: "link",
  linkEnabled: false,
  placeholderLink: "Link",
  sizeLimit: null,
};
